
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function required(field_name, form_data, field_label) {
    if (field_name in form_data) {
        return {
            valid: true
        };
    }
    else {
        return {
            valid: false,
            message: `${field_label} is required.`
        };
    }
}

// Used for array data in forms to validate they they have items
function required_not_empty(field_name, form_data, field_label) {
    if (field_name in form_data && form_data[field_name] != null && form_data[field_name].length > 0) {
        return {
            valid: true
        };
    } else {
        return {
            valid: false,
            message: `${field_label} is required.`
        }
    }
}

function email(field_name, form_data, field_label) {
    if (form_data[field_name].toLowerCase().match(EMAIL_REGEX) != null) {
        return {
            valid: true
        };
    }
    else {
        return {
            valid: false,
            message: `${field_label} field is not an email.`
        }
    }
}







export {
    required,
    email,
    required_not_empty
}