<template>
  <SecondaryNavBar
    title="Billing - International Rates Lookup"
    @button-click="handleButtonClick"
    :rightAlignedButtons="getNavButtons"
  />
  <SideDrawer
    :drawerOpen="drawer"
    buttonType="Close"
    :buttonOptions="buttonOptions"
    :drawerData="drawerData"
    @close-click="handleCloseClick"
    @form-submission="handleDrawerSubmit"
    :num-cols="12"
    :width="350"
    :previousSubmissionData="submissionData"
  />
  <div class="intRateBackground">
    <div class="intRateContainer">
      <div class="intRateContentContainer">
        <div class="intRatePageContent">
          <div class="intRateFormContainer">
            <FormComp
              :form-data="intRateData"
              :buttonOptions="{
                positionBottom: false,
                centered: false,
                fillToParent: false,
                divider: true
              }"
              :numCols="6"
              @submission-data="handleFormSubmission"
            />
          </div>
          <TableComp
            v-if="showTable"
            :headers="headers"
            :dataList="data"
            :filterOptionsArray="filterOptionsArray"
            backgroundColor="#e2f1f4"
            :fixedWidth="true"
            @click-row="handleClickRow"
          />
        </div>
        <AlertsGroup
          v-model:alerts="alerts"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormComp from "@/components/base_components/FormComponents/FormComp.vue";
import SecondaryNavBar from "@/components/base_components/Navigation/SecondaryNavBar.vue";
import TableComp from "@/components/base_components/TableComp.vue";
import AlertsGroup from "@/components/base_components/AlertsGroup.vue";
import { downloadCSV } from "@/helpers/csvUtils.js";
import { addAlert } from '@/helpers/alertUtils';
import SideDrawer from "@/components/base_components/SideDrawer.vue";
import { ERROR, NOTIFICATION, SUCCESS } from "@/constants/statuses.constants.js";
import { MISSING_FIELDS } from '@/constants/alerts.constants.js';

// import { useInternationalRatesStore } from "@/stores/internationalRates.js";
import { mapActions, mapStores } from 'pinia';
import { useInternationalRatesStore } from "@/stores/Billing/InternationalRates/internationalRatesStore.js";
import { useHelpStore } from '@/stores/Help/helpStore.js';
import { useS3Store } from "@/stores/aws/s3Store";
import { useAuthStore } from "@/stores/auth/authStore";

export default {
  name: "IntRateLookupView",
  components: {
    SecondaryNavBar,
    FormComp,
    TableComp,
    AlertsGroup,
    SideDrawer
  },
  data() {
    return {
      windowHeight: null,
      showTable: false,
      alerts: [],
      drawer: false,
      filterOptionsArray: [],
      submissionData: {},
      intRateData: [
        { title: "International Rates Lookup", type: "form", button: "Search" },
        {
          title: "",

          inputs: [
            { name: "Country", type: "bubble", required: false },
            { name: "Dial Prefix", type: "bubble", required: false },
          ],
        },
      ],
      headers: [
        { text: "Country", value: "country" },
        { text: "Dial Prefix", value: "dialPrefix" },
        { text: "Retail Rate", value: "retailRate" },
        { text: "Wholesale Rate", value: "wholesaleRate" },
      ],
      data: [],
      drawerData: [
        {
          title: "Add Email",
          type: "form",
          button: "Email All Data",
          buttonOptions: {
            positionBottom: true,
            centered: true,
            fillToParent: true,
            width: "100%",
            divider: true,
          }
        },
        {
          title: "",
          inputs: [
            {
              name: "Email",
              value: "email",
              type: "bubble",
              required: true
            }
          ],
        },
      ],
    };
  },
  watch: {
    windowHeight() {
      this.$nextTick(() => {
        this.dynamicHeight;
      });
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.windowHeight = window.innerHeight;
    this.HelpManagementStore.setPage('Billing / International Rates Lookup');
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    ...mapStores(useInternationalRatesStore, useHelpStore),
    dynamicHeight() {
      return Number(this.windowHeight) - 43;
    },
    getNavButtons() {
      if (this.showTable) {
        return [
          { title: "Download", icon: "Export.png" },
          { title: "Email All Data", icon: "email.png" },
        ];
      }
      return [
        { title: "Email All Data", icon: "email.png" },
      ];
    },
  },
  methods: {
    ...mapActions(useInternationalRatesStore, ['fetchRates']),
    ...mapActions(useHelpStore, ['setPage']),

    validateEmail(email) {
      const emailRule = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
      if (!emailRule) {
        addAlert(ERROR, 'Please enter a valid email', this.alerts);
        return false;
      }

      return true;
    },
    handleResize() {
      this.windowHeight = window.innerHeight;
    },
    handleButtonClick(title) {
      if (title === "Download") {
        downloadCSV(
          this.headers,
          this.data,
          this.filterOptionsArray,
          "internationalRates"
        );
      }

      if (title === 'Email All Data') {
        this.drawer = !this.drawer
      }
    },
    handleCloseClick () {
      this.drawer = false;
    },
    async handleFormSubmission(formData) {
      if (formData === "Error" || Object.keys(formData).length === 0) {
        this.showTable = false;
        addAlert(ERROR, "Please enter a Search Value", this.alerts);
      } else {
        //APPLY FILTERS
        this.filterOptionsArray = [];

        await this.fetchRates(formData["Country"], formData["Dial Prefix"]);


        var ratesData = this.InternationalRatesStore.getRates;
        this.data = [];

        for (const index in ratesData) {
          this.data.push({
            country: ratesData[index].country,
            dialPrefix: ratesData[index].dial_prefix,
            retailRate: ratesData[index].retail_rate,
            wholesaleRate: ratesData[index].wholesale_rate,
          })
        }

        this.showTable = true;
      }
    },
    buildCSVContent(headers, data) {
      const csvRows = [];
      const headerRow = headers.map((header) => header.text);
      csvRows.push(headerRow);

      data.forEach((row) => {
        const rowData = headers.map((header) => row[header.value]);
        csvRows.push(rowData);
      });

      return csvRows.map((row) => row.join(",")).join("\n");
    },
    async handleDrawerSubmit(submissionData) {
      if (submissionData !== ERROR) {
        const { email } = submissionData;

        if (!this.validateEmail(email)) { return false }

        addAlert(NOTIFICATION, "It may take a few minutes to email the data", this.alerts);

        const csvContent = this.buildCSVContent(this.headers, this.data);

        const filename = `InterantionalRates.csv`;
        const S3 = useS3Store();
        const authStore = useAuthStore();

        try {
          const uploadResult = await S3.putObject({
            Bucket: authStore.$state.s3_bucket,
            Key: `public/internationalRates/${filename}`,
            ContentType: 'text/csv',
            Body: new Blob([csvContent], {type: 'text/csv'})
          });


          const ratesFilePath = uploadResult.key;
          const ratesFileName = filename;

          const emailResult = await this.InternationalRatesStore.sendMail(email, ratesFilePath, ratesFileName);

          if (!emailResult.error) {
            addAlert(SUCCESS, "EMAIL SENT SUCCESSFULLY", this.alerts);
          } else {
            addAlert(ERROR, emailResult.message, this.alerts);
          }
        } catch (uploadError) {
          addAlert(ERROR, "CSV upload failed: " + uploadError.message, this.alerts);
          return;
        }

        this.drawer = false;
        this.submissionData = {};
      } else {
        addAlert(ERROR, MISSING_FIELDS, this.alerts);
      }
    },
    handleClickRow (data) {
      console.log(data);
    }
  },
};
</script>

<style scoped>
html {
  overflow-y: auto !important;
}
.intRateBackground {
  background-color: #e2f1f4;
  min-height: calc(100vh - 93px);
}
.intRateContainer {
  display: flex;
  width: calc(100vw - 421px);
  padding-left: 20px;
}
@media (max-width: 820px) {
  .intRateContainer {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0px;
  }
}
.intRatePageContent {
  flex-grow: 1;
}
.intRateContentContainer {
  display: flex;
  flex-grow: 1;
  position: relative;
}
.intRateFormContainer {
  margin: 20px 0px 20px 0px;
  filter: drop-shadow(1px 1px 1px rgb(39, 29, 122, 0.2));
}
.configAlerts {
  position: fixed;
  right: 0;
  padding-bottom: 10px;
  padding-right: 20px;
  z-index: 9;
}
</style>
