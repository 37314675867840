<template>
    <v-form class="form-container">
        <v-row v-for="section in PROPS.form_input_layout" v-bind:key="section">
            <v-col 
                :cols="input.cols ? input.cols : 'auto'"
                :class="{buttonColumnStyle : input.type == 'dark_button' || input.type == 'button'}"
                :style="getCustomCSS(input.custom_css)"
                v-for="input in section.inputs" v-bind:key="input.value"
            >
                <TextFieldComponent 
                    v-if="input.type == 'text_field'"
                    :label="input.label"
                    v-bind="{...input.props}"
                    v-model="form_data[input.value]"
                />
                <SelectComponent 
                    v-if="input.type == 'select'"
                    :label="input.label"
                    :options="input.options"
                    v-bind="{...input.props}"
                    v-model="form_data[input.value]"
                />
                <MultiSelectComponent 
                    v-if="input.type == 'multi_select'"
                    :label="input.label"
                    :options="input.options"
                    v-bind="{...input.props}"
                    v-model="form_data[input.value]"
                    @close-select="emitClose(input.value)"
                />
                <TextAreaComponent 
                    v-if="input.type == 'text_area'"
                    :label="input.label"
                    v-bind="{...input.props}"
                    v-model="form_data[input.value]"
                />
                <DateComponent
                    v-if="input.type == 'date'"
                    :label="input.label"
                    v-bind="{...input.props}"
                    v-model="form_data[input.value]"
                    @clear-key="handleClear(input.value)"
                />
                <CheckBoxComponent
                    v-if="input.type == 'checkbox'"
                    :label="input.label"
                    v-bind="{...input.props}"
                    v-model="form_data[input.value]"
                />
                <DarkButton 
                    v-if="input.type == 'dark_button'"
                    :title="input.title"
                    @click.prevent="emitButtonAction(input.action)"
                />
                <ButtonComponent
                    v-if="input.type == 'button'"
                    :title = "input.title"
                    v-bind="{...input.props}"
                    @click="emitButtonAction(input.action)"
                />
                <DynamicFilterComponent
                    v-if="input.type == 'dynamic_filter'"
                    :label="input.title"
                    v-bind="{...input.props}"
                    v-model="form_data[input.value]"
                />
                <TextEditorComponent
                    v-if="input.type == 'text_editor'"
                    :label="input.label"
                    v-bind="{...input.props}"
                    v-model="form_data[input.value]"
                >
                </TextEditorComponent>
                <div v-if="input.type == 'read_only'">
                    <span style="color: #271d7a; font-size: 10pt;">{{ input.label }}: {{ form_data[input.value] }}</span>
                </div>
            </v-col>
        </v-row>
    </v-form>
</template>



<script setup>
/* eslint-disable */ 

/*
    //TODO:
        Get rid of 'dark_button' component once it had been removed out of use in code. DO NOT USE IT IN NEW CODE.


    **DOCUMENTATION**
    *** IF YOU ARE HAVING A PROBLEM LOOK BELOW***
    *** IF YOU FIND A QUIRK PLEASE ADD BELOW***
      
    Previous Form Values
        -When populating a DateComponent with UTC ISO Strings, Convert them to Locale ISO Strings First.

    Input - cols:
        - auto is not entirely reliable, as it varies between computers.  In some cases, the inputs will not reach the full width of the row.


    Example Input Object with all Parameters
    {
        title: "Reset",
        cols: 6,
        custom_css: {
            'justify-content': "end",
            color: "red",
            'background-color': "blue"
        },
        type: "dark_button"
    }
*/






import { defineProps, onMounted, watch, reactive, ref, defineEmits, defineExpose, computed } from "vue";
import TextFieldComponent from "@/components/base_components/TextFieldComponent.vue";
import SelectComponent from "@/components/base_components/SelectComponent.vue";
import MultiSelectComponent from "@/components/base_components/MultiSelectComponent.vue";
import TextAreaComponent from "@/components/base_components/TextAreaComponent.vue";
import DateComponent from "@/components/base_components/DateComponent.vue";
import DarkButton from "@/components/base_components/Buttons/DarkButton.vue";
import { useAlertsStore } from "@/stores/alertsStore.js";
import CheckBoxComponent from "@/components/base_components/CheckboxComponent.vue";
import DynamicFilterComponent from "@/components/base_components/FormComponents/DynamicFilterComponent.vue";
import ButtonComponent from "./Buttons/ButtonComponent.vue";
import TextEditorComponent from "./TextEditorComponent.vue";



//Constants
const form_data = ref({});
const alertsStore = useAlertsStore();

let inputs_map = {};
for (let section of PROPS.form_input_layout) { //Gather all the Input values and tie them to each Input Label for Showing in form validation
    for (let input of section.inputs) {
        if (input?.label && input?.value) {
            inputs_map[input.value] = input.label;
        }
    }
}


//Emits
const emit = defineEmits(["form-data-update", "action_button_pressed", "closed"]);

 //Exposes
 defineExpose({
    resetFormData,
    submitFormData,
    checkFormData
});

//Props
const PROPS = defineProps({
    form_input_layout: { //Controls what values the column search can access. By default null is every column.
        type: Array,
        default: () => []
    },
    previous_form_data: {
        type: Object,
        default: () => {}
    },
    validation_rules: {
        type: Object,
        default: () => {}
    }
});

//Main Functions
function emitButtonAction(action) {
    emit('action_button_pressed', action);
}

function handleCheckBox(item) {
    console.log("HANDLE CHECK BOX: ", item);
}

//Applies Custom CSS To Elements Column based on custom_css object existing on input object
function getCustomCSS(custom_css) {
    if (custom_css) {
        return custom_css;
    }
    else {
        return "";
    }
}

//Used for clearing date components from form data, to remove lingering keys.
function handleClear(key) {
    delete form_data.value[key];
}
 
// Used for cases when we don't want Multiselect v-model changes to initiate code.  This gives the option for watching for the multi-select to close.
function emitClose(field) {
    emit('closed', field)
}

//Exposed Functions
function resetFormData() {
    form_data.value = {};
}

function submitFormData() {
    console.log("SUBMIT FORM DATA: ", form_data.value);

    for (const [field_name, rules] of Object.entries(PROPS.validation_rules)) { // Form Validation
        for (const rule_function of rules) {
            const validation_response = rule_function(field_name, form_data.value, inputs_map[field_name]);
            if (!validation_response.valid) {
                alertsStore.addAlert("Error", validation_response.message);
                return validation_response;
            }
        }
    }

    return {
        valid: true,
        form_data: form_data.value
    }
}

function checkFormData() {
    return form_data.value;
}

const form_data_computed = computed(() => {
    return Object.assign({}, form_data.value);
});

//Watchers
watch(form_data_computed, (new_data, old_data) => {
        if (JSON.stringify(new_data) != JSON.stringify(old_data)) {
            emit('form-data-update', new_data);
        }
    },
    {deep: true}
);

watch(() => PROPS.previous_form_data, (new_data) => {
        form_data.value = new_data;
    },
    {deep: true}
);

//Life Cycle Hooks
onMounted(() => {
    if (PROPS.previous_form_data) { //Sets Previous form_data if it was sent
        form_data.value = PROPS.previous_form_data;
    }
});

//Validation Rule Functions




</script>







<style scoped>
.form-container {
    padding: 5px;
}

.buttonColumnStyle {
    display: flex;
    align-items: flex-end;
}

</style>


