<template>
    <div v-if="range">
        <div>
            <label v-if="PROPS.required" class="inputLabel">{{ PROPS.label }} *</label>
            <label v-else class="inputLabel">{{ PROPS.label }}</label>
        </div>
        <v-row>
            <v-col cols="6">
                <VDatePicker :modelValue="setValue.start" :mode="mode" @update:modelValue="handleRangeSelected('start', $event)" :popover="{visibility: 'click'}">
                    <template #default="{ inputValue, inputEvents }">
                            <input class="dateInputRange" :value="inputValue" v-on="inputEvents" :placeholder="`${PROPS.label} from`"/>
                    </template>
                </VDatePicker>
            </v-col>
            <v-col cols="6">
                <VDatePicker :modelValue="setValue.end" :mode="mode" @update:modelValue="handleRangeSelected('end', $event)" :popover="{visibility: 'click'}">
                    <template #default="{ inputValue, inputEvents }">
                            <input class="dateInputRange" :value="inputValue" v-on="inputEvents" :placeholder="`${PROPS.label} to`"/>
                    </template>
                </VDatePicker>
            </v-col>
        </v-row>
    </div>
    <div v-else>
        <VDatePicker :modelValue="setValue" :mode="mode" @update:modelValue="handleSelected" :popover="{visibility: 'click'}">
            <template #default="{ inputValue, inputEvents }">
                    <div>
                        <label v-if="PROPS.required" class="inputLabel">{{ PROPS.label }} *</label>
                        <label v-else class="inputLabel">{{ PROPS.label }}</label>
                    </div>

                    <input v-if="range == false" class="dateInput" :value="inputValue" v-on="inputEvents" />
            </template>
        </VDatePicker>
    </div>
    
    
</template>



<script setup>
import { defineProps, defineEmits, computed, ref } from 'vue';

const PROPS = defineProps({
    label: {
        type: String,
        default: ""
    },
    disabled: { //Disable Input from being interactable
        type: Boolean,
        default: false
    },
    mode: {
        type: String,
        default: "date"
    },
    range: {
        type: Boolean,
        default: false
    },
    required: {
        type: Boolean,
        default: false
    },
    modelValue: {
        type: Object,
        default: () => {}
    }
});


const setValue = computed(() => {
    if (PROPS.modelValue) {
        if (PROPS.range) {
            var start = PROPS.modelValue.start || {};
            var end = PROPS.modelValue.end || {};
            return {start: start, end: end};
        }
        else {
            return PROPS.modelValue.start || {};
        }
    }
    else {
        return {}
    }
});

const start = ref(null);
const end = ref(null);

// Emits
const emit = defineEmits(["update:modelValue", "clear-key"]);


function handleSelected(date) {
    if (!date) { //Handle Clearing Submission
        emit("clear-key");
    } else {
        const date_value = new Date(date).toISOString();
        emit("update:modelValue", {start: date_value});
    }
}

function handleRangeSelected(input, date) {
    if(input == 'start') {
        if(date) {
            const start_value = new Date(date).toISOString();
            start.value = start_value;
        } else {
            start.value = null;
        }

        if(!end.value && PROPS.modelValue && PROPS.modelValue.end) {
            end.value = PROPS.modelValue.end;
        }
    } else if(input == 'end') {
        if(date) {
            const end_value = new Date(date).toISOString();
            end.value = end_value;
        } else {
            end.value = null;
        }

        if(!start.value && PROPS.modelValue && PROPS.modelValue.start) {
            start.value = PROPS.modelValue.start;
        }
    }

    if(!start.value && !end.value) {
        emit("clear-key");
    } else {
        //Build the 
        var value = {};
        if(start.value) {
            value['start'] = start.value;
        }
        if(end.value) {
            value['end'] = end.value;
        }

        emit("update:modelValue", value);
    }

}

</script>



<style scoped>

.dateInput, .dateInputRange {
  border: 0.25px solid #2e3e91;
  border-radius: 3px;
  padding-left: 10px;
  color: #271d7a;
  padding: 2px 2px 2px 8px;
  font-size: 9pt;
  height: 29px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-size: 16px 16px;
  background-image: url("@/assets/icons/Calendar.png");
}

.dateInputRange {
    width: 100%;
}

.inputLabel {
  font-size: 9pt;
  font-weight: 600;
  color: #271d7a;
  padding-bottom: 5px;
}

</style>