<template>
    <div>
        <label v-if="PROPS.required" class="inputLabel">{{ PROPS.label }} *</label>
        <label v-else class="inputLabel">{{ PROPS.label }}</label>
    </div>
    <textarea :style="height" class="textArea" :disabled="PROPS.disabled" @input="handleInputChange" :value="PROPS.modelValue"></textarea>
</template>



<script setup>

import { defineProps, defineEmits, computed } from "vue";


const PROPS = defineProps({
    label: {
        type: String,
        default: ""
    },
    disabled: { //Disable Input from being interactable
        type: Boolean,
        default: false
    },
    height: {
        type: Number,
        default: 0
    },
    required: {
        type: Boolean,
        default: false
    },
    modelValue: String
});

//Emits
const emit = defineEmits(["update:modelValue"]);



//Functions
function handleInputChange(event) {
    emit("update:modelValue", event.target.value);
}


const height = computed(() => {
    if (PROPS.height) {
        return {'min-height': PROPS.height + 'px'}
    }
    
    return {'min-height': 29 + 'px'}
});


</script>


<style scoped>

.textArea {
    border: #2e3e91 solid 0.25pt;
    border-radius: 2px;
    font-size: 9pt;
    font-weight: 500;
    padding-left: 8px;
    padding-top: 4px;
    color: #271d7a;
    width: inherit;
    background-color: white;
    min-width: 155px;
    &:focus {
        outline: transparent;
    }
}

.inputLabel {
  font-size: 9pt;
  font-weight: 600;
  color: #271d7a;
  padding-bottom: 5px;
}


/* .textArea {
  border: #2e3e91 solid 0.25pt;
  border-radius: 2px;
  height: 28px;
  padding-left: 8px;
  padding-top: 4px;
  font-size: 9pt;
  font-weight: 500;
  color: #271d7a;
  
  &:focus {
    outline: transparent;

    &::placeholder {
      opacity: 1;
    }
  }
  &::placeholder {
    opacity: 0;
    size: 9pt;
    color: #b1cdea;
  }
} */
</style>