<template>
    <div>
        <label v-if="PROPS.required" :class="label_size_class">{{ PROPS.label }} *</label>
        <label v-else :class="label_size_class">{{ PROPS.label }}</label>
    </div>
    <div id="toolbarContainer">
        <Toolbar class="toolbar" :editor="editor" defaultColor="#000000" :showColorWheel="false" />
    </div>
    <div id="editorContainer">
        <editor-content :editor="editor" />
    </div>
    <div id="bottomContainer">
        <div v-if="enable_attachments" style="display: flex; align-items: center">
            <v-tooltip text="Attachment" location="top">
                <template v-slot:activator="{ props }">
                    <img style="width: 10px; height: 20px; margin-left: 15px; cursor: pointer;" v-bind="props"
                        :src="require('@/assets/icons/Attachment.png')" @click="handleFileButtonUpload" />
                </template>
            </v-tooltip>


            <div v-if="!is_attached" @dragover.prevent @drop.prevent class="file-holder"
                :class="{ 'dragging': is_dragging, 'attached': is_attached }" @dragenter="handleDragEnter"
                @dragleave="handleDragLeave" @drop="handleFileDrop">
                <input class="fileUploadInput" type="file" name="file" ref="fileUpload" @change="handleFileChange">
            </div>
            <div v-else class="attached-file">
                {{ current_uploaded_filename }}
                <img style="width: 15px; cursor: pointer;" :src="require('@/assets/icons/Remove.png')"
                    @click="removeFile" />
            </div>
        </div>
    </div>
</template>


<script setup>
import { Editor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import Underline from "@tiptap/extension-underline";
import Highlight from "@tiptap/extension-highlight";
import TextStyle from "@tiptap/extension-text-style";
import Color from "@tiptap/extension-color";
import Toolbar from "@/components/LEX/TextBoxLEX/subComponents/Toolbar.vue";
import { ref, defineProps, computed, watch, defineEmits } from "vue";


const is_dragging = ref(false);
const is_attached = ref(false);
const current_uploaded_filename = ref(null);
const attachment = ref(null);
const fileUpload = ref();

const PROPS = defineProps({
    label: {
        type: String,
        default: ""
    },
    required: {
        type: Boolean,
        default: false
    },
    text_content: {
        type: String,
        default: ""
    },
    output_type: { // html | text
        type: String,
        default: 'html'
    },
    enable_attachments: {
        type: Boolean,
        default: false
    },
    modelValue: Object
});




const label_size_class = computed(() => {
    if (PROPS.size == 'large') {
        return 'labelLarge';
    }
    else {
        return 'labelDefault';
    }
});

const emit = defineEmits(["update:modelValue"]);


const editor = new Editor({
    extensions: [
        StarterKit,
        Highlight.configure({ multicolor: true }),
        TaskList,
        TaskItem,
        Underline,
        TextStyle,
        Placeholder.configure({
            placeholder: "Begin typing your message here...",
        }),
        Color.configure({
            types: ["textStyle"],
        }),
        
    ],
    content: ""
});

editor.commands.setContent(PROPS.text_content);

emit("update:modelValue", {
    content: PROPS.text_content,
    attachment: attachment.value
});


editor.on('blur', () => {
    updateModelValue();
});


watch(() => PROPS.text_content, (newVal) => {
    editor.commands.setContent(newVal);

    updateModelValue();
});



function handleDragEnter() {
    is_dragging.value = true;
}

function handleDragLeave () {
    is_dragging.value = false;
}

function handleFileDrop(e) {
    var file = e.dataTransfer.files[0];
    handleFileProcessing(file);
    is_dragging.value = false;
}

function handleFileChange() {
    if (fileUpload.value.files[0]) {
        var file = fileUpload.value.files[0];
        handleFileProcessing(file);
    }
}

function handleFileButtonUpload() {
    if (!is_attached.value) {
        fileUpload.value.click();
    }
}

function updateModelValue() {
    if (PROPS.output_type === 'html') {
        emit("update:modelValue", {
            content: editor.getHTML(),
            attachment: attachment.value
        });
    }
    else if (PROPS.output_type === 'text') {
        emit("update:modelValue", {
            content: editor.getText(),
            attachment: attachment.value
        });
    }
}


function removeFile() {
    current_uploaded_filename.value = null;
    attachment.value = null;
    is_attached.value = false;

    updateModelValue();
}

function handleFileProcessing(file) {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
        current_uploaded_filename.value = file.name;
    
        attachment.value = {
            file_name: current_uploaded_filename.value,
            file: file
        };

        updateModelValue();

        is_attached.value = true;
    };
}


</script>


<style scoped>
#toolbarContainer {
    display: flex;
    border-top: 1px solid grey;
    border-left: 1px solid grey;
    border-bottom: 1px solid rgb(224, 224, 224);
    border-right: 1px solid grey;
    background-color: #ecf2f8;
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

#editorContainer {
    border-right: 1px solid grey;
    border-left: 1px solid grey;
}

#bottomContainer {
    display: flex;
    border-right: 1px solid grey;
    border-left: 1px solid grey;
    border-bottom: 1px solid grey;
    background: #ecf2f8;
    padding-top: 15px;
    padding-bottom: 15px;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: center;
}

.v-tooltip> :deep(.v-overlay__content) {
    background-color: #3e87d3 !important;
}

:deep(.ProseMirror:focus) {
    outline: none;
}

:deep(.ProseMirror) {
    height: 350px;
    padding: 1rem;
    font-size: 12pt;
    background-color: #ffffff;
    overflow-y: auto;
}

:deep(.ProseMirror p.is-editor-empty:first-child::before) {
    color: #b1cdea;
    content: attr(data-placeholder);
    float: left;
    font-size: 12pt;
    height: 0;
    pointer-events: none;
}

.fileUploadInput {
    display: none;
}

.file-holder {
    margin-left: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 5px;
    padding-left: 5px;
    text-align: center;
    min-width: 200px;
    border-radius: 5px;
    border: 1px dashed #396fba;
    color: #271d7a;
    background-color: #e0ebf5;
    position: relative;
    font-size: 11pt;
}

.file-holder::before {
  content: 'Drag and Drop Attachment';
}

.file-holder.dragging {
  border: 1px dashed #c0d6ed;
  background-color: #c0d6ed;
}

.file-holder.dragging input[type="file"] {
  pointer-events: auto;
}

.file-holder.attached {
  border-color: transparent;
  background-color: transparent;
}

.attached-file {
    align-items: center;
    display: flex;
    margin-left: 10px;
    padding: 5px;
    gap: 13px;
    color: #271d7a;
}

.labelDefault {
    font-size: 9pt;
    font-weight: 600;
    color: #271d7a;
    padding-bottom: 5px;
}

.labelLarge {
    font-size: 25px;
    font-weight: 500;
    color: #271d7a;
    padding-bottom: 5px;
}
</style>