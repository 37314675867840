<template>
    <v-container class="main_container">
        <v-row>
            <v-col>
                <v-progress-linear :class="[isLoading ? showLoader : '', hideLoader]" color="#271d7a" indeterminate></v-progress-linear>
            </v-col>
        </v-row>
        <v-row :class="loginContainerMarginControl">
            <v-col align="center" class="imageContainer">
                <img id="login_logo_image" src="@/assets/loginpage_logo.png" />
            </v-col>
        </v-row>
        <v-row>
            <v-col class="welcomeTextContainer">
                <span id="login_welcome_text">Welcome to LEX Connect</span>
            </v-col>
        </v-row>
        <v-window :touch="false" v-model="auth_step">
            <v-window-item value="LOGIN">
                <v-row>
                    <v-col class="sectionHeadingContainer">
                        <span id="login_section_heading">User Login</span>
                    </v-col>
                </v-row>
                <form>
                    <v-row>
                        <v-col class="textfieldContainer">
                            <TextFieldComponent label="Username" size="large" autocomplete="email" v-model="username" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="textfieldContainer">
                            <TextFieldComponent label="Password" size="large" autocomplete="current-password" type="password" v-model="password" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="buttonContainer">
                                <ButtonComponent class="buttonSizeOverride" button_type="dark" title="Login" @click="handleLogin"/>
                        </v-col>
                    </v-row>
                </form>
                <v-row>
                    <v-col class="bottomTextContainer">
                        <span class="bottomText" @click="changeScreen('FORGOT_PASSWORD')">Forgot Password?</span>
                    </v-col>
                </v-row>
            </v-window-item>
            <v-window-item value="SMS_MFA">
                <v-row>
                    <v-col class="sectionHeadingContainer">
                        <span id="login_section_heading">SMS Authentication Code</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="textContainer">
                        <p class="regularText">If code is not working please click "Resend Code" to receive a new code.</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="buttonContainer">
                        <DarkButton class="buttonSizeOverride" title="Resend Code" @click="resendSMSCode"/>
                    </v-col>
                </v-row>
                <form>
                    <v-row>
                        <v-col class="textfieldContainer">
                            <TextFieldComponent size="large" label="Code" v-model="totp_code" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="buttonContainer">
                            <ButtonComponent class="buttonSizeOverride" button_type="dark" title="Submit" @click="handleTOTPCode('SMS_MFA')"/>
                        </v-col>
                    </v-row>
                </form>
                <v-row>
                    <v-col class="checkboxContainer">
                        <CheckboxComponent v-model="remember_device"/>
                        <span class="rememberMeText">Remember Me</span>
                    </v-col>
                </v-row>
            </v-window-item>
            <v-window-item value="SOFTWARE_TOKEN_MFA">
                <v-row>
                    <v-col class="sectionHeadingContainer">
                        <span id="login_section_heading">Authenticator Code</span>
                    </v-col>
                </v-row>
                <form>
                    <v-row>
                        <v-col class="textfieldContainer">
                            <TextFieldComponent size="large" label="Code" v-model="totp_code" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="buttonContainer">
                            <ButtonComponent class="buttonSizeOverride" button_type="dark" title="Submit" @click="handleTOTPCode('SOFTWARE_TOKEN_MFA')"/>
                        </v-col>
                    </v-row>
                </form>
                <v-row>
                    <v-col class="checkboxContainer">
                        <CheckboxComponent v-model="remember_device"/>
                        <span class="rememberMeText">Remember Me</span>
                    </v-col>
                </v-row>
            </v-window-item>
            <v-window-item value="EMAIL_OTP">
                <v-row>
                    <v-col class="sectionHeadingContainer">
                        <span id="login_section_heading">Email Verification Code</span>
                    </v-col>
                </v-row>
                <form>
                    <v-row>
                        <v-col class="textfieldContainer">
                            <TextFieldComponent size="large" label="Code" v-model="totp_code" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="buttonContainer">
                            <ButtonComponent class="buttonSizeOverride" button_type="dark" title="Submit" @click="handleTOTPCode('EMAIL_OTP')"/>
                        </v-col>
                    </v-row>
                </form>
                <v-row>
                    <v-col class="checkboxContainer">
                        <CheckboxComponent v-model="remember_device"/>
                        <span class="rememberMeText">Remember Me</span>
                    </v-col>
                </v-row>
            </v-window-item>
            <v-window-item value="NEW_PASSWORD_REQUIRED">
                <v-row>
                    <v-col class="sectionHeadingContainer">
                        <span id="login_section_heading">New Password Required</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="textfieldContainer">
                        <TextFieldComponent size="large" :renderPasswordTooltip="true" label="New Password" type="password" v-model="force_new_password" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="textfieldContainer">
                        <TextFieldComponent size="large" label="Confirm Password" type="password" v-model="confirm_force_new_password" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="buttonContainer">
                        <DarkButton title="Submit" class="buttonSizeOverride" @click="handleForcePasswordChange" />
                    </v-col>
                </v-row>
            </v-window-item>
            <v-window-item value="SETUP_SOFTWARE_MFA">
                <v-row>
                    <v-col class="sectionHeadingContainer">
                        <span id="login_section_heading">Setup Authenticator</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="qrCodeContainer">
                        <QRCodeVue3 :width="200" :height="200" :dotsOptions="dotsOptions" :value="setup_totp_qr_url" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="buttonContainer">
                        <DarkButton class="buttonSizeOverride" style="width: 100%" title="Copy Setup Key to Clipboard" @click="copySoftwareSetupKey()" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="textContainer">
                        <p class="regularText">After setting up your authenticator app using the setup key above, enter the code generated below.</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="textfieldContainer">
                        <TextFieldComponent size="large" label="Code" v-model="verify_software_token_code" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="buttonContainer">
                        <DarkButton title="Submit" class="buttonSizeOverride" @click="verifySoftwareTokenCode" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="bottomTextContainer">
                        <span class="bottomText" @click="changeScreen('CHOOSE_MFA_PREFERENCE')">Back</span>
                    </v-col>
                </v-row>
            </v-window-item>
            <v-window-item value="SETUP_SMS_MFA">
                <v-row>
                    <v-col class="sectionHeadingContainer">
                        <span id="login_section_heading">Setup SMS Authentication</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="textfieldContainer">
                        <TextFieldComponent size="large" label="Phone Number" v-model="phone_number" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="buttonContainer">
                        <DarkButton title="Send Code" class="buttonSizeOverride" @click="addPhoneNumberAndSendVerification" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="textContainer">
                        <p class="regularText">If code is not working please click "Send Code" again to receive a new code.</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="textfieldContainer">
                        <TextFieldComponent size="large" label="Code Received" v-model="sms_totp_code" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="buttonContainer">
                        <DarkButton title="Submit" class="buttonSizeOverride" @click="verifySMSCode" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="bottomTextContainer">
                        <span class="bottomText" @click="changeScreen('CHOOSE_MFA_PREFERENCE')">Back</span>
                    </v-col>
                </v-row>
            </v-window-item>
            <v-window-item value="CHOOSE_MFA_PREFERENCE">
                <v-row>
                    <v-col class="sectionHeadingContainer">
                        <span id="login_section_heading">Choose MFA Type</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="selectContainer">
                        <SelectComponent size="large" placeholder="Choose MFA Type" :options="MFA_TYPE_OPTIONS" label="MFA Type" v-model="mfa_type"/>
                    </v-col>
                </v-row>
                <v-row style="margin-bottom: 2%;">
                    <v-col class="buttonContainer">
                        <DarkButton class="buttonSizeOverride" title="Submit" @click="handleChooseMFAPreference" />
                    </v-col>
                </v-row>
            </v-window-item>
            <v-window-item value="FORGOT_PASSWORD">
                <v-row>
                    <v-col class="sectionHeadingContainer">
                        <span id="login_section_heading">Password Reset</span>
                        <p class="regularText">Please enter your username to reset your password.</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="textfieldContainer">
                        <TextFieldComponent label="Username" size="large" v-model="username_for_password_reset" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="buttonContainer">
                        <DarkButton title="Reset Password" class="buttonSizeOverride" style="width: 50%;" @click="sendPasswordReset" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="textContainer">
                        <p class="regularText">Remember your password?</p>
                        <span class="bottomText" style="float: left;" @click="changeScreen('LOGIN')">Sign In</span>
                    </v-col>
                </v-row>
            </v-window-item>
            <v-window-item value="RESET_PASSWORD">
                <v-row>
                    <v-col class="sectionHeadingContainer">
                        <span id="login_section_heading">Reset Password</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="textContainer">
                        <p class="regularText">Please check your inbox for an email with the subject "LEX Connect Verification Code" and enter the code below.</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="textfieldContainer">
                        <TextFieldComponent label="Code" size="large" v-model="reset_password_code" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="textfieldContainer">
                        <TextFieldComponent label="New Password" :renderPasswordTooltip="true" size="large" type="password" v-model="new_pass_for_reset" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="textfieldContainer">
                        <TextFieldComponent label="Confirm Password" size="large" type="password" v-model="confirm_pass_for_reset" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="buttonContainer">
                        <DarkButton class="buttonSizeOverride" title="Submit" @click="confirmPasswordReset" />
                    </v-col>
                </v-row>
            </v-window-item>
        </v-window>
    </v-container>
</template>



<script setup>
import { ref, computed } from 'vue';
import TextFieldComponent from '../base_components/TextFieldComponent.vue';
import DarkButton from '../base_components/Buttons/DarkButton.vue';
import { useAlertsStore } from "@/stores/alertsStore.js";
import { useAuthStore } from '@/stores/auth/authStore';
import QRCodeVue3 from "qrcode-vue3";
import SelectComponent from '../base_components/SelectComponent.vue';
import CheckboxComponent from '../base_components/CheckboxComponent.vue';
import ButtonComponent from '../base_components/Buttons/ButtonComponent.vue';
import awsconfig from '@/aws-exports';

const USER_POOL_ID = awsconfig.aws_user_pools_id.split("_")[1];
const CLIENT_ID = awsconfig.aws_user_pools_web_client_id;


//General Variables
const alertsStore = useAlertsStore();
const authStore = useAuthStore();
const auth_step = ref('LOGIN');
const mfa_type = ref(null);
const isLoading = ref(false);
const hideLoader = ref('hideLoader');
const showLoader = ref('showLoader');

//Reset Password Variables
const username_for_password_reset = ref(null);
const reset_password_code = ref(null);
const new_pass_for_reset = ref(null);
const confirm_pass_for_reset = ref(null);

//Force Password Change Variables
const force_new_password = ref(null);
const confirm_force_new_password = ref(null);


//Sign in Variables
const username = ref(null);
const password = ref(null);
const totp_code = ref(null);


//SMS Setup Variables
const phone_number = ref(null);
const sms_totp_code = ref(null);

//Software MFA Variables
const verify_software_token_code = ref(null);
const setup_totp_code = ref(null);
const setup_totp_qr_url = ref(null);

//Remember Device Variables
const remember_device = ref(false);

//Login Process Variables
const current_session = ref(null);
const current_user_id = ref(null);


const dotsOptions = {
    type: 'square',
    color: '#271d7a',
}

const MFA_TYPE_OPTIONS = [
    {label: "Authenticator Application", value: "SETUP_SOFTWARE_MFA"},
    {label: "SMS", value: "SETUP_SMS_MFA"},
    {label: "Email", value: "SETUP_EMAIL_OTP"}
]



//Computed

const loginContainerMarginControl = computed(() => {
    if (auth_step.value == 'RESET_PASSWORD' || auth_step.value == 'SETUP_SMS_MFA') {
        return 'TenPercentContainerMargin';
    }
    else if (auth_step.value == 'SETUP_SOFTWARE_MFA') {
        return 'ZeroPercentContainerMargin';
    }
    else {
        return "defaultContainerMargin";
    }
});




//Functions
function changeScreen(STEP) {
    auth_step.value = STEP;
}


async function handleLogin() { //Handles the Sign in Page - Redirects The User Depending on the challange returned **NEXT_STEP**
    if (!username.value) { alertsStore.addAlert("Error", `Username cannot be empty.`); return; }
    if (!password.value) { alertsStore.addAlert("Error", `Password cannot be empty.`); return; }

    isLoading.value = true;

    try {
        const device_key = localStorage.getItem(`${btoa(username.value)}.${btoa(USER_POOL_ID)}.deviceKey`);
        const inititate_auth_response = await initiateAuth(device_key);

        current_session.value = inititate_auth_response.data.Session;
        current_user_id.value = inititate_auth_response.user_id;
        const NEXT_STEP = inititate_auth_response.data.ChallengeName;

        if (NEXT_STEP === 'DEVICE_SRP_AUTH') {
            const random_password_key = localStorage.getItem(`${btoa(username.value)}.${btoa(USER_POOL_ID)}.randomPasswordKey`);
            const device_group_key = localStorage.getItem(`${btoa(username.value)}.${btoa(USER_POOL_ID)}.deviceGroupKey`);

            const device_auth_request = await fetch(`https://api.lexconnect.cloud/user_auth/login_device`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user_id: current_user_id.value,
                    client_id: CLIENT_ID,
                    user_pool_id: USER_POOL_ID,
                    device_key: device_key,
                    random_password_key: random_password_key,
                    device_group_key: device_group_key,
                    session: current_session.value
                }),
            });

            const device_auth_response = await device_auth_request.json();

            if (device_auth_response.success && device_auth_response.data.AuthenticationResult) {
                setCachedRefreshAndAccessTokens(
                    device_auth_response.data.AuthenticationResult.RefreshToken,
                    device_auth_response.data.AuthenticationResult.AccessToken,
                    device_auth_response.data.AuthenticationResult.IdToken
                );

                await authStore.setUserAuthStatus(true);
            }
        }
        else if (NEXT_STEP) {
            changeScreen(NEXT_STEP);
        }
        else { 
            //Did not go through Device Auth / Did not get a MFA Challenge returned.
            //Send the user through mfa setup
            setCachedRefreshAndAccessTokens(
                inititate_auth_response.data.AuthenticationResult.RefreshToken,
                inititate_auth_response.data.AuthenticationResult.AccessToken,
                inititate_auth_response.data.AuthenticationResult.IdToken
            );
            changeScreen('CHOOSE_MFA_PREFERENCE');
        }
    }
    catch (error) {
        alertsStore.addAlert("Error", "Incorrect username or password."); 
    }

    isLoading.value = false;
}

async function handleTOTPCode(challenge_name) { //Handles Confirming the Email MFA Challenge
    if (!totp_code.value) { alertsStore.addAlert("Error", `Code cannot be empty.`); return; }

    isLoading.value = true;

    try {
        const initiate_mfa_request = await fetch(`https://api.lexconnect.cloud/user_auth/totp_confirm`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                client_id: CLIENT_ID,
                challenge_name: challenge_name,
                totp_code: totp_code.value,
                user_id: current_user_id.value,
                session: current_session.value,
            }),
        });

        const mfa_response = await initiate_mfa_request.json();

        if (mfa_response.data.AuthenticationResult) {
            setCachedRefreshAndAccessTokens(
                mfa_response.data.AuthenticationResult.RefreshToken,
                mfa_response.data.AuthenticationResult.AccessToken,
                mfa_response.data.AuthenticationResult.IdToken
            );
            await authStore.setUserAuthStatus(true);
 
            if (mfa_response.data.AuthenticationResult.NewDeviceMetadata) {  
                await addAndRememberDevice(mfa_response)
            }
        }
        else {
            throw new Error("Code is Invalid");
        }
    }
    catch (error) {
        alertsStore.addAlert("Error", "Code is Invalid.");
    }

    isLoading.value = false;
}

async function handleForcePasswordChange() { //Handles Force Change Password Step for New Users
    if (force_new_password.value != confirm_force_new_password.value) { alertsStore.addAlert("Error", `Passwords do not match.`); return; }

    isLoading.value = true;


    try {
        const force_change_password_request = await fetch(`https://api.lexconnect.cloud/user_auth/force_change_password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user_pool_id: awsconfig.aws_user_pools_id,
                username: username.value,
                new_password: force_new_password.value,
            }),
        });

        const force_change_password_response = await force_change_password_request.json();

        if (force_change_password_response.success) {
            alertsStore.addAlert("Success", "Password Changed.");
            username.value = null;
            password.value = null;
            changeScreen("LOGIN");
        }
    }
    catch ({ message }) {
        alertsStore.addAlert("Error", "Password does not match policy.");
    }

    isLoading.value = false;
}

async function verifySoftwareTokenCode() { //Verifies the setup of Authenticator App - If Successfull we are good to login the user.
    if (!verify_software_token_code.value) {alertsStore.addAlert("Error", `Code cannot be empty.`); return;}

    isLoading.value = true;

    try {
        const verify_software_token_request = await fetch(`https://api.lexconnect.cloud/user_auth/verify_software_token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: authStore.$state.cognito_tokens.access_token,
                user_code: verify_software_token_code.value
            }),
        });

        const verify_software_token_response = await verify_software_token_request.json();

        if (verify_software_token_response.success) {

            const set_preferred_mfa_response = await setPreferredMFA({
                SoftwareTokenMfaSettings: {
                    Enabled: true,
                    PreferredMfa: true,
                },
            });

            if (set_preferred_mfa_response.success) {
                await handleLogin();
            }
        }
    }
    catch(error) {
        alertsStore.addAlert("Error", "Code is Invalid.");
    }

    isLoading.value = false;
}

async function handleChooseMFAPreference() { //Handles Choosing what MFA the New User will be using.
    if (!mfa_type.value) { alertsStore.addAlert("Error", `MFA Type Cannot be empty.`); return; }  

    if (mfa_type.value === 'SETUP_SOFTWARE_MFA') {
        const associate_software_token_request = await fetch(`https://api.lexconnect.cloud/user_auth/associate_software_token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: authStore.$state.cognito_tokens.access_token
            }),
        });

        const associate_software_token_response = await associate_software_token_request.json();
        setup_totp_code.value = associate_software_token_response.data.SecretCode;

        setup_totp_qr_url.value = `otpauth://totp/LEXConnect:${username.value}?secret=${setup_totp_code.value}&issuer=LEXConnect`;
        changeScreen(mfa_type.value);
    }
    else if (mfa_type.value === 'SETUP_SMS_MFA') {
        changeScreen(mfa_type.value);
    }
    else if (mfa_type.value === 'SETUP_EMAIL_OTP') {
        const set_preferred_mfa_response = await setPreferredMFA({
            EmailMfaSettings: {
                Enabled: true,
                PreferredMfa: true
            }
        });

        console.log("set_preferred_mfa_response: ", set_preferred_mfa_response);

        if (set_preferred_mfa_response.success) {
            await handleLogin();
        }
    }
}

async function addPhoneNumberAndSendVerification() { //Adds Phone Number to User and send verification message
    if (!phone_number.value) { alertsStore.addAlert("Error", `Phone Number cannot be empty`); return; }

    let formatted_phone_number = "+1"  + phone_number.value.replace("-", "").trim();

    try {
        const update_user_attributes_request = await fetch(`https://api.lexconnect.cloud/user_auth/update_user_attributes`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: authStore.$state.cognito_tokens.access_token,
                user_attributes: [
                    {Name: "phone_number", Value: formatted_phone_number}
                ]
                
            }),
        });

        const update_user_attributes_response = await update_user_attributes_request.json();

        if (update_user_attributes_response.success) {
            const send_verify_user_attribute_request = await fetch(`https://api.lexconnect.cloud/user_auth/send_verify_user_attribute`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    access_token: authStore.$state.cognito_tokens.access_token,
                    attribute_name: "phone_number"
                }),
            });

            const send_verify_user_attribute_response = await send_verify_user_attribute_request.json(); 

            if (send_verify_user_attribute_response.success) {
                alertsStore.addAlert("Notification", "SMS Code Sent.");
            }
        }
    }
    catch(error) {
        alertsStore.addAlert("Error", "Invalid Phone Number.");
        return;
    }
}

async function verifySMSCode() { //Handles Verification of SMS Code send to user. If successful we are good to login the user.
    if (!sms_totp_code.value) { alertsStore.addAlert("Error", `Code cannot be empty.`); return; }

    try {
        const verify_user_attribute_submit_request = await fetch(`https://api.lexconnect.cloud/user_auth/verify_user_attribute_submit`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: authStore.$state.cognito_tokens.access_token,
                attribute_name: "phone_number",
                code: sms_totp_code.value
            }),
        });

        const verify_user_attribute_submit_response = await verify_user_attribute_submit_request.json(); 

        if (verify_user_attribute_submit_response.success) {
            const set_preferred_mfa_response = await setPreferredMFA({
                SMSMfaSettings: {
                    Enabled: true,
                    PreferredMfa: true
                }
            });

            if (set_preferred_mfa_response.success) {
                await handleLogin();
            }
        }
    }
    catch(error) {
        alertsStore.addAlert("Error", "Code is Invalid");
        return;
    }
}

async function sendPasswordReset() { //Initiate Password Reset
    if (!username_for_password_reset.value) { alertsStore.addAlert("Error", `Username cannot be empty.`); return; }


    try {
        const initiate_forgot_password_request = await fetch(`https://api.lexconnect.cloud/user_auth/initiate_forgot_password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                client_id: CLIENT_ID,
                username: username_for_password_reset.value
            }),
        });

        await initiate_forgot_password_request.json(); 

        alertsStore.addAlert("Notification", "Reset Password Code Sent.");
        changeScreen("RESET_PASSWORD");
    }
    catch(error) {
        alertsStore.addAlert("Error", "Failed to send Password Reset Code.");
        return;
    }
}

async function confirmPasswordReset() { //Confirm Password Reset
    if (new_pass_for_reset.value != confirm_pass_for_reset.value) { alertsStore.addAlert("Error", `Passwords do not match.`); return; }

    try {
        const confirm_forgot_password_request = await fetch(`https://api.lexconnect.cloud/user_auth/confirm_forgot_password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                client_id: CLIENT_ID,
                username: username_for_password_reset.value,
                confirmation_code: reset_password_code.value,
                new_password: new_pass_for_reset.value
            }),
        });

        const confirm_forgot_password_response = await confirm_forgot_password_request.json(); 

        if (confirm_forgot_password_response.success) {
            alertsStore.addAlert("Success", "Password Changed.");
            changeScreen("LOGIN");
        }
    }
    catch({ message }) {
        alertsStore.addAlert("Error", "Password does not match policy."); 
        return;
    }
}

async function copySoftwareSetupKey() {
    navigator.clipboard.writeText(setup_totp_code.value);
}

async function resendSMSCode() {
    try {
        const initiate_auth_request = await fetch(`https://api.lexconnect.cloud/user_auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: username.value,
                password: password.value,
                client_id: CLIENT_ID,
                user_pool_id: USER_POOL_ID
            }),
        });

        const inititate_auth_response = await initiate_auth_request.json();

        current_session.value = inititate_auth_response.data.Session;
        current_user_id.value = inititate_auth_response.user_id;

        alertsStore.addAlert("Success", "SMS Code Sent.");
    }
    catch(error) {
        alertsStore.addAlert("Error", "Failed to Resend Code."); 
    }
}

async function addAndRememberDevice(mfa_response) {
    const isBrowser = typeof navigator !== 'undefined';
    const userAgent = isBrowser ? navigator.userAgent : 'nodejs';

    const device_key = mfa_response.data.AuthenticationResult.NewDeviceMetadata.DeviceKey;
    const device_group_key = mfa_response.data.AuthenticationResult.NewDeviceMetadata.DeviceGroupKey;
    
    const confirm_device_request = await fetch(`https://api.lexconnect.cloud/user_auth/confirm_device`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            access_token: mfa_response.data.AuthenticationResult.AccessToken,
            device_key: device_key,
            device_group_key: device_group_key,
            user_id: current_user_id.value,
            remember_device: remember_device.value,
            user_agent: userAgent
        }),
    });

    const confirm_device_response = await confirm_device_request.json();

    

    setCachedDeviceKeyAndPassword(device_key, device_group_key, confirm_device_response.data.randomPasswordKey);
}

async function initiateAuth(device_key = null) {
    const initiate_auth_request = await fetch(`https://api.lexconnect.cloud/user_auth/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            username: username.value,
            password: password.value,
            client_id: CLIENT_ID,
            user_pool_id: USER_POOL_ID,
            device_key: device_key
        }),
    });

    const inititate_auth_response = await initiate_auth_request.json();

    //Recall to catch localStorage having a Device Key but Cognito can't recognize it.
    if (inititate_auth_response.success && inititate_auth_response.recall_initiate_auth) {
        clearCachedDeviceKeyAndPassword();
        return await initiateAuth();
    }

    return inititate_auth_response;
}

async function setPreferredMFA(mfa_settings) {
    const set_preferred_mfa_request = await fetch(`https://api.lexconnect.cloud/user_auth/set_preferred_mfa`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            access_token: authStore.$state.cognito_tokens.access_token,
            mfa_settings: mfa_settings
        }),
    });

    const set_preferred_mfa_response = await set_preferred_mfa_request.json(); 

    return set_preferred_mfa_response;
}


function clearCachedDeviceKeyAndPassword() {
    localStorage.removeItem(`${btoa(username.value)}.${btoa(USER_POOL_ID)}.deviceKey`);
    localStorage.removeItem(`${btoa(username.value)}.${btoa(USER_POOL_ID)}.deviceGroupKey`);
    localStorage.removeItem(`${btoa(username.value)}.${btoa(USER_POOL_ID)}.randomPasswordKey`);
}

function setCachedDeviceKeyAndPassword(device_key, device_group_key, random_password_key) {
    localStorage.setItem(`${btoa(username.value)}.${btoa(USER_POOL_ID)}.randomPasswordKey`, random_password_key);
    localStorage.setItem(`${btoa(username.value)}.${btoa(USER_POOL_ID)}.deviceKey`, device_key);
    localStorage.setItem(`${btoa(username.value)}.${btoa(USER_POOL_ID)}.deviceGroupKey`, device_group_key); 
}

function setCachedRefreshAndAccessTokens(refresh_token, access_token, id_token) {
    localStorage.setItem(`${btoa(username.value)}.${btoa(USER_POOL_ID)}.accessToken`, access_token);
    localStorage.setItem(`${btoa(username.value)}.${btoa(USER_POOL_ID)}.refreshToken`,refresh_token);
    localStorage.setItem(`${btoa(username.value)}.${btoa(USER_POOL_ID)}.idToken`,id_token);
    localStorage.setItem(`${btoa(USER_POOL_ID)}.lastAuthUser`, username.value);

    authStore.storeCognitoTokens(refresh_token, access_token, id_token);
}


</script>




<style scoped>
.imageContainer {
    margin-left: 20%;
    margin-right: 20%;
}

.welcomeTextContainer {
    margin-left: 20%;
    margin-right: 20%;
}

.sectionHeadingContainer {
    margin-top: 5%;
    margin-left: 20%;
    margin-right: 20%;
}

.textfieldContainer {
    margin-left: 20%;
    margin-right: 25%;
    margin-bottom: 1%;
}

.textContainer {
    margin-left: 20%;
    margin-right: 25%;
}

.buttonContainer {
    margin-left: 20%;
    margin-right: 25%;
}

.setupTotpCodeContainer {
    margin-left: 20%;
    margin-right: 20%;
}

.bottomTextContainer {
    margin-left: 20%;
    margin-right: 25%;
}

.qrCodeContainer {
    margin-left:  17%;
    margin-right: 20%;
}

.selectContainer {
    margin-left: 20%;
    margin-right: 25%;
}

.checkboxContainer {
    margin-left: 20%;
    margin-right: 20%;
}

.bottomText {
    float: right;
    border: 1px solid #fff;
    color: #3e87d3;
    font-weight: 500;
    font-size: 20px;
    font-family: "Red Hat Display", sans-serif;
    cursor: pointer;
}

.rememberMeText {
    padding-left: 2%;
    color: #271d7a;
    font-weight: 500;
    font-size: 18px;
    font-family: "Red Hat Display", sans-serif;
}

#login_welcome_text {
    font-weight: 500;
    font-family: "Red Hat Display", sans-serif;
    color: #271d7a;
    font-size: 30px;
    background-color: #ffffff;
}

#login_logo_image {
    max-width: 100%;
}

#login_section_heading {
    font-size: 28px;
    font-weight: 700;
    font-family: "Red Hat Display", sans-serif;
    color: #3e87d3;
    background-color: #fff;
}

.hideLoader {
    visibility: hidden;
}

.showLoader {
    visibility: visible;
}


.regularText {
    color: #271d7a;
    font-family: "Red Hat Display", sans-serif;
    font-weight: 400;
    font-size: 20px;
}


@media screen and (max-width: 960px) {
    .main_container {
        width: auto;
        margin-top: 10%;
        margin-right: 5%;
        margin-left: 5%;
        margin-bottom: 5%;
        padding-bottom: 5%;
        background-color: #fff;
    }
}


.buttonSizeOverride {
    width: 40%;
    height: 42px;
    font-size: 14pt;
    font-weight: 500;
}

.defaultContainerMargin {
    margin-top: 15% !important;
}

.TenPercentContainerMargin {
    margin-top: 10% !important;
}

.ZeroPercentContainerMargin {
    margin-top: 0% !important;
}




</style>