<template>
    <FormComponent
        :form_input_layout="form_layout"
        :previous_form_data="previousFormData"
        @action_button_pressed="handleActionButton"
        :validation_rules="validation_rules"
        ref="questionForm"
    >
    </FormComponent>
    <span style="margin-left: 5px;" v-if="question_result">{{ question_result }}</span>
</template>



<script setup>
    import FormComponent from "@/components/base_components/FormComponent.vue";
    import { useAuthStore } from "@/stores/auth/authStore";
    import { useS3Store } from "@/stores/aws/s3Store";
    import { onMounted, defineProps, ref, defineEmits } from "vue";
    import AWS from "aws-sdk";
    import { required_not_empty } from "@/constants/form_validators.js";
    import { useGeneralStore } from "@/stores/generalStore";
    import { useRoute } from "vue-router";

    const authStore = useAuthStore();
    const S3 = useS3Store();
    const form_layout = ref([]);
    const previousFormData = ref({});
    const questionForm = ref(null);
    const submissionScript = ref(null);
    const question_result = ref(null);
    const generalStore = useGeneralStore();

    const validation_rules = ref({});
    const route = useRoute();


    const lambda = new AWS.Lambda();


    //Props
    const PROPS = defineProps({
        script: {
            type: String,
            default: null
        },
        account_number: {
            type: String,
            default: null
        },
        ticket_number: {
            type: String,
            default: null
        },
        queue: {
            type: String,
            default: null
        },
        previous_flow_values: {
            default: null
        },
        selectedFlow: {
            default: null
        }
    });

    // //Emits
    const emit = defineEmits(["toggle-selected"]);



    async function formatSubmissionData(flowSubmissionData, selectedFlow) {
      let results = await submissionRecursiveFlatten(selectedFlow);


      var labels = document.querySelectorAll('.flowInputLabel');
      var labelsArray = [];

      labels.forEach(function(label) {
          var dataValue = label.getAttribute('data-question_id');
          labelsArray.push(dataValue);
      });


      const resultObject = labelsArray.reduce((result, itemId) => {
        if (flowSubmissionData[itemId]) {
          result[itemId] = flowSubmissionData[itemId];
        } else {
          result[itemId] = "";
        }
        return result;
      }, {});

      var formattedSubmissionData = "";
      for (const [key, value] of Object.entries(resultObject)) {
        let question = results.find(q => q.id === key);

        if (question && question.type == 'Custom Display') {
          let custom_display_spans = document.querySelectorAll(`.customDisplayHTML`);

          for (let span of custom_display_spans) {
            var dataValue = span.getAttribute('data-question_id');

            if (dataValue == question.id) {
              question.description = span.innerHTML;
              break;
            }
          }
        }

        if (!question || question.type == 'Flow') {
          continue;
        }

        let questionHtml = question.description.replace(/<\/?p>/g, '');
        formattedSubmissionData += `<p>${questionHtml}`;

        if (typeof value !== 'string') {
          if (Object.keys(value).length > 0) {
            formattedSubmissionData += `<br>`;
          }
          // eslint-disable-next-line
          for (const [option_key, option_value] of Object.entries(value)) {
            formattedSubmissionData += `${option_value.value}<br>`;
          }
        } else {
          formattedSubmissionData += `<br>${value}<br>`;
        }
        formattedSubmissionData += `</p>`;
      }

      return formattedSubmissionData;
    }


    async function submissionRecursiveFlatten(items, flatArray = []) {
      if (!items) { return }
      for (const item of items) {
        if (
          item.type !== "Display Only" &&
          item.type !== "Smart Link"
        ) {
          let itemObject = {};
          itemObject.id = item.id;
          itemObject.description = item.description;
          itemObject.type = item.type;

          flatArray.push(itemObject);
        }

        if (item.dependents) {
          let result = await submissionRecursiveFlatten(item.dependents);
          flatArray.push(...result);
          
        }
      }
      return flatArray;
    }


    async function handleActionButton(action) {
      if (action === 'submit' && submissionScript.value != null) {
        const form_result = questionForm.value.submitFormData();

        if (!form_result.valid) { return; }


        for (let form_object of form_layout.value) { //Disable Submit Action Button
          for (let input of form_object.inputs) {
            if (input?.action == "submit") {
              input.props["disabled"] = true;
            }
          }
        }

        question_result.value = "Process Running...";

        if (route.path.toLowerCase().includes("management")) {
          question_result.value = "Success: Script run Skipped...";
          toggleValue({label: "Success: Script run Skipped...", value: "Success: Script run Skipped..."});
        }
        else {
          const lambda_result = await lambda.invoke({
              FunctionName: submissionScript.value,
              Payload: JSON.stringify({
                  queue: PROPS.queue,
                  external_id: PROPS.account_number,
                  ticket_id: PROPS.ticket_number,
                  formData: form_result.form_data,
                  environment: generalStore.$state.ENVIRONMENT,
                  invoked_by: authStore.$state.email
              })
          }).promise();

          if (lambda_result.StatusCode == 200) {
              const lambda_payload = JSON.parse(lambda_result.Payload);
              question_result.value = lambda_payload.result;

              toggleValue({label: lambda_payload.result, value: lambda_payload.result});
          }
          else {
            question_result.value = "Problem creating Billing Ticket.";
            toggleValue({label: "Problem creating Billing Ticket", value: "Problem creating Billing Ticket"});
          }
        }
      }
    }

    //Functions
    function toggleValue(item) {
        emit("toggle-selected", item);
    }



    //Life Cycle Hooks
    onMounted(async () => {
        /* eslint-disable */

     

        for (const [key, value] of Object.entries(PROPS)) {
            if (key != "previous_flow_values" && key != "selectedFlow") {
                previousFormData.value[key] = value;
            }
        }


        const s3_result = await S3.getObject({
            Bucket: authStore.$state.s3_bucket,
            Key: `public/LEX/custom_input_question_configs/${PROPS.script}.json`
        });

        let custom_input_config = JSON.parse(s3_result.Body.toString());

        form_layout.value = custom_input_config.form_layout;
        submissionScript.value = custom_input_config.submission_script;

        const result = await formatSubmissionData(PROPS.previous_flow_values, PROPS.selectedFlow);
        previousFormData.value["current_flow_content"] = result;


        for (let form_object of form_layout.value) {
          for (let input of form_object.inputs) {
            if (input.type != "button") {
              validation_rules.value[input.value] = [ required_not_empty ]
            }
          }
        }
    });
</script>


<style scoped>




</style>