<template>
  <div>
    <!-- <div v-if="isAnyDrawerOpen" class="overlay"></div> -->
    <ManagementSideDrawer
      :drawer-open="localDrawer"
      buttonType="Close"
      :numCols="12"
      @close-click="handleCloseClick"
      :drawer-data="publishFormData"
      @form-submission="handleSubmit"
      :previousSubmissionData="submissionData"
      :readonly="isLocalReadonly"
      :width="300"
    />
    <ReviewSideDrawer
      :drawer-open="localDrawerReview"
      :drawer-data="getReviewFormData"
      :numCols="12"
      @close-click="handleCloseClick"
      @form-submission="handleReviewSubmit"
      @submission-data-independent="sendPdfToEmail"
      :previousSubmissionData="submissionData"
    />
    <EditSideDrawer
      :drawer-open="localDrawerEdit"
      :drawer-data="getEditFormData"
      :numCols="12"
      @close-click="handleCloseClick"
      @form-submission="handleEditSubmit"
      :previousSubmissionData="submissionData"
    />
    <div class="tableContainer">
      <TableComp
        ref="tableRef"
        :key="tableKey"
        :headers="tableHeaders"
        :data-list="filteredData"
        background-color="#e2f1f4"
        :single-select="true"
        :select-all="false"
        uniqueKey="id"
        :fixedWidth="true"
        :left-nav-present="true"
        :clearSelectedItems="clearTrigger"
        :filterOptionsArray="filterOptionsArray"
        @select-action="handleSelectedAction"
        @selected-item="handleSelectedItem"
        @edit-row="handleEditRow"
        @click-row="handleClickRow"
      />
    </div>
    <AlertsGroup
      v-model:alerts="alerts" 
    />
    <div
      v-show="pdfVisible"
      style="padding-top: 10000px;"
    >
      <div id="pdf-file">
        <QualityAuditsPdf
          :table-data="reviewFormData"
          :previousSubmissionData="submissionData"
        />
      </div>
    </div>
  </div>
</template>

<script>
//import TableComp from "@/components/base_components/TableComp.vue";
import TableComp from "@/components/CcManagement/TableCompQA.vue";
import ManagementSideDrawer from "@/components/Voice/Management/SideDrawer/ManagementSideDrawer.vue";
import ReviewSideDrawer from "./components/ReviewSideDrawer.vue";
import EditSideDrawer from "./components/EditSideDrawer.vue";
import QualityAuditsPdf from '@/components/CcManagement/Attendance/Pdf/QualityAuditsPdf.vue';
import AlertsGroup from '@/components/base_components/AlertsGroup.vue';
import { PUBLISHED, ERROR, SUCCESS, NOTIFICATION } from "@/constants/statuses.constants";
import { MISSING_FIELDS } from '@/constants/alerts.constants';
import { AUDITS_PUBLISHED_ALERT } from '@/constants/pages/quality-audits.constants';
// import { formatTableDate } from '@/helpers/dateUtils';
import html2pdf from "html2pdf.js";
import { addAlert } from '@/helpers/alertUtils';
import awsconfig from '@/aws-exports';

import { useQualityAuditStore } from "@/stores/ContactCenter/Management/QualityAuditStore.js";
import { mapStores } from 'pinia';
import { useS3Store } from "@/stores/aws/s3Store";
import { useAuthStore } from "@/stores/auth/authStore";

export default {
  name: 'QualityAuditsView',
  components: {
    TableComp,
    ManagementSideDrawer,
    ReviewSideDrawer,
    EditSideDrawer,
    AlertsGroup,
    QualityAuditsPdf
  },
  props: {
    filterOptionsArray: Array,
    drawer: {
      type: Boolean,
      default: false
    },
    qualityAuditId: {
      type: String,
      default: null
    },
    drawerReview: {
      type: Boolean,
      default: false
    },
    isReview: {
      type: Boolean,
      default: false
    },
    isDetailsInfo: {
      type: Boolean,
      default: false
    },
    isPublished: {
      type: Boolean,
      default: false
    },
    isReadonly: {
      type: Boolean,
      default: false
    },
    search: {
      type: String,
      default: ''
    },
    clearReviewForm: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tableHeaders: [
        //{ text: "", value: "checkbox", width: 22.5, fixed: true },
        { text: "Date of Audit", value: "audit_date" },
        { text: "Employee", value: "employee" },
        { text: "Ticket Number", value: "ticket_number" },
        { text: "Date of Call", value: "date_call" },
        { text: "Auditor", value: "auditor" },
        { text: "Audit Status", value: "audit_status" },
        { text: "", value: "edithover", width: 23.95 }
      ],
      editFormData: [
        {
          title: "Edit Audit",
          button: "Edit Audit", type: "form"
        },
        {
          title: 'Basic Information',
          inputs: [
            {
              name: "Date of Audit",
              type: "datePicker",
              value: 'audit_date',
              adjustForTimezone: true,
              required: true
            },
            {
              name: "Employee",
              value: 'employee',
              type: "bubble",
              required: true,
              readonly: true
            },
            {
              name: "Ticket Number",
              value: 'ticket_number',
              type: "bubble",
              required: true,
              readonly: true
            },
            {
              name: "Date of Call",
              type: "datePicker",
              value: 'date_call',
              adjustForTimezone: true,
              required: true,
            },
          ]
        },
        {
          title: "Compliance",
          inputs: [
            {
              name: "Verification Level",
              type: "select",
              options: [
                { label: "2-step", value: "twoStep" },
                { label: "3-step", value: "threeStep" },
              ],
            },
            {
              name: "Call Type",
              type: "select",
              options: [
                { label: "Inbound", value: "inbound" },
                { label: "Outbound", value: "outbound" },
              ],
            },
            { name: "verifiedFullServiceAddress", label: "Verified Full Service Address", type: "checkbox", isChecked: false, isVisible: false },
            { name: "verifiedFullAccountHolderSName", label: "Verified Full Account Holder's Name", type: "checkbox", isChecked: false, isVisible: false },
            { name: "askedForPermissionToAccessAccount", label: "Asked for Permission to Access Account", type: "checkbox", isChecked: false, isVisible: false },
            { name: "verifiedAccountNumberOrLast4DigitsOfPartnerProvidedRgOrOntMacAddress", label: "Verified Account Number or Last 4 Digits of Partner Provided RG or ONT MAC Address", type: "checkbox", isChecked: false, isVisible: false },
            { name: "statementOfRecording", label: "Statement of Recording", type: "checkbox", isChecked: false, isVisible: false },
            { name: "Comments", type: "textarea", value: "compliance_comments", readonly: false }
          ],
        },
        {
          title: "Overall Tone",
          inputs: [
            { name: "askingForMemberSAssistanceInsteadOfOrderingMemberAround", label: "Asking for Member's Assistance instead of Ordering Member Around", type: "checkbox", isChecked: false, isVisible: true },
            { name: "avoidingDeadAir", label: "Avoiding Dead Air", type: "checkbox", isChecked: false, isVisible: true },
            { name: "backgroundSoundQuality", label: "Background Sound Quality", type: "checkbox", isChecked: false, isVisible: true },
            { name: "ownershipThroughoutCall", label: "Ownership Throughout Call", type: "checkbox", isChecked: false, isVisible: true },
            { name: "pleaseAndThankYouPhrases", label: "Please and Thank You Phrases", type: "checkbox", isChecked: false, isVisible: true },
            { name: "politeThroughCall", label: "Polite Through Call", type: "checkbox", isChecked: false, isVisible: true },
            { name: "positiveTone", label: "Positive Tone", type: "checkbox", isChecked: false, isVisible: true },
            { name: "Comments", type: "textarea", value: "tone_comments", readonly: false, isVisible: true }
          ],
        },
        {
          title: "Strong Greeting And Ending",
          inputs: [
            { name: "endingBrandingWithPartnerName", label: "Ending - Branding with Partner Name", type: "checkbox", isChecked: false, isVisible: true },
            { name: "endingConfidentEnding", label: "Ending - Confident Ending", type: "checkbox", isChecked: false, isVisible: true },
            { name: "endingRecapValueStatement", label: "Ending - Recap/Value Statement", type: "checkbox", isChecked: false, isVisible: true },
            { name: "endingSettingCorrectExpectationsPositioning", label: "Ending - Setting Correct Expectations/Positioning", type: "checkbox", isChecked: false, isVisible: true },
            { name: "greetingEmpathyStatement", label: "Greeting - Empathy Statement", type: "checkbox", isChecked: false, isVisible: true },
            { name: "greetingIdentifyCorrectPartner", label: "Greeting - Identify Correct Partner", type: "checkbox", isChecked: false, isVisible: true },
            { name: "greetingIdentifyIssueOfferAssistance", label: "Greeting - Identify Issue/Offer Assistance", type: "checkbox", isChecked: false, isVisible: true },
            { name: "greetingIdentifyName", label: "Greeting - Identify Name", type: "checkbox", isChecked: false, isVisible: true },
            { name: "greetingOfferTicketNumber", label: "Greeting - Offer Ticket Number", type: "checkbox", isChecked: false, isVisible: true },
            { name: "Comments", type: "textarea", value: "greeting_comments", readonly: false }
          ],
        },
        {
          title: "Troubleshooting",
          inputs: [
            { name: "bestPossibleResolutionReached", label: "Best Possible Resolution Reached", type: "checkbox", isChecked: false, isVisible: true },
            { name: "correctUseOfProbingQuestions", label: "Correct Use of Probing Questions", type: "checkbox", isChecked: false, isVisible: true },
            { name: "followedCorrectTechnicalTroubleshootingProcess", label: "Followed Correct Technical Troubleshooting Process", type: "checkbox", isChecked: false, isVisible: true },
            { name: "memberLevelDescriptionOfEquipmentLimitedTechJargon", label: "Member-level Description of Equipment - Limited Tech Jargon", type: "checkbox", isChecked: false, isVisible: true },
            { name: "rebootedOntAndRgWithMemberPermission", label: "Rebooted ONT and RG with Member Permission", type: "checkbox", isChecked: false, isVisible: true },
            { name: "remainedWithinScopeOfSupport", label: "Remained within Scope of Support", type: "checkbox", isChecked: false, isVisible: true },
            { name: "Comments", type: "textarea", value: "trouble_comments", readonly: false }
          ],
        },
        {
          title: "Documentation",
          inputs: [
            { name: "correctEscalationField", label: "Correct Escalation Field", type: "checkbox", isChecked: false, isVisible: true},
            { name: "correctIssueCode", label: "Correct Issue Code", type: "checkbox", isChecked: false, isVisible: true },
            { name: "correctResolutionCode", label: "Correct Resolution Code", type: "checkbox", isChecked: false, isVisible: true },
            { name: "correctVerificationField", label: "Correct Verification Field", type: "checkbox", isChecked: false, isVisible: true },
            { name: "filledOutFlowProperlyAndTruthfully", label: "Filled Out Flow Properly and Truthfully", type: "checkbox", isChecked: false, isVisible: true },
            { name: "followedFlowStepsCorrectly", label: "Followed Flow Steps Correctly", type: "checkbox", isChecked: false, isVisible: true },
            { name: "partnerSpecificTicketHandling", label: "Partner Specific Ticket Handling", type: "checkbox", isChecked: false, isVisible: true },
            { name: "professionalAndDetailedNotes", label: "Professional and Detailed Notes", type: "checkbox", isChecked: false, isVisible: true },
            { name: "usedAdvancedSupportFlow", label: "Used Advanced Support Flow", type: "checkbox", isChecked: false, isVisible: true },
            { name: "Comments", type: "textarea", value: "documentation_comments", readonly: false }
          ]
        },
        {
          title: "Call Recording",
          inputs: [
            {
              name: "Audio",
              type: "audio",
              value: "audio",
              readonly: true
            },
          ]
        }
      ],
      tableData: [

      ],
      reviewFormData: [
        {
          title: "Review",
          button: "Export to PDF", type: "form"
        },
        {
          title: 'Basic Information',
          inputs: [
            {
              name: "Date of Audit",
              type: "bubble",
              value: 'audit_date',
              readonly: true
            },
            {
              name: "Employee",
              value: 'employee',
              type: "bubble",
              readonly: true
            },
            {
              name: "Ticket Number",
              value: 'ticket_number',
              type: "bubble",
              readonly: true
            },
            {
              name: "Date of Call",
              type: "bubble",
              value: 'date_call',
              readonly: true
            },
          ]
        },
        {
          title: "Compliance",
          inputs: [
           {
              name: "Verification Level",
              type: "select",
              options: [
                { label: "2-step", value: "twoStep" },
                { label: "3-step", value: "threeStep" },
              ],
            },
            {
              name: "Call Type",
              type: "select",
              options: [
                { label: "Inbound", value: "inbound" },
                { label: "Outbound", value: "outbound" },
              ],
            },
            { name: "verifiedFullServiceAddress", label: "Verified Full Service Address", type: "checkbox", isChecked: false, readonly: true },
            { name: "verifiedFullAccountHolderSName", label: "Verified Full Account Holder's Name", type: "checkbox", isChecked: false, readonly: true },
            { name: "askedForPermissionToAccessAccount", label: "Asked for Permission to Access Account", type: "checkbox", isChecked: false, readonly: true },
            { name: "verifiedAccountNumberOrLast4DigitsOfPartnerProvidedRgOrOntMacAddress", label: "Verified Account Number or Last 4 Digits of Partner Provided RG or ONT MAC Address", type: "checkbox", isChecked: false, readonly: true },
            { name: "statementOfRecording", label: "Statement of Recording", type: "checkbox", isChecked: false, readonly: true },
            { name: "Comments", type: "textarea", value: "compliance_comments", readonly: true }
          ],
        },
        {
          title: "Overall Tone",
          inputs: [
            { name: "askingForMemberSAssistanceInsteadOfOrderingMemberAround", label: "Asking for Member's Assistance instead of Ordering Member Around", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "avoidingDeadAir", label: "Avoiding Dead Air", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "backgroundSoundQuality", label: "Background Sound Quality", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "ownershipThroughoutCall", label: "Ownership Throughout Call", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "pleaseAndThankYouPhrases", label: "Please and Thank You Phrases", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "politeThroughCall", label: "Polite Through Call", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "positiveTone", label: "Positive Tone", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "Comments", type: "textarea", value: "tone_comments", readonly: true }
          ],
        },
        {
          title: "Strong Greeting And Ending",
          inputs: [
            { name: "endingBrandingWithPartnerName", label: "Ending - Branding with Partner Name", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "endingConfidentEnding", label: "Ending - Confident Ending", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "endingRecapValueStatement", label: "Ending - Recap/Value Statement", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "endingSettingCorrectExpectationsPositioning", label: "Ending - Setting Correct Expectations/Positioning", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "greetingEmpathyStatement", label: "Greeting - Empathy Statement", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "greetingIdentifyCorrectPartner", label: "Greeting - Identify Correct Partner", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "greetingIdentifyIssueOfferAssistance", label: "Greeting - Identify Issue/Offer Assistance", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "greetingIdentifyName", label: "Greeting - Identify Name", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "greetingOfferTicketNumber", label: "Greeting - Offer Ticket Number", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "Comments", type: "textarea", value: "greeting_comments", readonly: true }
          ],
        },
        {
          title: "Troubleshooting",
          inputs: [
            { name: "bestPossibleResolutionReached", label: "Best Possible Resolution Reached", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "correctUseOfProbingQuestions", label: "Correct Use of Probing Questions", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "followedCorrectTechnicalTroubleshootingProcess", label: "Followed Correct Technical Troubleshooting Process", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "memberLevelDescriptionOfEquipmentLimitedTechJargon", label: "Member-level Description of Equipment - Limited Tech Jargon", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "rebootedOntAndRgWithMemberPermission", label: "Rebooted ONT and RG with Member Permission", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "remainedWithinScopeOfSupport", label: "Remained within Scope of Support", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "Comments", type: "textarea", value: "trouble_comments", readonly: true }
          ],
        },
        {
          title: "Documentation",
          inputs: [
            { name: "correctEscalationField", label: "Correct Escalation Field", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "correctIssueCode", label: "Correct Issue Code", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "correctResolutionCode", label: "Correct Resolution Code", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "correctVerificationField", label: "Correct Verification Field", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "filledOutFlowProperlyAndTruthfully", label: "Filled Out Flow Properly and Truthfully", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "followedFlowStepsCorrectly", label: "Followed Flow Steps Correctly", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "partnerSpecificTicketHandling", label: "Partner Specific Ticket Handling", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "professionalAndDetailedNotes", label: "Professional and Detailed Notes", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "usedAdvancedSupportFlow", label: "Used Advanced Support Flow", type: "checkbox", isChecked: false, readonly: true, isVisible: true },
            { name: "Comments", type: "textarea", value: "documentation_comments", readonly: true }
          ]
        },
        {
          title: "Call Recording",
          inputs: [
            {
              name: "Audio",
              type: "audio",
              value: "audio",
              readonly: true
            }
          ]
        }
      ],
      publishFormData: [
        { title: "Publish", button: "Publish Audit", type: "form" },
        { title: '', inputs: [
          {
            name: "Date of Audit",
            type: "bubble",
            value: 'audit_date',
            required: true,
            readonly: true,
          },
          { 
            name: "Employee",
            value: 'employee',
            type: "bubble",
            required: true,
            readonly: true,
          },
          {
            name: "Date of Call",
            type: "bubble",
            value: 'date_call',
            required: true,
            readonly: true
          },
          {
            name: "Audit Status",
            type: "bubble",
            value: "audit_status",
            required: true,
            readonly: true,
          }
        ]}
      ],
      submissionData: {},
      alerts: [],
      pdfVisible: false,
      isSelectedRow: false,
      isUpdateForm: false,
      localDrawerEdit: false,
      editFormDataDefault: null,
      reviewFormDataDefault: null,
      tableKey: 0,
    }
  },
  computed: {
    ...mapStores(useQualityAuditStore),
    // isAnyDrawerOpen() {
    //   return this.localDrawer || this.localDrawerReview || this.localDrawerEdit;
    // },
    localDrawer: {
      get () {
        return this.drawer;
      },
      set (drawer) {
        this.$emit('update:drawer', drawer);
      }
    },
    localDrawerReview: {
      get () {
        return this.drawerReview
      },
      set (drawerReview) {
        this.$emit('update:drawerReview', drawerReview);
      }
    },
    localIsReview: {
      get () {
        return this.isReview;
      },
      set (isReview) {
        this.$emit('update:isReview', isReview);
      }
    },
    localIsDetailsInfo: {
      get () {
        return this.isDetailsInfo;
      },
      set (isDetailsInfo) {
        this.$emit('update:isDetailsInfo', isDetailsInfo);
      }
    },
    localIsPublished: {
      get () {
        return this.isPublished;
      },
      set (isPublished) {
        this.$emit('update:isPublished', isPublished);
      }
    },
    isLocalReadonly: {
      get () {
        return this.isReadonly
      },
      set (isReadonly) {
        this.$emit('update:isReadonly', isReadonly)
      }
    },
    searchResult: {
      get () {
        return this.search;
      },
      set (search) {
        this.$emit('update:search', search);
      }
    },
    getReviewFormData () {
      return [
        ...this.reviewFormData,
        {
          title: '',
          inputs: [
            { name: "Enter an Email Address", type: "bubble", value: 'email' },
            { name: "Email PDF", type: "button", buttonOptions: { align: 'flex-start' } },
          ]
        }
      ];
    },
    getEditFormData () {
      return [
        ...this.editFormData,
      ];
    },
    filteredData () {
      if (this.searchResult !== '') {
        return this.tableData.filter(item => {
          return Object.values(item).some(fieldValue =>
            String(fieldValue).toLowerCase().includes(this.searchResult.toLowerCase())
          );
        });
      }
      
      return this.tableData
    }
  },
  methods: {
    async handleSelectedAction(data) {
     data.item.audio = await this.QualityAuditStore.getAuditAudio(data.item.call_recording);
     
      if (data.action === 'edit') {
        this.handleEditRow(data.item);
      } else if (data.action === 'review') {
        this.localIsDetailsInfo = true        
        this.submissionData = data.item;
        this.localIsPublished = false;
        this.handleReviewFormDataUpdate(data.item);
        this.localDrawerReview = true;
      } else if (data.action === 'publish') {
        this.submissionData = {
          ...data.item,
          audit_status: "Published"
        };
        this.localDrawer = true;
      }
    },
    handleSelectedItem(selectedItem) {
      //console.log("selectedItem", selectedItem);
      if (selectedItem) {
        this.localIsDetailsInfo = true
        this.isSelectedRow = true;
        if (selectedItem.audit_status !== PUBLISHED) {
          this.localIsPublished = true;
          this.submissionData = {
            ...selectedItem,
            audit_status: PUBLISHED
          };
        } else {
          this.submissionData = selectedItem;
          this.localIsPublished = false;
        }
        this.handleReviewFormDataUpdate(this.submissionData);
      } else {
        this.isSelectedRow = false;
        //this.submissionData = {};
        this.localIsDetailsInfo = false;
        this.reviewFormData = this.reviewFormDataDefault;
        this.resetReviewFormData();
      }
    },
    handleEditRow(submissionData) {
      //console.log("EDIT ROW", submissionData);
      this.isEditForm = true;

      const complianceData = submissionData.auditFieldsObject?.Compliance || {};
      this.submissionData = {
        ...submissionData,
        'Call Type': complianceData.callType,
        'Verification Level': complianceData.verificationLevel,
      };

      this.localDrawerEdit = true;
      this.handleReviewFormDataUpdate(this.submissionData);
    },
    handleCloseClick() {
      this.tableKey++;
      this.localDrawer = false;
      this.localIsReview = false;
      this.localDrawerReview = false;
      this.localDrawerEdit = false;
      this.resetReviewFormData();
      this.resetEditFormData();
    },
    editButtonDisabled() {
      const table = document.querySelector('.vue3-easy-data-table__body');
      table.querySelectorAll('tr td').forEach(item => {
        if (item.textContent === PUBLISHED) {
          item.parentNode.classList.add('no-editable');
        }
      });
    },
    async handlePublishForm(submissionData) {
      //console.log("publish audit submissionData", submissionData);

      if (submissionData !== ERROR) {
        let { id, audit_status } = submissionData;

        if (audit_status && typeof audit_status === 'object' && audit_status.value) {
          audit_status = audit_status.value;
        }

        let result = await this.QualityAuditStore.publishAudit(id, audit_status);

        if (result.error) {
          addAlert(ERROR, result.message, this.alerts);
        } else {
          addAlert(SUCCESS, AUDITS_PUBLISHED_ALERT, this.alerts);
          this.resetAndNavigate();
          await this.QualityAuditStore.fetchQualityAudits();

          this.localDrawer = false;
          if (!this.isSelectedRow) {
            this.submissionData = {};
          }
          this.localIsPublished = false;
        }
      } else {
        addAlert(ERROR, MISSING_FIELDS, this.alerts);
      }
    },
    handleSubmit(submissionData) {
      if (this.isUpdateForm) {
        this.handleUpdateForm(submissionData);
      } else if (this.publishFormData) {
        this.handlePublishForm(submissionData);
      }

      this.isUpdateForm = false;
      //this.resetAndNavigate();
    },
    async handleEditSubmit(submissionData) {
      // console.log("edit form submissionData")
      // console.log(submissionData);

      if (submissionData !== ERROR) {
        try {
          let result = await this.QualityAuditStore.editAudit(submissionData);

          if (result.error) {
            addAlert(ERROR, result.message, this.alerts);
          } else {
            addAlert(SUCCESS, "Audit Updated", this.alerts);
            this.resetAndNavigate();
            await this.QualityAuditStore.fetchQualityAudits();
          }
        } catch (error) {
          console.error("Edit submission failed:", error);
          addAlert(ERROR, "An error occurred while updating the audit.", this.alerts);
        }
      } else {
        addAlert(ERROR, MISSING_FIELDS, this.alerts);
      }

      this.localDrawerEdit = false;
      this.resetReviewFormData();
      this.resetEditFormData();
      this.submissionData = {};
    },

    handleReviewSubmit() {
      this.pdfVisible = true;
      html2pdf(
        document.getElementById('pdf-file'),
        {
          pagebreak: {
            mode: ['avoid-all']
          },
          margin: 2,
          html2canvas: { scale: 2 },
          filename: `quality_audit_${this.submissionData.employee}.pdf`,
        }
      ).then(() => {
        this.pdfVisible = false;
        this.localDrawerReview = false;
      });
    },
    async handlePDF() {
      try {
        this.pdfVisible = true;

        let opt = {
          pagebreak: {
            mode: ['avoid-all']
          },
          margin: 2,
          html2canvas: { scale: 2 },
          filename: `quality_audit_${this.submissionData.employee}.pdf`,
        };

        let pdf;

        await html2pdf().set(opt).from(document.getElementById('pdf-file')).output('blob').then((data) => {
          this.pdfVisible = false;
          this.localDrawerReview = false;
          pdf = data;
        });

        return pdf;
      } catch (error) {
        console.error("PDF generation failed:", error);
        return null;
      }
    },
    async sendPdfToEmail(submissionData) {
      try {
        addAlert(NOTIFICATION, 'Processing...', this.alerts);
        const pdfBlob = await this.handlePDF();
        if (!pdfBlob) {
          console.log("PDF Blob generation failed.");
          return;
        }

        const reader = new FileReader();
        reader.onload = function () {
          console.log(reader.result);
        }
        reader.readAsText(pdfBlob);
        const filename = `quality_audit_${submissionData.employee.replace(" ", "_")}.pdf`;

        try {
          const S3 = useS3Store();
          const authStore = useAuthStore();

          const uploadResult = await S3.putObject({
            Bucket: authStore.$state.s3_bucket,
            Key: `public/quality_audit/temp/${filename}`,
            ContentType: 'application/pdf',
            Body: pdfBlob
          });

          console.log(uploadResult);
        } catch (uploadError) {
          addAlert(ERROR, "PDF upload failed: " + uploadError.message, this.alerts);
          return;
        }

        if (!submissionData.attachments) {
          submissionData.attachments = [];
        }

        submissionData.attachments.push({
          bucket: awsconfig.aws_user_files_s3_bucket,
          path: `public/quality_audit/temp/${filename}`,
          filename: filename,
        });

        const result = await this.QualityAuditStore.sendMail(submissionData);

        if (result.error) {
          addAlert(ERROR, "Unable to send email", this.alerts);
        } else {
          addAlert(SUCCESS, 'Email sent successfully', this.alerts);
        }
      } catch (error) {
        console.log("An error occurred:", error);
      }
    },
    async handleClickRow(submissionData) {
      submissionData.audio = await this.QualityAuditStore.getAuditAudio(submissionData.call_recording);
      this.submissionData = submissionData;
      this.handleReviewFormDataUpdate(submissionData);
      this.localDrawerReview = true;
    },
    resetReviewFormData() {
      this.reviewFormData = JSON.parse(JSON.stringify(this.reviewFormDataDefault));
    },
    resetEditFormData() {
      this.editFormData = JSON.parse(JSON.stringify(this.editFormDataDefault));
    },
    async handleReviewFormDataUpdate(submissionData) {
      for (const [category, fields] of Object.entries(submissionData.auditFieldsObject)) {
        for (const [field, value] of Object.entries(fields)) {
          this.updateFormField(category, field, value, this.reviewFormData);
          this.updateFormField(category, field, value, this.editFormData);
        }
      }

      if (submissionData.auditFieldsObject.Compliance) {
        this.updateFormField("Compliance", "Verification Level", submissionData.auditFieldsObject.Compliance.verificationLevel, this.reviewFormData);
        this.updateFormField("Compliance", "Call Type", submissionData.auditFieldsObject.Compliance.callType, this.reviewFormData);
      }
    },
    updateFormField(category, field, value, formData) {
      const section = formData.find(s => s.title === category);

      if (section && section.inputs) {
        const input = section.inputs.find(i => i.name === field);

        if (input) {
          if (input.type === 'checkbox') {
            input.isChecked = value;
            this.submissionData[field] = value;
          } else if (input.type === 'bubble') {
            input.value = value;
            this.submissionData[field] = value;
          } else if (input.type === 'select') {
            input.value = value; 
            this.submissionData[field] = value;
          } else if (input.type === 'dropdown') {
            input.value = value; 
            this.submissionData[field] = value;
          }
        }
      }
    },
    async reloadAudits() {
      let result = await this.QualityAuditStore.fetchQualityAudits();

      if (result && !result.error) {
        this.tableData = result.map(audit => {
          let options = [{ label: "Review", value: "review" }];

          if (audit.audit_status !== 'Published') {
            options.push({ label: "Edit", value: "edit" });
            options.push({ label: "Publish", value: "publish" });
          }

          return {
            id: audit.id,
            audit_date: audit.audit_date,
            employee: audit.employee,
            ticket_number: audit.ticket_number,
            date_call: audit.date_call,
            auditor: audit.auditor,
            audit_status: audit.audit_status,
            score: audit.score,
            audio: audit.audio,
            call_recording: audit.call_recording,
            auditFieldsObject: audit.parsedAuditObject,
            mutatedObject: audit.parsedMutatedObject,
            options: options
          };
        }).sort((a, b) => new Date(b.audit_date) - new Date(a.audit_date));
      } else {
        console.error("Failed to fetch quality audits: ", result);
      }
    },
    async loadQualityAudit(qualityAuditId) {
      //console.log("LOAD QUALITY AUDIT", qualityAuditId);

      try {
        const result = await this.QualityAuditStore.fetchQualityAuditsById(qualityAuditId);

        if (result && !result.error) {
          this.tableData = this.QualityAuditStore.getAudits.map(audit => {
            let options = [{ label: "Review", value: "review" }];

            if (audit.audit_status !== 'Published') {
              options.push({ label: "Edit", value: "edit" });
              options.push({ label: "Publish", value: "publish" });
            }

            return {
              id: audit.id,
              audit_date: audit.audit_date,
              employee: audit.employee,
              ticket_number: audit.ticket_number,
              date_call: audit.date_call,
              auditor: audit.auditor,
              audit_status: audit.audit_status,
              score: audit.score,
              audio: audit.audio,
              call_recording: audit.call_recording,
              auditFieldsObject: audit.parsedAuditObject,
              options: options
            };
          });
        } else {
          console.error("Failed to fetch quality audits or no data: ", result);
        }
      } catch (error) {
        console.error("Error in fetchQualityAuditsById: ", error);
      }
    },

    resetAndNavigate() {
      this.$emit('resetQualityAuditId');
      this.$router.push({ name: 'Contact Center Management' });
    },


  },
  async mounted() {
    this.editFormDataDefault = JSON.parse(JSON.stringify(this.editFormData));
    this.reviewFormDataDefault = JSON.parse(JSON.stringify(this.reviewFormData));

    if (this.qualityAuditId) {
      this.loadQualityAudit(this.qualityAuditId);
    } else {
      let result = await this.QualityAuditStore.fetchQualityAudits(); 

      if (result && !result.error) {
        this.tableData = this.QualityAuditStore.getAudits.map(audit => {
          let options = [{ label: "Review", value: "review" }];

          if (audit.audit_status !== "Published") {
            options.push(
              { label: "Edit", value: "edit" },
              { label: "Publish", value: "publish" }
            );
          }

          return {
            id: audit.id,
            audit_date: audit.audit_date,
            employee: audit.employee,
            ticket_number: audit.ticket_number,
            date_call: audit.date_call,
            auditor: audit.auditor,
            audit_status: audit.audit_status,
            score: audit.score,
            audio: audit.audio,
            call_recording: audit.call_recording,
            auditFieldsObject: audit.parsedAuditObject,
            mutatedObject: audit.parsedMutatedObject,
            options: options 
          };
        }).sort((a, b) => new Date(b.audit_date) - new Date(a.audit_date));
      } else {
        console.error("Failed to fetch quality audits: ", result);
      }

      this.editButtonDisabled();
    }
  },
  watch: {
    'QualityAuditStore.getAudits': {
      deep: true,
      handler(newAudits) {
        this.tableData = newAudits.map(audit => {
          let options = [{ label: "Review", value: "review" }];

          if (audit.audit_status !== 'Published') {
            options.push({ label: "Edit", value: "edit" });
            options.push({ label: "Publish", value: "publish" });
          }

          return {
            id: audit.id,
            audit_date: audit.audit_date,
            employee: audit.employee,
            ticket_number: audit.ticket_number,
            date_call: audit.date_call,
            auditor: audit.auditor,
            audit_status: audit.audit_status,
            score: audit.score,
            audio: audit.audio,
            call_recording: audit.call_recording,
            auditFieldsObject: audit.parsedAuditObject,
            mutatedObject: audit.parsedMutatedObject,
            options: options
          };
        }).sort((a, b) => new Date(b.audit_date) - new Date(a.audit_date));
      },
    },
    // isAnyDrawerOpen(newValue) {
    //   this.$emit('drawerStateChanged', newValue);
    // },
    clearReviewForm(newValue) {
      if (newValue == true) {
        this.resetReviewFormData();
        this.$emit("cleared-review-form");
      }
    }
  },
  updated() {
    this.editButtonDisabled();
  },
  unmounted () {
    this.localIsReview = false;
    this.localDrawerReview = false;
    this.resetAndNavigate();
  },
  emits: [
    "quality-audit",
    "update:search",
    "update:drawer",
    "update:drawerReview",
    "update:isReview",
    "update:isDetailsInfo",
    "update:isPublished",
    "update:isReadonly",
    "resetQualityAuditId",
    "cleared-review-form",
    "drawerStateChanged"
  ]
}
</script>

<style scoped>
/* .overlay {
  position: fixed;
  top: 140px;
  left: 300px;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 5;
} */
.pageContent {
  flex-grow: 1;
}
.mainContainer {
  display: flex;
  background-color: #e2f1f4;
  height: 100%;
}
.mainContent {
  display: flex;
  flex-grow: 1;
  position: relative;
}
.tableContainer {
  height: 100%;
  padding: 20px;
  background-color: #e2f1f4;
}
.sideDrawer {
  flex: 0 0 auto;
}

:deep(.formContent) {
  min-height: calc(100vh - 164px);
}
.readonly :deep(.dateInputSingle),
.readonly :deep(.dropdownContainer),
.readonly :deep(.textareaNormal) {
  user-select: none;
  pointer-events: none;
}

:deep(.no-editable .item-edit-button) {
  visibility: hidden;
  user-select: none;
  pointer-events: none;
}
:deep(.textareaNormal) {
  padding-top: 5px;
  resize: none;
  max-height: 28px;
}
:deep(.formButtonContainer) {
  padding-bottom: 5px;
}

:deep(.darkButton.fitToText) {
  background-color: #3e87d3;
}

:deep(table tr td:last-child) {
  padding-right: 0px;
  padding-left: 45px;
}
</style>