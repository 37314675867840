<template>
    <v-sheet v-if="actionControlBarActive" border
        style="padding: 10px; margin: 0; position: relative; top: 0; z-index: 1; background: #f4fafa;">
        <div style="float:left">
            <div v-if="searchableColumns" style="display: inline-block">
                <span style="color: #271d7a; font-size: 11pt;">Search: </span>
                <TextInput style="height: 25px;" v-model="tableSearchValue" />
            </div>

            <slot name="leftSideActions"></slot>
        </div>

        <div class="controlDivider"></div>

        <div style="float:right">
            <slot name="rightSideActions"></slot>
        </div>
    </v-sheet>
    <v-sheet style="overflow: auto; max-height: 100%;">
        <!-- Table -->
        <EasyDataTable v-if="!isLoading" table-class-name="customize-table" ref="dataTable"
            :headers="PROPS.table_headers" :items="PROPS.table_data" :search-field="searchableColumnValues"
            :search-value="tableSearchValue" :rows-per-page="rows_per_page" :hide-footer="true"
            :border-cell="border_cell" :sort-by="sortBy" :sort-type="sortType" :multi-sort="multi_sort"
            @click-row="handleRowClick">

            <template v-for="(_, slotName) in $slots" v-slot:[slotName]="slotProps">
                <slot :name="slotName" v-bind="slotProps ?? {}" />
            </template>
        </EasyDataTable>

        <!-- Table Loading Bar -->
        <v-progress-linear v-else color="#271d7a" indeterminate></v-progress-linear>
    </v-sheet>
    <!-- Table Footer -->
    <div v-if="!isLoading" class="customize-footer">
        <div class="customize-index">
            Showing: {{ currentPageFirstIndex }} to {{ currentPageLastIndex }} of {{ clientItemsLength }} entries
        </div>
        <div class="customize-pagination">
            <button @click="prevPage" :disabled="isFirstPage">
                <img class="tablePagButton" v-bind:src="require(`@/assets/icons/Table - back.png`)" />
            </button>
            <button @click="nextPage" :disabled="isLastPage">
                <img class="tablePagButton" v-bind:src="require(`@/assets/icons/Table - next.png`)" />
            </button>
        </div>
        <div class="customize-buttons">
            <section class="menu">
                <ul>
                    <template v-for="paginationNumber in maxPaginationNumber" v-bind:key="paginationNumber">
                        <li :class="{ current: currentPaginationNumber === paginationNumber, hide: !shouldShowPageInMenu(paginationNumber) }" @click="updatePage(paginationNumber)">
                            <span>{{ paginationNumber }}</span>
                        </li>
                    </template>
                </ul>
            </section>
        </div>
    </div>
</template>



<script setup>
import { defineProps, ref, defineEmits, defineExpose, computed } from 'vue';
import TextInput from "@/components/base_components/FormComponents/FillInBubble.vue";

//Constants
const tableSearchValue = ref("");
const isLoading = ref(false);
const dataTable = ref();



//Computed
const currentPageFirstIndex = computed(() => dataTable.value?.currentPageFirstIndex);
const currentPageLastIndex = computed(() => dataTable.value?.currentPageLastIndex);
const clientItemsLength = computed(() => dataTable.value?.clientItemsLength);
const isFirstPage = computed(() => dataTable.value?.isFirstPage);
const isLastPage = computed(() => dataTable.value?.isLastPage);

// pagination related
const maxPaginationNumber = computed(() => dataTable.value?.maxPaginationNumber);
const currentPaginationNumber = computed(() => dataTable.value?.currentPaginationNumber);




//Emits
const emit = defineEmits(["row-clicked"]);

//Exposes
defineExpose({
    toggleTableLoadingSpinner
});



//Props
const PROPS = defineProps({
    table_headers: Array,
    table_data: Array,
    searchableColumnValues: { //Controls what values the column search can access. By default null is every column.
        type: Array,
        default: null
    },
    searchableColumns: { //Controls if the column search is visible or not.
        type: Boolean,
        default: false
    },
    border_cell: { //Controls vertical divider to each column/
        type: Boolean,
        default: false
    },
    rows_per_page: { //Controls Amount of Rows the Table loads with.
        type: Number,
        default: 100
    },
    actionControlBarActive: { //Turns on the bar above the table that allows you to slot actions
        type: Boolean,
        default: false
    },
    row_click_mode: {
        type: String,
        default: "single"
    },
    sortBy: {
        default: ""
    },
    sortType: {
        default: ""
    },
    multi_sort: {
        type: Boolean,
        default: false
    }
});



//Components Functions
function handleRowClick(data, event) {
    if (PROPS.row_click_mode === "single") {
        emit("row-clicked", data);
    }
    else if (PROPS.row_click_mode === "double") {
        if (event.detail === 2) {
            emit("row-clicked", data);
        }
    }
}

function nextPage() {
    dataTable.value.nextPage();
}

function prevPage() {
    dataTable.value.prevPage();
}

function shouldShowPageInMenu(page) {
    return (isCurrentPage(page) || isNextOrPreviousPage(page) || isFirstOrLastPage(page))
}

function isCurrentPage(page) {
    if (page === currentPaginationNumber.value) {
        return true
    }
}

function isNextOrPreviousPage(page) {
    const current = currentPaginationNumber.value
    if (page + 1 === current || page - 1 === current) {
        return true
    }
}

function isFirstOrLastPage(page) {
    if (page === 1 || page === maxPaginationNumber.value) {
        return true
    }
}

function updatePage(paginationNumber) {
    dataTable.value.updatePage(paginationNumber);
}




//Exposed Functions
function toggleTableLoadingSpinner() {
    isLoading.value = !isLoading.value;
}
</script>





<style scoped>
.controlDivider {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
}


.customize-table {
    --easy-table-header-font-color: #fbfdfe;
    --easy-table-body-row-font-color: #2e3e91;
    --easy-table-body-row-hover-font-color: #2e3e91;
    --easy-table-header-background-color: #5c95d6;
    --easy-table-body-row-hover-background-color: #eff4f9;
}


.customize-index {
    font-size: 10pt;
    color: #271d7a;
    padding-top: 10px;
    display: inline-block;
    padding-left: 5px;
}

.customize-buttons {
    display: block;
    float: right;
}

section {
    text-align: center;
}

ul {
    list-style: none;
    margin-top: 11px;
}

li {
    display: inline;
    margin: 0 0.4rem;
    color: #271d7a;
    font-size: 12px;
    cursor: pointer;
}

li.current {
    color: #271d7a;
    filter: drop-shadow(2px 2px 2px rgba(39, 29, 122, 0.2));
    background-color: #fff;
    cursor: pointer;
    width: 24px;
    height: 24px;
    line-height: 24px;
    display: inline-block;
}

li.hide {
    display: none;
}

li.hide+ :not(.hide)::before {
    content: '...';
    display: inline-block;
    margin-right: 0.8rem;
    cursor: auto;
}

.customize-button {
    background-color: #fff;
    color: #271d7a;
    width: 25px;
    display: inline-block;
}

.customize-pagination {
    display: block;
    float: right;
    padding-right: 5px;
}

.tablePagButton {
    width: 32px;
    height: 32px;
    filter: drop-shadow(1px 1px 1px rgba(39, 29, 122, 0.2));
    margin-top: 10px;
}
</style>