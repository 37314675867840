<template>
    <div class="formContainer">
      <div class="formContent">
        <p class="formHeader">{{ formData[0].title }}</p>
        <img
          v-if="removeIcon"
          class="formRemoveIcon"
          :src="require('../../../assets/icons/Remove.png')"
          @click="handleClose"
        />
        <div v-for="(section, sectionIndex) in formData" :key="sectionIndex">
          <template v-if="sectionIndex !== 0 && section.show !== false">
            <p class="formSectionTitle">
              {{ section.title }}
            </p>
            <v-form ref="form" @submit.prevent="onSubmit()">
              <div
                class="formInputs"
                :class="{
                  formInputsWithScroll:
                    hasScrollbar && buttonOptions.positionBottom,
                }"
                ref="formInputs"
              >
                <template v-if="section.checkbox">
                  <div class="checkboxWrapper">
                    <CheckBox
                      :item="section.checkbox"
                      @toggle-selected="
                        this.submissionData[section.checkbox.name] =
                          !this.submissionData[section.checkbox.name]
                      "
                    />
                    <label class="formInputLabel pl-2">{{
                      section.checkbox.name
                    }}</label>
                  </div>
                </template>
                <v-row dense>
                  <template v-for="input in section.inputs" :key="input">
                    <v-col
                      :cols="input.fullWidth ? 12 : (input.thirdWidth ? 8 : numCols)"
                      class="formInputCol px-5"
                    >
                      <label
                        class="formInputLabel"
                        v-if="
                          input.type !== 'checkbox' &&
                          input.type != 'dynamicOptions'
                        "
                        >{{ input.name }}
                        <span v-if="input.required" class="required-asterisk"
                          >*</span
                        ></label
                      >
                      <template v-if="input.type === 'select'">
                        <div class="dropdownContainer">
                          <DropDown
                            :name="input.name"
                            :options="input.options"
                            :default="!input.noDefault && getDefaultValue(input)"
                            @input="updateDropDownVal(input, $event)"
                            :value="
                              input?.options?.find(
                                (option) =>
                                  option.value === submissionData?.[input.name]
                              ) ||
                              input?.options?.find(
                                (option) =>
                                  option.value === submissionData?.[input.value]
                              ) ||
                              submissionData[input.name] ||
                              formData?.[1].inputs?.[1]?.options?.find(
                                (option) =>
                                  option.value === submissionData[input.value]
                              ) ||
                              submissionData[input.value] ||
                              formData?.[1].inputs?.[1]?.options?.find(
                                (option) =>
                                  option.value === submissionData[input.value]
                              ) 
                            "
                          />
                        </div>
                      </template>
                      <template v-else-if="input.type === 'multiSelect'">
                        <DropDownMulti
                          :name="input.name"
                          :options="input.options"
                          placeholder="Select Fields"
                          :default="getDefaultValue(input)"
                          @input="updateDropDownVal(input.name, $event)"
                          :value="submissionData[input.name]"
                        />
                      </template>
                      <template v-else-if="input.name === 'Subject'">
                        <v-col class="formInputCol" :style="{ padding: '0px' }">
                          <FillInBubble
                            :placeholder="input.name"
                            :isRequired="input.required"
                            @input="updateInputVal(input, $event)"
                            :value="submissionData[input.name]"
                          />
                          <BulkUpdatePanel class="bulkUpdateText" />
                        </v-col>
                      </template>
                      <template v-else-if="input.type === 'expandableBubble'">
                        <ExpandableFillInBubble
                          :placeholder="input.name"
                          :isRequired="input.required"
                          @input="updateInputVal(input, $event)"
                          :value="
                            submissionData?.[input.value] ||
                            submissionData?.[input.name]
                          "
                        />
                      </template>
                      <template v-else-if="input.type === 'radio'">
                        <RadioComp
                          :input="input"
                          :submissionData="submissionData"
                          @toggle-selected="updateRadioValue(input.name, $event)"
                          :multiSelect="input.multiSelect"
                        />
                      </template>
                      <template v-else-if="input.type === 'bubble'">
                        <FillInBubble
                          :placeholder="input.name"
                          :isRequired="input.required"
                          @change="updateInputVal(input, $event)"
                          :value="
                            submissionData?.[input.value]
                              ? submissionData?.[input.value]
                              : submissionData?.[input.name]
                          "
                        />
                      </template>
                      <template v-else-if="input.type === 'checkbox'">
                        <div
                          class="formInputCheckbox"
                          :class="{
                            checkboxInRow: !(section.inputs.length % 2),
                          }"
                        >
                          <CheckBox
                            :item="input.name"
                            @toggle-selected="
                              this.submissionData[input.name] =
                                !this.submissionData[input.name]
                            "
                          />
                          <label class="formInputLabel pl-2">{{
                            input.name
                          }}</label>
                        </div>
                      </template>
                      <template v-else-if="input.type === 'dateRange'">
                        <DatePicker
                          :allowDateRange="true"
                          :input="input"
                          @date-value="handleDateChange"
                        />
                      </template>
                      <template v-else-if="input.type === 'datePicker'">
                        <DatePicker
                          :allowDateRange="false"
                          :input="input"
                          @date-value="handleDateChange"
                          :value="previousFormValues?.[input.value]"
                        /> </template
                      ><template v-else-if="input.type === 'timePicker'">
                        <TimePicker
                          :allowDateRange="false"
                          :input="input"
                          @date-value="handleDateChange"
                          :value="submissionData?.[input.value]"
                          :existingDate="
                            submissionData['dateEST'] ||
                            previousFormValues?.['dateEST']
                          "
                        />
                      </template>
                      <template v-else-if="input.type === 'dynamicOptions'">
                        <DynamicOptions
                          @dOptionsInput="updateOptionsVal(input.type, $event)"
                          :initialOptions="
                            Object.keys(previousFormValues).length
                              ? [...previousFormValues?.['dynamicOptions']]
                              : submissionData?.['dynamicOptions']
                          "
                        />
                      </template>
                      <template v-else-if="input.type === 'button'">
                        <slot
                          name="button"
                          :sendSubmissionData="sendSubmissionData"
                        >
                          <DarkButton> {{ input.name }}</DarkButton>
                        </slot>
                      </template>
                      <template v-else-if="input.type === 'filePicker'">
                        <slot name="file-picker"></slot>
                      </template>
                      <template v-else-if="input.type === 'smartLink'">
                        <SmartLink />
                      </template>
                      <template v-else-if="input.type === 'textarea'">
                        <slot name="textarea">
                          <textarea
                            :readonly="
                              input.readonly !== undefined ? input.readonly : true
                            "
                            :value="
                              submissionData?.[input.value] ||
                              submissionData?.[input.name] ||
                              input.text
                            "
                            :placeholder="input.placeholder"
                            class="textareaNormal"
                            @change="updateInputVal(input, $event)"
                          ></textarea>
                        </slot>
                      </template>
                      <template v-else-if="input.type === 'textbox'">
                        <slot name="textbox">
                          <TextBox
                            :defaultColor="input.defaultColor"
                            :showColorWheel="input.showColorWheel"
                            @update-value="updateTextboxVal(input, $event)"
                            :value="
                              submissionData?.[input.value] ||
                              submissionData?.[input.name] ||
                              input.text
                            "
                          />
                        </slot>
                      </template>
                    </v-col>
                  </template>
                  <slot> </slot>
                </v-row>
              </div>
              <template v-if="sectionIndex === formData.length - 1">
                <div
                  class="formButtonContainer"
                  :class="{
                    formButtonPositionBottom: buttonOptions.positionBottom,
                    formButtonCentered: buttonOptions.centered,
                  }"
                >
                  <DividerComp
                    class="formButtonDivider"
                    v-if="buttonOptions.divider"
                  />
                  <slot name="submit-button" :clearForm="clearForm">
                    <div
                      class="formDarkButton"
                      :style="{
                        width: this.buttonOptions.width || '100%',
                      }"
                      :class="{
                        inLineWithInputs:
                          buttonOptions.divider !== undefined &&
                          !buttonOptions.divider,
                      }"
                    >
                      <DarkButton
                        :title="formData[0].button"
                        :width="getButtonWidth"
                      />
                    </div>
                  </slot>
                </div>
              </template>
              <template v-else>
                <DividerComp class="formSectionDivider" />
              </template>
            </v-form>
          </template>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import DividerComp from "@/components/base_components/DividerComp.vue";
  import DropDown from "@/components/base_components/FormComponents/DropDown.vue";
  import FillInBubble from "@/components/base_components/FormComponents/FillInBubble.vue";
  import DarkButton from "@/components/base_components/Buttons/DarkButton.vue";
  import CheckBox from "@/components/base_components/FormComponents/CheckBox.vue";
  import DropDownMulti from "@/components/base_components/FormComponents/DropDownMulti.vue";
  import BulkUpdatePanel from "@/components/ContactCenterManagement/AccountNotifications/BulkUpdatePanel.vue";
  import DatePicker from "@/components/base_components/FormComponents/DatePicker.vue";
  import ExpandableFillInBubble from "@/components/base_components/ExpandableFillInBubble.vue";
  import RadioComp from "@/components/base_components/FormComponents/RadioComp.vue";
  import DynamicOptions from "@/components/base_components/DynamicOptions.vue";
  import TimePicker from "@/components/base_components/FormComponents/TimePicker.vue";
  import SmartLink from "@/components/base_components/FormComponents/SmartLink.vue";
  import TextBox from "@/components/ContactCenterManagement/AccountNotifications/TextBox.vue"
  
  export default {
    name: "FormComp",
    props: {
      formData: Array,
      title: String,
      numCols: Number,
      removeIcon: Boolean,
      previousFormValues: Object,
  
      buttonOptions: {
        type: Object,
        default() {
          return {
            positionBottom: true,
            centered: true,
            fillToParent: true,
            width: "100%",
            divider: true,
          };
        },
      },
    },
    watch: {
      windowHeight() {
        this.$nextTick(() => {
          this.dynamicHeight;
        });
      },
  
      previousFormValues: {
        deep: true,
        handler(newValues) {
          this.previousFormValuesTriggered = true;
          this.submissionData = { ...newValues };
        },
      },
  
      submissionData: {
        deep: true,
        handler(newData) {
          this.$emit("submissionDataUpdated", newData);
        },
      },
      formData() {
        if (!this.previousFormValuesTriggered) {
          this.submissionData = {};
        }
        this.previousFormValuesTriggered = false;
      },
      
    },
    computed: {
      dynamicHeight() {
        return Number(this.windowHeight) - 93;
      },
      dropdownName(input) {
        return `Select a ${input.name}`;
      },
      getButtonWidth() {
        return this.buttonOptions?.fillToParent ? "parent" : "";
      },
      isFormValid() {
        return this.formData.slice(1, this.formData.length)?.every((section) => {
          return section?.inputs?.every((input) => {
            if (input.required) {
              if (
                !this.submissionData[input.name] &&
                !this.submissionData[input.value]
              ) {
                return false;
              }
              return true;
            } else {
              if (
                input.name === "dynamicOptions" &&
                this.submissionData.dynamicOptions
              ) {
                return this.submissionData.dynamicOptions.every((option) => {
                  return option.value !== "";
                });
              }
              return true;
            }
          });
        });
      },
    },
    data() {
      return {
        submissionData: {},
        hasScrollbar: false,
        windowHeight: null,
        showDatePicker: false,
        selectedStartDate: "",
        selectedEndDate: "",
        previousFormValuesTriggered: false,
      };
    },
    methods: {
      handleDateChange(input, value) {
        if (input.value) {
          this.submissionData[input.value] = value;
        } else {
          this.submissionData[input.name] = value;
        }
      },
      toggleDatePicker() {
        this.showDatePicker = !this.showDatePicker;
      },
      updateSelectedDate(date) {
        this.selectedStartDate = date;
      },
      onSubmit() {
        if (this.isFormValid) {
          this.$emit("submission-data", this.submissionData);
        } else {
          this.$emit("submission-data", "Error");
        }
      },
      getDefaultValue(input) {
        const vowels = ["a", "e", "i", "o", "u"];
  
        const inputName = input.name.toLowerCase();
  
        return vowels.includes(inputName.charAt(0))
          ? `Select an ${inputName}`
          : `Select a ${inputName}`;
      },
      sendSubmissionData() {
        // this is a function independent from the submit form. Used for partially sending values
        // even if some required fields arent set (used in Iconfig main UI for adding a user)
        this.$emit("submission-data-independent", this.submissionData);
      },
      updateDropDownVal(input, event) {
        if (input.value) {
          this.submissionData[input.value] = event;
        } else {
          this.submissionData[input.name] = event;
        }
        let name = input.name;
        this.$emit("dropdown-data", { name, value: event });
  
        if (input.controls) {
          const dependent = input.controls;
          this.submissionData[dependent] = null;
          this.$emit("dependent-dropdown-data");
        }
  
      },
      updateInputVal(input, event) {
        if (event.target.value) {
          if (input.value) {
            this.submissionData[input.value] = event.target.value;
          } else {
            this.submissionData[input.name] = event.target.value;
          }
          this.$emit("input-data", event.target.value);
        } else {
          if (event.target.innerText) {
            if (input.value) {
              this.submissionData[input.value] = event.target.innerText;
            } else {
              this.submissionData[input.name] = event.target.innerText;
            }
          } else {
            delete this.submissionData[input.name];
            delete this.submissionData[input.value];
          }
        }
      },
      updateTextboxVal(input, event) {
        this.submissionData[input.name] = event;
      },
      handleClose() {
        this.$emit("close-click");
      },
      updateRadioValue(name, option) {
        if (Array.isArray(option) && name) {
          this.submissionData[name] = option;
        } else {
          if (name) {
            this.submissionData[name] = option.value;
          }
        }
      },
      updateOptionsVal(type, value) {
        this.submissionData[type] = value;
      },
      clearForm() {
        this.submissionData = {};
      },
      checkScrollbar() {
        const element = this.$refs.formInputs;
        if (this.windowHeight < element[0].scrollHeight + 200) {
          this.hasScrollbar = true;
        } else {
          this.hasScrollbar = false;
        }
      },
      handleResize() {
        this.windowHeight = window.innerHeight;
        this.checkScrollbar();
      },
    },
    mounted() {
      this.submissionData = { ...this.previousFormValues };
      window.addEventListener("resize", this.handleResize);
      this.windowHeight = window.innerHeight;
      this.checkScrollbar();
    },
  
    beforeUnmount() {
      window.removeEventListener("resize", this.handleResize);
    },
  
    updated() {
      this.checkScrollbar();
    },
    components: {
      FillInBubble,
      DividerComp,
      DropDown,
      DarkButton,
      CheckBox,
      DropDownMulti,
      BulkUpdatePanel,
      DatePicker,
      ExpandableFillInBubble,
      RadioComp,
      DynamicOptions,
      TimePicker,
      SmartLink,
      TextBox,
    },
    emits: [
      "submission-data",
      "dropdown-data",
      "input-data",
      "dependent-dropdown-data",
      "submissionDataUpdated",
    ],
  };
  </script>
  
  <style scoped>
  .formButtonDivider {
    margin: 0px 0px 20px 0px;
    width: 100%;
  }
  .checkboxWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 10px;
    padding-left: 16px;
  }
  .formInputCheckbox {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .checkboxInRow {
    padding-top: 27px;
    padding-left: 3px;
  }
  .formButtonContainer {
    width: 100%;
    padding-bottom: 8px;
    bottom: 0;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    z-index: 5;
  }
  .formButtonPositionBottom {
    position: absolute;
    padding-bottom: 20px;
  }
  .formButtonCentered {
    align-items: center;
    text-align: center;
  }
  .formDarkButton {
    padding: 0px 36px 0px 36px;
  }
  .formInputCol {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .formContainer {
    background-color: #ffffff;
    font-family: "Red Hat Display", sans-serif;
    overflow: hidden;
    height: 100%;
    min-height: 600px;
  }

  @media (height < 800px) {
    .formContainer {
      min-height: 600px;
    }
  }
  .formContent {
    padding: 11px 0px 11px 0px;
    overflow: visible;
    height: 100%;
  }
  .formInputs {
    padding: 11px 20px 11px 20px;
  }
  .formInputsWithScroll {
    height: calc(100% - 70px);
    padding-bottom: 70px;
  }
  .formSectionTitle {
    padding-left: 35px;
    font-size: 11pt;
    font-weight: bold;
    color: #3e87d3;
  }
  .formSectionDivider {
    margin: 11px 11px 25px 11px;
  }
  .formInputLabel {
    font-size: 9pt;
    font-weight: 600;
    color: #271d7a;
    padding-bottom: 5px;
  }
  .formHeader {
    padding-top: 5px;
    padding-left: 35px;
    padding-bottom: 10px;
    font-size: 14pt;
    font-weight: bold;
    color: #271d7a;
  }
  .formRemoveIcon {
    position: absolute;
    cursor: pointer;
    right: 0;
    top: 0;
    height: 35px;
    padding-top: 20px;
    padding-right: 20px;
  }
  
  .textareaNormal {
    outline: #2e3e91 solid 0.25pt;
    border-radius: 0px;
    padding-left: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 9pt;
    color: #271d7a;
    font-family: "Red Hat Display", sans-serif;
    background-color: #ffffff;
    height: 150px;
  }
  
  .bulkUpdateText {
    margin-top: 30px;
  }
  .inLineWithInputs {
    padding-left: 35px;
  }
  </style>
  