<template>
  <div class="menubar">
    <TiptapItem v-bind="item" v-for="(item, index) in items" 
      :key="index"
      :editor="editor"
      :showColorWheel="showColorWheel"
    />
  </div>
</template>

<script>
import TiptapItem from "./ToolbarItem.vue";

export default {
  components: {
    TiptapItem,
  },
  name: "tiptapMenubar",
  props: {
    editor: {
      type: Object,
      required: true,
    },
    showColorWheel: Boolean
  },

  data() {
    return {
      items: [
        {
          icon: "bold",
          title: "Bold",
          action: () => this.editor.chain().focus().toggleBold().run(),
          isActive: () => this.editor.isActive("bold"),
        },
        {
          icon: "italic",
          title: "Italic",
          action: () => this.editor.chain().focus().toggleItalic().run(),
          isActive: () => this.editor.isActive("italic"),
        },

        {
          icon: "underline",
          title: "Underline",
          action: () => this.editor.chain().focus().toggleUnderline().run(),
          isActive: () => this.editor.isActive("underline"),
        },
        {
          icon: "font-color",
          title: "Font Color",
          isColorPicker: true,
          editor: this.editor,
          actionType: "color",
          action: () => {}
        },
         {
          icon: "font-color",
          title: "highlight",
          isColorPicker: true,
          editor: this.editor,
          actionType: "highlight",
          action: () => {}
        },
      ],
    };
  },
};
</script>

<style scoped>

</style>
