export function downloadCSV(headers, data, filterOptionsArray, name) {
    const filteredData = applyFilters(data, filterOptionsArray);
    const adjustedData = handleProblemCharacters(filteredData);
    const csvContent =
      "data:text/csv;charset=utf-8," +
      encodeURIComponent(buildCSVContent(headers, adjustedData));
    const link = document.createElement("a");
    link.setAttribute("href", csvContent);
    link.setAttribute("download", `${name}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  
  function applyFilters(data, filterOptionsArray) {
    return data.filter((row) => {
      return filterOptionsArray.every((filter) => {
        const headerValue = filter.field;
        const comparison = filter.comparison;
        const criteria = filter.criteria;
        const rowData = row[headerValue];
  
        switch (comparison) {
          case "=":
            return rowData === criteria;
          case "<":
            return rowData < criteria;
          case ">":
            return rowData > criteria;
          case "in":
            return criteria.includes(rowData);
          default:
            return false;
        }
      });
    });
  }

  function handleProblemCharacters(data) {
    let adjusted_data = [];
    for(let row of data) {
      let adjusted_row = {};
      for(const [key, value] of Object.entries(row)) {
        // eslint throwing no-control-regex error due to control characters in regex.  Turning off rule here since we want to target those characters.
        // eslint-disable-next-line
        adjusted_row[key] = value.replace(/[^\x00-\x7F]|,/g, "");
      }
      adjusted_data.push(adjusted_row);
    }

    return adjusted_data;
  }
  
  function buildCSVContent(headers, data) {
    const csvRows = [];
    const headerRow = headers.map((header) => header.text);
    csvRows.push(headerRow);
  
    data.forEach((row) => {
      const rowData = headers.map((header) => row[header.value]);
      csvRows.push(rowData);
    });
  
    return csvRows.map((row) => row.join(",")).join("\n");
  }
  

  export function downloadMultipleCSV(tables, name) {
    const csvContent =
      "data:text/csv;charset=utf-8," + encodeURIComponent(buildMultipleCSVContent(tables));
    const link = document.createElement("a");
    link.setAttribute("href", csvContent);
    link.setAttribute("download", `${name}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  
  function buildMultipleCSVContent(tables) {
    let csvContent = [];
    tables.forEach((table) => {
      const csvRows = [];
      const headerRow = table.headers.map((header) => header.text);
      csvRows.push([]);
      csvRows.push(headerRow);
      const adjusted_table_data = handleProblemCharacters(table.data);
  
      adjusted_table_data.forEach((row) => {
        const rowData = table.headers.map((header) => {
          // Check if the row value is an array
          if (header.value !== "checkbox" && header.value !== "edithover") {
            if (Array.isArray(row[header.value])) {
              // If it's an array, join its values with a comma
              return `"${row[header.value].join(", ")}"`;
            } else {
              return row[header.value];
            }
          }
        });
        csvRows.push(rowData);
      });
      csvContent.push(csvRows.join("\n"));
      csvContent.push("\r");
    });
    return csvContent;
  }