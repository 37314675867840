<template>
  <SecondaryNavBar
    v-if="selectedNestedOption !== 'Overview'"
    title="Contact Center - Management"
    @button-click="handleButtonClick"
    @search-value="handleSearchValue"
    @filter-changed="onFilterChange"
    :drawerOpen="drawer"
    :selectedPage="selectedPage"
    :leftAlignedButtons="getButtonList.left"
    :rightAlignedButtons="getButtonList.right"
    :agent-name-options="agentFilterOptions['agentName']"
    :status-options="statusOptions"
    :supervisor-options="supervisorOptions"
    leftPadding="20px"
  />
  <SecondaryNavBarOverview
    v-if="selectedNestedOption === 'Overview'"
    title="Contact Center - Management"
    @button-click="handleButtonClick"
    @search-value="handleSearchValue"
    :drawerOpen="drawer"
    :selectedPage="selectedPage"
    :leftAlignedButtons="getButtonList.left"
    :rightAlignedButtons="getButtonList.right"
    leftPadding="20px"
  />
  <div class="sideDrawer">
    <FilterSideDrawer
      v-if="selectedPage !== 'Scheduling'"
      v-model:drawerOpen="drawer"
      :drawerData="getDrawerData"
      @button-click="handleButtonClick"
      @close-click="handleCloseClick"
      @submit-click="handleApplyFilter"
      :numCols="12"
    />
    <ViewFlowSideDrawer
      :drawerOpen="viewFlow"
      :currentFlow="selectedItem"
      buttonType="Close"
      :numCols="12"
      :top-aligned="true"
      @close-click="handleCloseClick"
    />
  </div>
  <div class="bconfigContainer">
    <div class="contentContainer">
      <LeftNavigation
        :items="getItems"
        @selected-page="handleSelectedPage"
        @selected-nested-page="handleSelectedNestedPage"
        :startCategory="selectedPage"
        :nestedStartCategory="selectedNestedOption"
      />
      <div class="pageContent"  :style="{ width: dynamicWidth + 'px'}">
        <FlowsView
          v-if="
            selectedPage === 'LEX Flow Manager' &&
            selectedNestedOption === 'Flows'
          "
          :createFlow="createFlow"
          @selected-item="handleSelectedItem"
          :filterOptionsArray="filterOptionsArray"
          :dynamicHeight="dynamicHeight"
          :searchValue="searchValue"
          @edit-row="handleEditRow"
          @close-click="handleCloseClick"
          @open-drawer="() => (createFlow = true)"
          :markedForRemoval="markedForRemoval"
          :unmarkForRemoval="unmarkForRemoval"
          :publish="publish"
          :undo="undo"
          :publishIndv="publishIndv"
          :undoIndv="undoIndv"
          :copyFlow="copyFlow"
          @publish="handlePublish"
          @undo="handleUndo"
          @publishIndv="handleIndvPublish"
          @undoIndv="handleIndvUndo"
          @createFlowSuccess="handleCreateFlowSuccess"
          @editFlowSuccess="handleEditFlowSuccess"
          @addAlert="handleAddAlert"
          :secondDrawerType="secondDrawerType"
          @action-triggered="handleButtonClick"
        />
        <AgentDashboard
          v-if="
            selectedPage === 'Dashboards' && selectedNestedOption === 'Agent(Deprecate)'
          "
          :headersToDisplay="headersToDisplay"
          :dynamicHeight="dynamicHeight"
          @edit-row="handleEditRow"
          :markedForRemoval="markedForRemoval"
          :unmarkForRemoval="unmarkForRemoval"
          :searchValue="searchValue"
        />
        <AgentDashboardRealTime
          v-if="
            selectedPage === 'Dashboards' && selectedNestedOption === 'Agent'
          "
          :dynamicHeight="dynamicHeight"
          @edit-row="handleEditRow"
          :markedForRemoval="markedForRemoval"
          :unmarkForRemoval="unmarkForRemoval"
          :searchValue="searchValue"
        />
        <QueueDashboard
          v-if="
            selectedPage === 'Dashboards' && selectedNestedOption === 'Queue(Deprecate))'
          "
          :queueClicked="queueClicked"
          :headersToDisplay="headersToDisplay"
          :dynamicHeight="dynamicHeight"
          @selectedQueue="handleSelectedQueue"
          :markedForRemoval="markedForRemoval"
          :unmarkForRemoval="unmarkForRemoval"
          :searchValue="searchValue"
        />
        <QueueDashboardRealTime
          v-if="
            selectedPage === 'Dashboards' && selectedNestedOption === 'Queue'
          "
          :queueClicked="queueClicked"
          :headersToDisplay="headersToDisplay"
          :dynamicHeight="dynamicHeight"
          @selectedQueue="handleSelectedQueue"
          :markedForRemoval="markedForRemoval"
          :unmarkForRemoval="unmarkForRemoval"
          :searchValue="searchValue"
        />
        <QuestionsView
          v-if="
            selectedPage === `LEX Flow Manager` &&
            selectedNestedOption === 'Questions'
          "
          :filterOptionsArray="filterOptionsArray"
          :dynamicHeight="dynamicHeight"
          @edit-row="handleEditRow"
          @selected-item="handleSelectedItem"
          @copy-row="handleCopyRow"
          @remove-question="handleRemoveQuestion"
          :addQuestion="addQuestion"
          :editQuestion="editQuestion"
          :applyAll="applyAll"
          @toggleDrawer="handleToggleDrawer"
          @apply-all="handleApplyAll"
          :searchValue="searchValue"
          :copyQuestion="copyQuestion"
          :removeQuestion="removeQuestion"
        />
        <QualityAudit v-if="selectedPage === 'Quality Audit' && selectedNestedOption === 'New Quality Audit'" />
        <PreviewView
          v-if="
            selectedPage === `LEX Flow Manager` &&
            selectedNestedOption === 'Preview'
          "
          :dynamicHeight="dynamicHeight"
        />
        <AuditOverviewView
          v-if="selectedPage === 'Quality Audit' && selectedNestedOption === 'Audit Overview' && canReviewQualityAudits == true"
          v-model:drawer="isDrawer"
        />
        <QualityAuditsView
          ref="qualityAuditsView"
          v-if="selectedPage === 'Quality Audit' && selectedNestedOption === 'Quality Audits' && canReviewQualityAudits == true"
          v-model:drawer="isDrawer"
          v-model:drawerReview="isDrawerReview"
          v-model:isReview="isReview"
          v-model:search="searchValue"
          v-model:isDetailsInfo="isDetailsInfo"
          v-model:isPublished="isPublished"
          v-model:isReadonly="isReadonly"
          :clearReviewForm="clearReviewForm"
          :qualityAuditId="qualityAuditId"
          @drawerStateChanged="handleDrawerStateChange"
          @resetQualityAuditId="qualityAuditId = null"
          @cleared-review-form="handleReviewClear"
        />

        <CoverageView
          v-if="selectedPage === 'Scheduling' && selectedNestedOption === 'Coverage'"
          v-model:drawer="isDrawer"
          v-model:search="searchValue"
        />
        <EmployeesView
          v-if="selectedPage === 'Scheduling' && selectedNestedOption === 'Employees'"
          v-model:drawer="isDrawer"
          v-model:search="searchValue"
          v-model:create="isCreateForm"
          v-model:update="isUpdateForm"
          v-model:remove="isRemoveForm"
          v-model:isRemove="isRemove"
          v-model:isReadonly="isReadonly"
        />
        <PartnersView
          v-if="selectedPage === 'Scheduling' && selectedNestedOption === 'Partners'"
          v-model:drawer="isDrawer"
          v-model:search="searchValue"
          v-model:create="isCreateForm"
          v-model:update="isUpdateForm"
          v-model:remove="isRemoveForm"
          v-model:isRemove="isRemove"
          v-model:isReadonly="isReadonly"
        />
        <AccountNotificationView
          v-if="
            selectedPage === `Account Notification` &&
            selectedNestedOption === 'Textbox'
          "
          :dynamicHeight="dynamicHeight"
        />
        <ToolsEditorView
          v-if="
            selectedPage === `Account Notification` &&
            selectedNestedOption === 'Tools'
          "
          :dynamicHeight="dynamicHeight"
        />

        <AlertsGroup
          v-model:alerts="alerts"
        />
      </div>
    </div>
  </div>
</template>

<script>
// TODO: FIX THIS FILE IT HAS ESLINT ISSUES
import LeftNavigation from "@/components/base_components/Navigation/LeftNavigation.vue";
import SecondaryNavBar from "@/components/CcManagement/SecondaryNavBar.vue";
import SecondaryNavBarOverview from "@/components/CcManagement/SecondaryNavBarOverview.vue";
import AlertsGroup from "@/components/base_components/AlertsGroup.vue";
import { addAlert } from '@/helpers/alertUtils';
import { SUCCESS } from "@/constants/statuses.constants.js";
import FlowsView from "@/views/ContactCenter/CcManagement/FlowManager/FlowsView.vue";
import QuestionsView from "./FlowManager/QuestionsView.vue";
import PreviewView from "./FlowManager/PreviewView.vue";
import FilterSideDrawer from "@/components/CcManagement/FilterSideDrawerCC.vue";
import AgentDashboard from "@/components/CcManagement/Dashboards/AgentDashboard.vue";
import AgentDashboardRealTime from "@/components/CcManagement/Dashboards/AgentDashboardRealTime.vue";
import QueueDashboard from "@/components/CcManagement/Dashboards/Queue/QueueDashboard.vue";
import QueueDashboardRealTime from "@/components/CcManagement/Dashboards/QueueDashboardRealTime.vue";
import ViewFlowSideDrawer from "@/components/CcManagement/Flows/View/ViewFlowSideDrawer.vue";
import AuditOverviewView from "@/views/ContactCenter/CcManagement/Attendance/AuditOverviewView.vue";
import QualityAuditsView from "@/views/ContactCenter/CcManagement/Attendance/QualityAuditsView.vue";
import QualityAudit from "@/views/ContactCenter/CcManagement/QualityAudit/QualityAudit.vue";
import CoverageView from "@/views/ContactCenter/CcManagement/Scheduling/CoverageView.vue";
import EmployeesView from "@/views/ContactCenter/CcManagement/Scheduling/EmployeesView.vue";
import PartnersView from "@/views/ContactCenter/CcManagement/Scheduling/PartnersView.vue";
import AccountNotificationView from "@/views/ContactCenter/CcManagement/AccountNotification/AccountNotification.vue";
import ToolsEditorView from "@/views/ContactCenter/CcManagement/AccountNotification/ToolsEditorView.vue";


import { mapStores, mapActions } from "pinia";
import { useHelpStore } from "@/stores/Help/helpStore.js";
import { useDashboardStore } from "@/stores/ContactCenter/Management/DashboardStore.js";
import { useQualityAuditStore } from "@/stores/ContactCenter/Management/QualityAuditStore.js";
import { useCCManagementSchedulingStore } from "@/stores/ContactCenter/Management/CCManagementSchedulingStore.js";
import { useGeneralStore } from "@/stores/generalStore.js";

export default {
  name: "CcManagementView",
  data() {
    return {
      createFlow: false,
      viewFlow: false,
      copyQuestion: false,
      removeQuestion: false,
      qubicleQueueData: ["Hello"],
      copyFlow: false,
      headersToDisplay: [],
      queueClicked: false,
      clearReviewForm: false,
      publish: false,
      undo: false,
      undoIndv: false,
      publishIndv: false,
      searchValue: "",
      secondDrawerType: "",
      items: [],

      flowsDrawerData: [
        { title: "Filters", button: "Apply", type: "apply" },
        {
          title: "",
          details: [
            {
              name: "Flow name",
              value: "flowName",
              type: "input",
              placeholder: "Any Flow Name",
            },
            {
              name: "Number of Questions",
              value: "questions",
              type: "input",
              placeholder: "Any # of Questions",
            },
            {
              name: "Partners",
              value: "partners",
              type: "input",
              placeholder: "Any Partner",
            },
          ],
        },
      ],
      agentDrawerData: [
        { title: "Filters", button: "Apply", type: "apply" },
        {
          title: "",
          details: [
            {
              name: "Agent Name",
              value: "agentName",
              type: "select",
              default: "Select an Agent Name",
              options: [],
              clear: true,
            },
            {
              name: "Status",
              value: "status",
              type: "select",
              default: "Select a Status",
              options: [
                { label: "Ready", value: "Ready" },
                { label: "On-A-Call", value: "On-A-Call" },
                { label: "Away", value: "Away" },
                { label: "Call-Offer", value: "Call-Offer" },
                { label: "Wrapup-Time", value: "Wrapup-Time" },
                { label: "On-External-Call", value: "On-External-Call" },
                { label: "Monitoring", value: "Monitoring" }
              ],
              clear: true,
            },
            {
              name: "Supervisor",
              value: "supervisor",
              type: "select",
              default: "Select a Supervisor",
              options: [],
              clear: true,
            },
            // {
            //   name: "Fields",
            //   value: "fields",
            //   type: "multiSelect",
            //   default: "Select Fields",
            //   options: [
            //     {
            //       label: "Calls During Current Shift",
            //       value: "callsCurrentShift",
            //     },
            //     {
            //       label: "Outbound and Answered Calls",
            //       value: "outboundAndAnsweredCalls",
            //     },
            //     {
            //       label: "Missed and Abandoned Calls",
            //       value: "missedAndAbandonedCalls",
            //     },
            //     { label: "Tickets", value: "tickets" },
            //     { label: "Tickets Escalated", value: "ticketsEscalated" },
            //     {
            //       label: "Average Recovery Time",
            //       value: "averageRecoveryTime",
            //     },
            //   ],
            //   clear: true,
            // },
          ],
        },
      ],
      queueDrawerData: [
        { title: "Filters", button: "Apply", type: "apply" },
        {
          title: "",
          details: [
            {
              name: "Queue Name",
              value: "queueName",
              type: "select",
              default: "Select a Queue Name",
              options: [],
              clear: true,
            },

            //   {
            //     name: "Fields",
            //     value: "fields",
            //     type: "multiSelect",
            //     default: "Select Fields",
            //     options: [
            //       {
            //         label: "Calls Waiting & Wait Time",
            //         value: "callsWaitingAndWaitTime",
            //       },
            //       { label: "Agents Ready", value: "agentsReady" },
            //       {
            //         label: "Agents on Phone & Away",
            //         value: "agentsOnPhoneAndAway",
            //       },
            //       { label: "New Tickets", value: "newTickets" },
            //       {
            //         label: "LogicomUSA & Overflow Tickets",
            //         value: "logicomUSAAndOverflowTickets",
            //       },
            //       { label: "Tickets Escalated", value: "ticketsEscalated" },
            //       { label: "Open CSR Escalations", value: "csrEscalations" },
            //       {
            //         label: "Answered Calls",
            //         value: "answeredCalls",
            //       },
            //       {
            //         label: "Inbound and Outbound Calls",
            //         value: "inboundAndOutboundCalls",
            //       },
            //       {
            //         label: "Missed and Abandoned Calls",
            //         value: "missedAndAbandonedCalls",
            //       },
            //       {
            //         label: "Calls Handed to Overflow",
            //         value: "callsHandedToOverflow",
            //       },
            //       {
            //         label: "Calls Waiting in Queue",
            //         value: "callsWaitingInQueue",
            //       },
            //       {
            //         label: "Average Wait Time",
            //         value: "averageWaitTime",
            //       },
            //       {
            //         label: "Longest Hold Time - Current",
            //         value: "longestHoldTimeCurrent",
            //       },
            //       {
            //         label: "Longest Hold Time - Day",
            //         value: "longestHoldTimeDay",
            //       },
            //       {
            //         label: "Callbacks Scheduled",
            //         value: "callbacksScheduled",
            //       },
            //     ],
            //   clear: true,
            // },
          ],
        },
      ],
      isQueueSelected: false,
      selectedPage: "Account Notification",
      selectedNestedOption: "Textbox",
      filterOptionsArray: [],
      drawer: false,
      addQuestion: false,
      editQuestion: false,
      applyAll: false,
      windowHeight: null,
      windowWidth: window.innerWidth,
      alerts: [],
      selectedItem: null,
      markedForRemoval: [],
      unmarkForRemoval: [],
      isDrawer: false,
      isCreateForm: false,
      isUpdateForm: false,
      DashboardSocket: null,
      isRemoveForm: false,
      isRemove: false,
      isAdmin: false,
      isViewButton: false,
      isDetailsInfo: false,
      isPdfDownload: false,
      isPublished: false,
      isReview: false,
      isDrawerReview: false,
      canReviewQualityAudits: false,
      qualityAuditId: null,
      isAnyDrawerOpen: false,
      supervisorOptions: [],
      statusOptions: [
        { label: "None", value: "" },
        { label: "Ready", value: "Ready" },
        { label: "On-A-Call", value: "On-A-Call" },
        { label: "Away", value: "Away" },
        { label: "Call-Offer", value: "Call-Offer" },
        { label: "Wrapup-Time", value: "Wrapup-Time" },
        { label: "On-External-Call", value: "On-External-Call" },
        { label: "Monitoring", value: "Monitoring" }
      ]
    };
  },
  components: {
    LeftNavigation,
    SecondaryNavBar,
    SecondaryNavBarOverview,
    FlowsView,
    AlertsGroup,
    QuestionsView,
    PreviewView,
    FilterSideDrawer,
    AgentDashboard,
    AgentDashboardRealTime,
    QueueDashboard,
    QueueDashboardRealTime,
    ViewFlowSideDrawer,
    AuditOverviewView,
    QualityAuditsView,
    QualityAudit,
    CoverageView,
    EmployeesView,
    PartnersView,
    AccountNotificationView,
    ToolsEditorView

  },

  async mounted() {
    window.addEventListener("resize", this.handleResize);
    this.windowHeight = window.innerHeight;
    // this.DashboardSocket = await this.DashboardStore.initializeSwitchSocket();

    // try {
    //   await Promise.all([
    //     this.DashboardStore.setAgentList(),
    //     this.DashboardStore.setQueueList()
    //   ]);

    //   this.setupWebSocketListeners();
    // } catch (err) {
    //   console.error("Error initializing dashboard data:", err);
    // }

    if (this.$route.params.quality_audit_id) {
      await this.loadQualityAudit(this.$route.params.quality_audit_id);
    }
  },
  async created() {
    this.HelpManagementStore.setPage('Contact Center / Management / Account Notification');
    var results = await this.initializeContactCenterPage();
    this.isAdmin = results.isAdmin;
    var settings = results.settings;

    if (Object.values(settings).includes('canSeeAttendance')) {
      this.canReviewQualityAudits = true;
    }

    let supervisorsResult = await this.DashboardStore.fetchSupervisors();
    this.supervisorOptions = [{ label: "None", value: "" }];

    this.supervisorOptions.push(...supervisorsResult.map(supervisor => {
      return { label: supervisor, value: supervisor };
    }));

    // this.isAdmin = await this.initializePage();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    windowHeight() {
      this.$nextTick(() => {
        this.dynamicHeight;
      });
    },
    '$route'(to, from) {
      //console.log("Route changed from", from, "to", to);

      if (to.params.quality_audit_id) {
        this.loadQualityAudit(to.params.quality_audit_id);
      } else if (from.path.includes('/qualityAudit/') && this.qualityAuditId) {
        this.qualityAuditId = null;
      }
    },
    queueClicked(newVal) {
      this.$nextTick(() => {
        if (newVal) {
          this.queueClicked = false;
        }
      });
    },
    isQueueSelected(isSelected) {
      if (isSelected) {
        this.drawer = false;
      }
    },
    agentFilterOptions(newVal) {
      for (var filter of this.agentDrawerData[1]?.details) {
        var value = filter.value;
        if (Object.keys(newVal).indexOf(value) != -1) {
          var newOptions = newVal[value];
          filter.options = newOptions;
        }
      }
    },
    queueFilterOptions(newVal) {
      for (var filter of this.queueDrawerData[1]?.details) {
        var value = filter.value;
        if (Object.keys(newVal).indexOf(value) != -1) {
          var newOptions = newVal[value];
          filter.options = newOptions;
        }
      }
    },
  },
  computed: {
    ...mapStores(useDashboardStore, useHelpStore, useQualityAuditStore, useCCManagementSchedulingStore),
    dynamicWidth() {

      let width = "";
      // console.log(window.innerWidth)
      // console.log('window', this.windowWidth)
      if (this.windowWidth > 1000) {
          width = Number(this.windowWidth) - 400;
      } else {
          width = Number(this.windowWidth) - 325;
      }
      //console.log('width ', width)
      return width;
    },
    getItems() {
      var items = [
        {
          category: "Account Notification",
          options: [
            { category: "Textbox" },
            { category: "Tools" },
          ]
        },
        {
          category: "Dashboards",
          options: [
            { category: "Agent" },
            { category: "Queue" },
          ],
        },
        {
          category: "LEX Flow Manager",
          description: "",
          options: [
            { category: "Flows" },
            { category: "Preview" },
            { category: "Questions" },
          ],
        },
        {
          category: "Quality Audit",
          description: "",
          options: [
            { category: "New Quality Audit" },
          ],
        },
        {
          category: "Scheduling",
          options: [
            { category: 'Coverage' },
            { category: 'Employees' },
            { category: 'Partners' }
          ]
        },
      ]

      if (this.canReviewQualityAudits) {
        items = [
          {
            category: "Account Notification",
            options: [
              { category: "Textbox" },
              { category: "Tools" },
            ]
          },
          {
            category: "Dashboards",
            options: [
              { category: "Agent" },
              { category: "Queue" },
            ],
          },
          {
            category: "LEX Flow Manager",
            description: "",
            options: [
              { category: "Flows" },
              { category: "Preview" },
              { category: "Questions" },
            ],
          },
          {
            category: "Quality Audit",
            description: "",
            options: [
              { category: "New Quality Audit" },
              { category: "Audit Overview" },
              { category: "Quality Audits" },
            ],
          },
          {
            category: "Scheduling",
            options: [
              { category: 'Coverage' },
              { category: 'Employees' },
              { category: 'Partners' }
            ]
          },
        ]
      }

      return items;
    },
    agentFilterOptions() {
      return this.DashboardStore.agent_filter_options;
    },
    queueFilterOptions() {
      return this.DashboardStore.queue_filter_options;
    },
    dynamicHeight() {
      return Number(this.windowHeight) - 93;
    },
    getTable() {
      if (this.selectedPage === "LEX Flow Manager") {
        return { headers: this.bconfigHeaders, data: this.bconfigData };
      } else if (this.selectedPage === "Categories") {
        return { headers: this.categoriesHeaders, data: this.categoriesData };
      } else if (this.selectedPage === "Commands") {
        return { headers: this.commandsHeaders, data: this.commandsData };
      } else if (this.selectedPage === "Scheduled Tasks") {
        return {
          headers: this.scheduledTasksHeaders,
          data: this.scheduledTasksData,
        };
      } else if (this.selectedPage === "Templates") {
        return {
          headers: this.templateHeaders,
          data: this.templateData,
        };
      }

      return "";
    },
    getDrawerData() {
      if (this.selectedPage === "LEX Flow Manager") {
        return this.flowsDrawerData;
      } else if (this.selectedPage === "Dashboards") {
        if (this.selectedNestedOption === "Agent") {
          //console.log("this.agentDrawerData", this.agentDrawerData);
          return this.agentDrawerData;
        }
        else if (this.selectedNestedOption === "Queue") {
          //console.log("this.queueDrawerData", this.queueDrawerData);
          return this.queueDrawerData;
        }
      }
      return [];
    },
    getButtonList() {
      if (this.selectedNestedOption === "Flows") {
        return {
          right: [
            { title: "Search" },
            { title: "Publish", icon: "Export.png" },
            { title: "Undo", icon: "Export.png" },
            { title: "Create", icon: "Add.png" },
            // {
            //   title: "drawer",
            //   iconOpen: "Open.png",
            //   iconClose: "Collapse.png",
            // }
          ]
        };
      } else if (this.selectedNestedOption === "Questions") {
        return {
          left: [],
          right: [
            { title: "Search" },
            { title: `Add Question`, icon: "Add.png" },
          ],
        };
      }
      else if (this.selectedNestedOption === "Audit Overview") {
        return {
          left: [],
          right: [
            {
              title: "drawer",
              iconOpen: "Open.png",
              iconClose: "Collapse.png",
            },
          ],
        };
      } else if (this.selectedNestedOption === "Quality Audits") {
        let buttonList = [{ title: "Search" }];

        if (this.qualityAuditId) {
          buttonList.push({ title: "Load All Audits", iconOpen: "Open.png", iconClose: "Collapse.png" });
        }

        return {
          left: this.qualityAuditId ? buttonList.filter(button => button.title === "Load All Audits") : [],
          right: buttonList.filter(button => button.title !== "Load All Audits")
        };
      }
      else if (
        this.selectedPage === 'Scheduling'
        && this.selectedNestedOption === 'Coverage') {
        return {
          left: [],
          right: [
            { title: "Search" },
            {
              title: "drawer",
              iconOpen: "Open.png",
              iconClose: "Collapse.png",
            },
          ],
        }
      } else if (
        this.selectedPage === 'Scheduling'
        && this.selectedNestedOption === 'Employees'
      ) {
        if (this.isRemove) {
          return {
            left: [],
            right: [
              { title: 'Search' },
              { title: 'Remove Employee', icon: 'Remove.png' },
              { title: 'Add Employee', icon: 'Add.png' },
            ]
          }
        }
        return {
          left: [],
          right: [
            { title: 'Search' },
            { title: 'Add Employee', icon: 'Add.png' }
          ]
        }
      } else if (
        this.selectedPage === 'Scheduling'
        && this.selectedNestedOption === 'Partners'
      ) {
        if (this.isRemove) {
          return {
            left: [],
            right: [
              { title: 'Search' },
              { title: 'Remove Partner', icon: 'Remove.png' },
              { title: 'Add Partner', icon: 'Add.png' }
            ]
          }
        }
        return {
          left: [],
          right: [
            { title: 'Search' },
            { title: 'Add Partner', icon: 'Add.png' }
          ]
        }
      }
      return {};
    }
  },
  methods: {
    ...mapActions(useDashboardStore, ["filterAgentData", "filterQueueData", 'setFilter', 'initializeSwitchSocket', 'handleAgentReadyEvent', 'handleAgentAwayEvent', 'handleAgentWrapupCompleteEvent', 'handleAgentDeliveredEvent', 'handleAgentWrapupStartEvent', 'handleAgentExternalCallEndEvent', 'handleAgentCallOfferedEvent', 'handleAgentExternalCallStartEvent']),
    ...mapActions(useHelpStore, ['setPage']),
    ...mapActions(useGeneralStore, ['initializeContactCenterPage']),
    // ...mapActions(useQubicleRealtimeStore, ["getAgents", "getQueues"]),
    async loadQualityAudit(qualityAuditId) {
      if (qualityAuditId) {
        this.qualityAuditId = qualityAuditId;

        this.selectedPage = "Quality Audit";
        this.selectedNestedOption = "Quality Audits";
      }
    },
    handleDrawerStateChange(isOpen) {
      this.isAnyDrawerOpen = isOpen;
    },
    setupWebSocketListeners() {
      try {
        this.DashboardSocket.onmessage = async function (e) {
          var event = JSON.parse(e.data);
          //console.log("front event", event);
          if (event.subscribed_key == "qubicle.queue") {
            //TODO: Possibly Handle Other Events for qubicle.queue
            if (event.name == "sync") {
              await this.DashboardStore.handleQueueEvent(event);
            }
            else if (event.name == "leave") {
              //Call Left the queue???
            }
            else if (event.name == "join") {
              //Call Joined the queue???
              await this.DashboardStore.handleQueueEvent(event);
            }
            else if (event.name == "delivered") {
              await this.DashboardStore.handleQueueEvent(event);
            }
            else {
              console.log(event);
            }
          }
          else if (event.subscribed_key == "qubicle.recipient") {
            if (event.name == "ready") {
              await this.DashboardStore.handleAgentEvent(event);
            }
            else if (event.name == "away") {
              await this.DashboardStore.handleAgentEvent(event);
            }
            else if (event.name == "wrapup_complete") {
              await this.DashboardStore.handleAgentEvent(event);
            }
            else if (event.name == "wrapup_start") {
              await this.DashboardStore.handleAgentEvent(event);
            }
            else if (event.name == "delivered") {
              await this.DashboardStore.handleAgentEvent(event);
            }
            else if (event.name == "external_call_start") {
              await this.DashboardStore.handleAgentEvent(event);
            }
            else if (event.name == "external_call_end") {
              await this.DashboardStore.handleAgentEvent(event);
            }
            else if (event.name == "offer") {
              await this.DashboardStore.handleAgentEvent(event);
            }
          }
        }.bind(this);
      }
      catch (err) {
        console.log(err);
      }
    },
    handleAddAlert(name, description) {
      console.log(name);
      addAlert(SUCCESS, description, this.alerts);
    },
    handleReviewClear() {
      this.clearReviewForm = false;
    },
    handlePublish(tableData) {
      this.publish = !this.publish;
      console.log("Publish in view", tableData);
    },
    handleUndo(tableData) {
      this.undo = !this.undo;
      console.log("Undo in view", tableData);
    },
    handleIndvUndo() {
      this.undoIndv = !this.undoIndv;
    },
    handleIndvPublish() {
      this.publishIndv = !this.publishIndv;
    },
    handleCreateFlowSuccess() {
      this.secondDrawerType = "Flow";
      this.createFlow = false;
      this.copyFlow = false;
    },
    handleEditFlowSuccess() {
      this.secondDrawerType = "Edit";
      this.createFlow = false;
      this.copyFlow = false;
    },
    handleResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    },
    handleSelectedItem(selectedItem) {
      this.selectedItem = selectedItem;
    },
    handleEditRow(item) {
      // item will be handled when component is finished
      console.log(item)
      this.editQuestion = true;
    },
    handleCopyRow() {
      if (this.selectedItem) {
        this.copyQuestion = true;
      }
    },
    handleRemoveQuestion() {
      if (this.selectedItem) {
        this.removeQuestion = true;
      }
    },
    handleApplyAll() {
      this.applyAll = true;
    },
    handleToggleDrawer(name, value) {
      if (name === "all") {
        this.drawer = false;
        this.addQuestion = false;
        this.editQuestion = false;
        this.copyQuestion = false;
        this.removeQuestion = false;
        this.copyFlow = false;
        this.applyAll = false;
      } else if (name === "addQuestion") {
        this.addQuestion = value;
      } else if (name === "editQuestion") {
        this.editQuestion = value;
      } else if (name === "apply") {
        this.applyAll = value;
      }
    },

    handleSelectedPage(category) {
      this.drawer = false;
      this.addQuestion = false;
      this.editQuestion = false;
      this.searchValue = "";
      this.selectedPage = category;
      this.selectedItem = [];
      if (this.selectedPage === "LEX Flow Manager") {
        this.selectedNestedOption = "Flows";
      } else if (this.selectedPage === "Dashboards") {
        this.selectedNestedOption = "Agent";
      } else if (this.selectedPage === "Quality Audit") {
        this.selectedNestedOption = "New Quality Audit";
      } else if (this.selectedPage === "Scheduling") {
        this.selectedNestedOption = "Coverage";
      } else if (this.selectedPage === "Account Notification") {
        this.selectedNestedOption = "Textbox";
      } else {
        this.selectedNestedOption = "";
      }
              this.HelpManagementStore.setPage(`Contact Center / Management / ${this.selectedPage}`);
          },
    handleSelectedNestedPage(category) {
      this.drawer = false;
      this.addQuestion = false;
      this.editQuestion = false;
      this.searchValue = "";
      this.selectedNestedOption = category;
      if (this.selectedNestedOption === "Queue") {
        this.queueClicked = true;
      } else {
        this.isQueueSelected = false;
      }
    },
    handleCloseClick() {
      this.drawer = false;
      this.addQuestion = false;
      this.editQuestion = false;
      this.createFlow = false;
      this.copyFlow = false;
      this.viewFlow = false;
      this.publish = false;
      this.undo = false;
      this.publishIndv = false;
      this.undoIndv = false;
    },
    handleButtonClick(title) {
      if (
        title === "drawer" &&
        !(this.selectedNestedOption === "Audit Overview")
      ) {
        this.drawer = !this.drawer;
      } else if (title === "Add Question") {
        this.addQuestion = !this.addQuestion;
        if (this.drawer) {
          this.drawer = false;
        }
      } else if (title === "Back") {
        this.reviewDrawerData = [];
      } else if (title === "Remove") {
        this.markedForRemoval = this.selectedItem;
      } else if (title === "Undo Remove") {
        this.unmarkForRemoval = this.selectedItem;
      } else if (title === "Create") {
        this.createFlow = !this.createFlow;
      } else if (title === "View" && this.selectedNestedOption !== "Overview") {
        if (this.selectedItem) {
          this.viewFlow = !this.viewFlow;
        }
      } else if (title === "Copy" && this.selectedNestedOption == "Flows") {
        if (this.selectedItem) {
          this.copyFlow = !this.copyFlow;
        }
      } else if (title === "Publish") {
        //TODO Open Publish side drawer
        this.publish = !this.publish;
      } else if (title === "Undo") {
        //TODO Open Undo side drawer
        this.undo = !this.undo;
      } else if (title == "Publish Indv") {
        this.publishIndv = !this.publishIndv;
      } else if (title == "Undo Indv") {
        this.undoIndv = !this.undoIndv;
      }

      if (this.selectedPage === 'Quality Audit'
        && this.selectedNestedOption === 'Audit Overview') {
        if (title === 'drawer') {
          this.isDrawer = !this.isDrawer;
        }
      }

      if (
        this.selectedPage === 'Quality Audit'
        && this.selectedNestedOption === "Quality Audits"
      ) {
        if (title === 'Review') {
          this.isReview = !this.isReview;
          this.isDrawerReview = !this.isDrawerReview;

          if (this.isReview == false) {
            this.clearReviewForm = true;
          }
        }

        if (title === 'Publish') {
          this.isDrawer = !this.isDrawer;
          this.isReadonly = true;
        }

        if (title === 'Load All Audits') {
          this.qualityAuditId = null;
          if (this.$refs.qualityAuditsView) {
            this.$refs.qualityAuditsView.resetAndNavigate();
            this.$refs.qualityAuditsView.reloadAudits();
          }
        }
      }

      if (
        this.selectedPage === 'Scheduling'
        && this.selectedNestedOption === "Coverage"
      ) {
        if (title === 'drawer') {
          this.isDrawer = !this.isDrawer;
        }
      }

      if (
        this.selectedPage === 'Scheduling'
        && this.selectedNestedOption === "Employees"
      ) {
        if (title === "Add Employee") {
          this.isDrawer = !this.isDrawer;
          this.isCreateForm = !this.isCreateForm;
          this.isRemoveForm = true;
          this.isUpdateForm = false;
        }

        if (title === "Remove Employee") {
          this.isDrawer = !this.isDrawer;
          this.isRemoveForm = true;
          this.isCreateForm = false;
          this.isUpdateForm = false;
          this.isReadonly = true;
        }
      }

      if (
        this.selectedPage === 'Scheduling'
        && this.selectedNestedOption === "Partners"
      ) {
        if (title === "Add Partner") {
          this.isDrawer = !this.isDrawer;
          this.isCreateForm = !this.isCreateForm;
          this.isRemoveForm = true;
          this.isUpdateForm = false;
        }

        if (title === "Remove Partner") {
          this.isDrawer = !this.isDrawer;
          this.isRemoveForm = true;
          this.isCreateForm = false;
          this.isUpdateForm = false;
          this.isReadonly = true;
        }
      }
    },
    handleSearchValue(searchValue) {
      //console.log(searchValue);
      this.searchValue = searchValue;
    },

    onFilterChange({ filterType, selectedOption }) {

      let filters = {};

      if (filterType === 'Status') {
        filters.status = selectedOption.value;
      } else if (filterType === 'Agent Name') {
        filters.agentName = selectedOption.value;
      } else if (filterType === 'Supervisor') {
        filters.supervisor = selectedOption.value;
      }

      this.filterAgentData(filters);
    },
    handleApplyFilter(filterList) {
      //console.log("filterList", filterList);
      var listOfMiddleWareFilters = {};
      for (let key in filterList) {
        const value = filterList[key];
        if (key === "Fields") {
          this.headersToDisplay = value;
        } else {
          if (this.selectedNestedOption == "Agent") {
            if (key == "status") {
              listOfMiddleWareFilters["status"] = value;
            } else if (key == "agentName") {
              listOfMiddleWareFilters["agentName"] = value;
            } else if (key == "supervisor") {
              listOfMiddleWareFilters["supervisor"] = value;
            }
          } else if (this.selectedNestedOption == "Queue") {
            if (key == "queueName") {
              listOfMiddleWareFilters["queueName"] = value;
            }
          }
        }
      }

      if (this.selectedNestedOption == "Agent") {
        //console.log("listOfMiddleWareFilters", listOfMiddleWareFilters);
        this.filterAgentData(listOfMiddleWareFilters);
      } else if (this.selectedNestedOption == "Queue") {
        this.DashboardStore.setFilter(listOfMiddleWareFilters.queueName);
        this.DashboardStore.filterQueueData(listOfMiddleWareFilters);
        this.DashboardStore.getQueueData;
      }
      this.drawer = false;
    },
    handleSelectedQueue(isSelected) {
      this.isQueueSelected = isSelected;
    },
  },
};
</script>
<style scoped>
html {
  overflow-y: auto !important;
}

.bconfigContainer {
  display: flex;
  min-height: calc(100vh - 93px);
}

.pageContent {
  flex-grow: 1;
  background-color: #e2f1f4;
}

.contentContainer {
  display: flex;
  flex-grow: 1;
  position: relative;
}

.tableContainer {
  /* padding: 20px; */
  background-color: #e2f1f4;
}

.sideDrawer {
  flex: 0 0 auto;
}
</style>
