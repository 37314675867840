<template>
  <SideDrawerComponent title="Agent Dashboard" ref="agentDetail">
    <template #content>
      <div class="">
        <div class="agentStatusDetailHeader">
          <div class="agentStatusDetailBadge" :class="activeAgent.status.short"></div>
          <div style="display: flex; flex-direction: column;">
            <span class="agentName">{{ activeAgent.profile.display_name }}</span>
            <span class="">{{ activeAgent.status.first }}</span>
          </div>
        </div>
        <div class="kv">
              <label>Operations Analyst: </label>
              <span class="callerID"  v-html="activeAgent.supervisor" />
            </div>
        <div class="divider">
          <DividerComp />
        </div>
        <div v-if="activeAgent.status.caller_id != null">
          <div class="kv">
              <label>Name: </label>
              <span class="callerID"  v-html="activeAgent.status.caller_id.name" />
            </div>
            <div class="kv">
              <label>Number: </label>
              <span class="callerID"  v-html="activeAgent.status.caller_id.number" />
            </div>
            <div class="kv">
              <label>Queue:</label>
              <span class="callerID" v-html="activeAgent.status.caller_id.queue" />
            </div>
        </div>
        <section>
          <div class="agentDetail title">
            Call Handling Summary
            <div class="divider">
              <DividerComp />
            </div>
          </div>
          <div class="stats">
            <div class="statsCard">
              <div class="statsBox answered">{{ activeAgent.stats.outbound_calls }}</div>
              <div class="statsHeader">Outbound</div>
            </div>
            <div class="statsCard">
              <div class="statsBox total">{{ activeAgent.stats.callsCurrentShift }}</div>
              <div class="statsHeader">Total</div>
            </div>
            <div class="statsCard">
              <div class="statsBox answered">{{ activeAgent.stats.answered_calls }}</div>
              <div class="statsHeader">Answered</div>
            </div>
            <div class="statsCard">
              <div class="statsBox missed">{{ activeAgent.stats.missed_calls }}</div>
              <div class="statsHeader">Missed</div>
            </div>
            <div class="statsCard">
              <div class="statsBox abandoned">{{ activeAgent.stats.abandoned_calls }}</div>
              <div class="statsHeader">Abandoned</div>
            </div>
          </div>
        </section>

        <section>
          <div class="agentDetail title">
            <span>Ticket Handling Summary</span>
            <div class="divider">
              <DividerComp />
            </div>
          </div>

          <div class="stats">
            <div class="statsCard">
              <div class="statsBox ticketsResolvedFirst">{{ activeAgent.tickets.count }}</div>
              <div class="statsHeader">Total</div>
            </div>
            <div class="statsCard">
              <div class="statsBox ticketsResolvedFirst">{{ activeAgent.tickets.created }}</div>
              <div class="statsHeader">Created</div>
            </div>
            <div class="statsCard">
              <div class="statsBox ticketsRatio">

                  {{ parseInt((activeAgent.tickets.created / activeAgent.stats.answered_calls) * 100) || 0 }}
              </div>
              <div class="statsHeader">Tickets:Calls %</div>
            </div>
          </div>
          <br/>
          <div class="stats">
            <div class="statsCard">
              <div class="statsBox ticketsResolvedFirst">{{ activeAgent.tickets.resolved }}</div>
              <div class="statsHeader">Resolved</div>
            </div>
            <div class="statsCard">
              <div class="statsBox ticketsResolvedSecond">{{ parseInt(activeAgent.tickets.resolved / activeAgent.tickets.count * 100) || 0 }}</div>
              <div class="statsHeader">%</div>
            </div>
            <div class="statsCard">
              <div class="statsBox ticketsEscalatedInternal">{{ activeAgent.tickets.escalated }}</div>
              <div class="statsHeader">Escalated</div>
            </div>
            <div class="statsCard">
              <div class="statsBox ticketsEscalatedExternal">{{ parseInt(activeAgent.tickets.escalated / activeAgent.tickets.count * 100) || 0 }}</div>
              <div class="statsHeader">%</div>
            </div>
          </div>
        </section>

      </div>
    </template>
    <template #footer>
    </template>
  </SideDrawerComponent>

  <div class="flowsContainer">
    <div :style="{ height: PROPS.dynamicHeight - 100 + 'px' }">
      <div class="agentConsole">
        <div class="agentCard" :class="agent.status.short" v-for="agent in agents"
          v-bind:key="agent" @click="loadAgent(agent); agentDetail.openSideDrawer()">
          <div class="agentName">{{ agent.profile.display_name }}</div>
          <div class="agentStatus">
            <span class="agentStatusBadge" :class="agent.status.short"></span>
            <span v-html="agent.status.first"></span>
          </div>
          <div>
            <span class="agentQueue" v-if="agent.status.caller_id != null" v-html="agent.status.caller_id.queue"></span>
            <span class="agentQueue" v-else>&nbsp;</span>
          </div>
          <div class="agentTimeInStatus">
            <p>
              <img :src="require(`@/assets/icons/Status Time.png`)" class="tableIcons" />
              <StopWatchComp :seconds="agent.status.second" />
            </p>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div>
  </div>
</template>

<script setup>
import { computed, defineProps, ref } from 'vue';
import { useQubicleRealtimeStore } from '@/stores/ContactCenter/Management/QubicleRealtimeStore';
import StopWatchComp from "@/components/CcManagement/StopWatchComp.vue";
import SideDrawerComponent from '@/components/base_components/SideDrawerComponent.vue';
import DividerComp from '@/components/base_components/DividerComp.vue';

const QubicleRealtimeStore = useQubicleRealtimeStore();

const PROPS = defineProps({
  dynamicHeight: Number,
})

const agentDetail = ref();
const activeAgent = ref();

const loadAgent = (agent) => {
  activeAgent.value = agent;
}

const agents = computed(() => {
  let sessions = QubicleRealtimeStore.$state.sessions;
  let sorted = {};
  let temp = {};
  Object.keys(sessions)
    .map((id) => {
      temp[sessions[id].profile.display_name] = id;
    })

  Object.keys(temp)
    .sort()
    .map((id) => sorted[temp[id]] = sessions[temp[id]])

  return sorted;
});



</script>

<style scoped>
section {
  padding-top: 24px;
}

.agentConsole {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  font-family: "Red Hat Display Bold", sans-serif;
  font-size: 12px;
  color: #271d7a;
}

.agentCard {
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-color: #271d7a;
  border-style: solid;
  border-width: 2pt;
  justify-content: left;
  align-items: left;
  text-align: left;
  padding: 10px;
  border-radius: 1pt;
}

.agentName {
  font-weight: 800;
}

.away {
  border-color: #eee253;
}

.available {
  border-color: #add3ad;
}

.on-a-call {
  border-color: #e87928;
}

.answered {
  background-color: #3e87d3;
}

.total {
  background-color: #add3ad;
}

.missed {
  background-color: #e87928;

}

.abandoned {
  background-color: #eee253;
}

.ticketsResolvedFirst {
  background-color: #5fc0d3;
}

.ticketsResolvedSecond {
  background-color: #add38d;
}

.ticketsEscalatedInternal {
  background-color: #3e87d3;
}

.ticketsEscalatedExternal {
  background-color: #271d7a;
}

.ticketsRatio {
  width: 40px;
  background-color: #271d7a;
}

.agentDetail {
  padding-top: 5px;
  color: #271d7a;
  font-family: "Red Hat Display Bold", sans-serif;
  font-size: 14px;
}

.agentDetail.agentName {
  font-size: 14px;
}

.agentDetail.title {
  color: #271d7a;
  font-family: "Red Hat Display Bold", sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.agentStatus {
  display: grid;
  grid-template-columns: 0.1fr 1fr;
  align-items: center;
  vertical-align: middle;
}

.agentTimeInStatus {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: left;
  align-items: left;
  text-align: left;
}

.agentStatusDetailBadge {
  width: 24px;
  height: 24px;
  border-radius: 2pt;
  content: "";
}

.agentStatusBadge {
  width: 10px;
  height: 10px;
  content: "";
}

.agentQueue {
  padding-left: 20px;
}

.agentStatusBadge.on-a-call {
  border-color: #e87928;
  background-color: #e87928;
}

.agentStatusBadge.available {
  border-color: #add3ad;
  background-color: #add3ad;
}

.agentStatusBadge.away {

  border-color: #eee253;
  background-color: #eee253;
}

.agentStatusDetailBadge.on-a-call {
  border-color: #e87928;
  background-color: #e87928;
}

.agentStatusDetailBadge.available {
  border-color: #add3ad;
  background-color: #add3ad;
}

.agentStatusDetailHeader {
  color: #271d7a;
  font-family: "Red Hat Display Bold", sans-serif;
  /* font-weight: 600; */
  font-size: 14px;
  gap: 24px;
  justify-content: middle;
  align-items: center;
  margin: 10px;
  padding: 10px;
  padding-top: 0px;
  margin-top: 0px;
  display: flex;
  flex-direction: row;
}

.agentStatusDetailBadge.away {

  border-color: #eee253;
  background-color: #eee253;
}

#content {
  color: #271d7a;
}

.divider {
  padding-top: 10px;
}

.flowsContainer {
  padding: 20px;
  width: 100%;
  background-color: #fcfdfe;
  height: 100%;
  justify-items: center;
  align-items: center;
  text-align: center;
}

.tableIcons {
  width: 28px;
  padding-right: 15px;
}

.tableText {
  color: #271d7a;
  font-size: 12pt;
}

.stats {
  color: #271d7a;
  font-family: "Red Hat Display Bold", sans-serif;
  display: grid;
  grid-template-columns: repeat(5, 0.5fr);
  text-align: center;
  padding-left: 10px;
  font-size: 12px;
  font-weight: 800;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.statsHeader {
  color: #271d7a;
  font-family: "Red Hat Display Bold", sans-serif;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.statsBox {
  font-size: 12px;
  width: 24px;
  height: 24px;
  padding-top: 4px;
  color: #ffffff;
  vertical-align: middle;
  text-align: center;
  border-radius: 2pt;
  vertical-align: middle;
  justify-content: center;
  text-align: center;
}

.statsCard {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: calc(100% / 3);
}

.kv {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding-left: 14px;
  color: #271d7a;
  font-family: "Red Hat Display Bold", sans-serif;
  font-size: 12px;
}

.kv label {
  font-weight: 600;
}

label {
  color: #271d7a;
  font-family: "Red Hat Display Bold", sans-serif;
  font-weight: 600;
  font-size: 12px;
}

.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.sideDrawer {
  position: fixed;
  top: auto;
  z-index: 6;
  bottom: 0;
  min-height: calc(100vh - 93px);
  max-height: calc(100vh - 93px);
}
</style>
