import { defineStore } from 'pinia'
import { API } from "aws-amplify";
import * as queries from "@/graphql/queries.js";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import awsconfig from '@/aws-exports';
import { useAuthStore } from '@/stores/auth/authStore';



export const useInternationalRatesStore = defineStore('InternationalRates', {
    state: () => ({
        rates: [],
    }),
    getters: {
        getRates(state) {
            return state.rates
        }
    },
    actions: {
        async sendMail(email, ratesFilePath, ratesFileName) {
            var error_message = 'Unable to send email with International Rates';
        
            const emailData = {
                to: email,
                subject: 'International Rates Result',
                body: 'Attached International Rates Result',
                from: "no-reply@lexconnect.cloud",
                attachments: [
                    {
                        bucket: awsconfig.aws_user_files_s3_bucket,
                        path: `public/${ratesFilePath}`,
                        fileName: ratesFileName
                    }
                ]
            };
        
            try {
                const api_name = "mail";
                const path = `/frontend/send`;
                const authStore = useAuthStore();
        
                const userAuth = `Bearer ${authStore.$state.cognito_tokens.id_token}`;
        
                const myInit = {
                    headers: {
                        Authorization: userAuth,
                        "Content-Type": "application/json"
                    },
                    body: emailData
                }
        
                let result_json = await API.post(api_name, path, myInit);
        
                if (!result_json.success) {
                    return { error: true, message: error_message };
                }
        
                return { error: false, message: result_json.data };
        
            } catch (error) {
                return { error: true, message: error };
            }
        },  
        async fetchRates(country, dialprefix) {
            try {
                var hasData = true;
                var nextToken = null;
                var ratesData = [];


                var filter = {};
                if (country) {
                    filter.country = { matchPhrase: country };
                }
                if (dialprefix) {
                    filter.dial_prefix = { wildcard: dialprefix };
                }
        
                while (hasData) {
                    var options = {
                        query: queries.searchCarrierDialPrefixRates,
                        variables: {
                            filter: filter, 
                            limit: 1000
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    };

                    // console.log("Options", JSON.stringify(options));

                    if (nextToken != null) {
                        options.variables.nextToken = nextToken;
                    }

                    var rates = await API.graphql(options);

                    // console.log("Rates Result: ", rates);

                    for (const item of rates.data.searchCarrierDialPrefixRates.items) {
                        ratesData.push(item);
                    }

                    if (rates.data.searchCarrierDialPrefixRates.nextToken != null) {
                        nextToken = rates.data.searchCarrierDialPrefixRates.nextToken;                    
                    }
                    else {
                        hasData = false;
                    }
                }

                this.rates = ratesData;
            }
            catch (error) {
                return {error: true, message: "Unable to fetch rates"};
            }
        }
    },
})
