<template>
  <!-- <ChildComponent ref="childComponentInstance" /> -->
  <SecondaryNavBar title="Billing - Tax Calculator" @button-click="handleButtonClick"
    :rightAlignedButtons="getNavButtons" />
  <div class="sideDrawer">
    <SideDrawer :drawerOpen="showEmail" :drawerData="getDrawerData" @close-click="handleCloseClick"
      @form-submission="handleDrawerFormSubmission" :numCols="12" :width="300" />
  </div>
  <div class="taxCalcBackground">
    <div class="taxCalcContainer">
      <div class="taxCalcContentContainer">
        <div class="taxCalcPageContent">
          <div class="taxCalcFormContainer">
            <TaxCalcFormComp ref="taxCalcForm" 
              :form-data="taxCalcData" 
              :additional-buttons="additionalButtons"
              :show-table="showTable"
              :external-customer="externalCustomer"
              :buttonOptions="{
                positionBottom: false,
                centered: false,
                fillToParent: false,
                divider: true,
              }" 
              :numCols="6"
              @button-clicked="handleAdditionalButtonClick"
              @submission-data="handleFormSubmission" 
              @dropdown-data="handleDropDownUpdate" 
            />
          </div>
        </div>
      </div>
    </div>
    <div class="tableWrapper">
      <div class="tableContainer">
        <TableComp
          v-if="showTable"
          :headers="headers"
          :dataList="data"
          :filterOptionsArray="filterOptionsArray"
          backgroundColor="#e2f1f4"
          :fixedWidth="true"
          @click-row="handleClickRow"
        />
      </div>
    </div>
    <AlertsGroup
      v-model:alerts="alerts"
    />
  </div>
</template>

<script>
import TaxCalcFormComp from "./components/TaxCalcFormComp.vue";
import SecondaryNavBar from "@/components/base_components/Navigation/SecondaryNavBar.vue";
import TableComp from "@/components/base_components/TableComp.vue";
import AlertsGroup from "@/components/base_components/AlertsGroup.vue";
import { downloadCSV } from "@/helpers/csvUtils.js";
import SideDrawer from "@/components/base_components/SideDrawer.vue";
import { addAlert } from '@/helpers/alertUtils';
import { ERROR, SUCCESS } from '@/constants/statuses.constants';

import { useAuthStore } from "@/stores/auth/authStore.js";
import { useHelpStore } from '@/stores/Help/helpStore.js';
import { useGeneralStore } from "@/stores/generalStore.js";
import { useTaxCalculatorStore } from "@/stores/Billing/TaxCalculator/taxCalculatorStore.js";
import { usePricebookStore } from '@/stores/Billing/Pricebook/pricebookStore.js';
import { mapActions, mapStores } from 'pinia';
import { useS3Store } from "@/stores/aws/s3Store";

export default {
  name: "TaxCalculatorView",
  components: {
    SecondaryNavBar,
    TaxCalcFormComp,
    TableComp,
    AlertsGroup,
    SideDrawer,
  },
  data() {
    return {
      windowHeight: null,
      showTable: false,
      additionalButtons: [
        { title: 'Email' },
        { title: 'Export'},
      ],
      alerts: [],
      filterOptionsArray: [],
      showEmail: false,
      selectedItemTotal: null,
      selectedTaxCode: null,
      externalCustomer: false,
      emailData: [
        { title: "Email", type: "form", button: "Send" },
        {
          title: "",
          inputs: [{ name: "Email Address", type: "bubble", required: true }],
        },
      ],
      taxCalcData: [
        { title: "Taxes", type: "form", button: "Get Tax Info" },
        {
          title: "",

          inputs: [
            {
              name: "Pricebooks",
              type: "select",
              required: true,
              options: []
            },
            {
              name: "Product Name",
              type: "select",
              required: true,
              options: [],
            },
            { name: "Item Total", type: "bubble", required: true },
            {
              name: "Tax Code",
              type: "select",
              required: true,
              options: [
                { label: "990101", value: "990101" },
                { label: "110201", value: "110201" },
                { label: "050101", value: "050101" },
                { label: "050112", value: "050112" },
                { label: "010203", value: "010203" },
                { label: "210202", value: "210202" },
                { label: "030101", value: "030101" },
                { label: "030107", value: "030107" },
                { label: "050110", value: "050110" },
                { label: "050201", value: "050201" },
                { label: "050212", value: "050212" },
                { label: "050406", value: "050406" },
                { label: "110604", value: "110604" },
                { label: "210118", value: "210118" },
                { label: "210301", value: "210301" },
                { label: "210302", value: "210302" },
                { label: "970115", value: "970115" },
                { label: "050158 - international usage tax code", value: "050158" },
                { label: "010209 - toll free inbound usage tax code", value: "010209" },
                { label: "110302 - 411 Directory Assistance tax code", value: "110302" },
              ],
            },
            { name: "Zip Code", type: "bubble", required: true },
          ],
        },
      ],
      headers: [
        { text: "Tax Authority", width: 170, value: "taxAuthority" },
        { text: "Tax Type", value: "taxType" },
        { text: "Tax Type Description", width: 150, value: "taxTypeDescription" },
        { text: "Revenue", value: "revenue" },
        { text: "Revenue Base", value: "revenueBase" },
        { text: "Tax On Tax", value: "taxOnTax" },
        { text: "Tax Rate", value: "taxRate" },
        { text: "Fee Rate", value: "feeRate" },
        { text: "Pct Taxable", value: "pctTaxable" },
        { text: "Tax Amount", value: "taxAmount" },
      ],
      data: [],
      userInfo: "",
      currentSelectedProducts: [],
      partner: ""
    };
  },
  watch: {
    'TaxCalculatorComp': {
      handler(newValue, oldValue) {
        console.log("new value", newValue);
        console.log("old value", oldValue);
      }
    },
  },
  async mounted() {
    // this.$nextTick(function () {
    //   console.log("BubbleComp: ", this.$refs.taxCalcForm.$refs.itemTotalBubble);
    // })
    window.addEventListener("resize", this.handleResize);
    this.windowHeight = window.innerHeight;
    this.externalCustomer = this.initializePage();
    let current_user = await this.AuthStore.getCurrentDBUser.partner;
    this.partner = current_user;
    
    // let pricebooks = await this.usePricebookStore.getPricebooks;
    if (current_user !== "LogicomUSA") {
      let options = [];
      await this.fetchPricebooks();
      let pricebooks =this.PricebookStore.getPricebooks;

      let filteredArray = this.taxCalcData[1].inputs.filter(input => input.name !== "Partners");
      this.taxCalcData[1].inputs = filteredArray;
      let findIndex = this.taxCalcData[1].inputs.findIndex(input => input.name === "Pricebooks");
      options = pricebooks.map(item => ({
        label: `${item.partner} - ${item.partner_account_number}`,
        value: item.id,
      }))
      this.taxCalcData[1].inputs[findIndex].options = options;
      let idArray = options.map(item => item.value)
      await this.fetchIndividualPricebooks(idArray);
      let results = this.PricebookStore.getPricebookTableInformation;
      // console.log('productsByPricebooksID', results);
      if (pricebooks.length === 0 && results.length !== 0) {
        await this.fetchProductsByPricebook(results)
      }
      // this.currentSelectedProducts = results;
    } else {
      
      // IF LOGICOM USER
      // 1 - FETCH ALL PARTNERS
      await this.GeneralStore.fetchAllPartnerInformation();
      let partners = this.GeneralStore.getPartnerOptions;
      // 2 - ON SELECT PARTNER - FETCH PRICEBOOKSBYPARTNER
      // 3 - ON SELECT PRICEBOOK - FETCH PRODUCT
      
      let currentInputs = this.taxCalcData[1].inputs;
      let partnerInput = {
        name: "Partners",
        type: "select",
        required: true,
        options: []
      }
      partnerInput.options = partners;
      currentInputs.unshift(partnerInput);
      this.taxCalcData[1].inputs = currentInputs;
    }
    
    // this.fetchPartnerProducts();
    this.HelpManagementStore.setPage('Billing / Tax Calculator');

  },

  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    ...mapStores(useTaxCalculatorStore, useHelpStore, useAuthStore, usePricebookStore, useGeneralStore),
    // getNavButtons() {
    //   if (this.showTable) {
    //     return [
    //       { title: "Email", icon: "email.png" },
    //       { title: "Export", icon: "Export.png" },
    //     ];
    //   }
    //   return [];
    // },
    getDrawerData() {
      return this.emailData;
    },
  },
  methods: {
    ...mapActions(useTaxCalculatorStore, ['fetchTaxInformation', 'fetchProducts', 'initializePage']),
    ...mapActions(useHelpStore, ['setPage']),
    ...mapActions(usePricebookStore, ['fetchPricebooks', 'getPricebooks', 'fetchIndividualPricebooks', 'fetchPricebooksByPartner']),
    ...mapActions(useGeneralStore, ['getPartnerOptions', 'fetchAllPartnerInformation']),
    handleAdditionalButtonClick(buttonTitle) {
      if (buttonTitle === 'Email') {
        this.showEmail = !this.showEmail;
      } else if (buttonTitle === 'Export') {
        downloadCSV(this.headers, this.data, [], "TaxInfo");
      }
    },
    async handleProductSelection(selectedOption) {
      
      const products = this.currentSelectedProducts;
      let splitStrings = selectedOption.split(' - ');
      let name = splitStrings[0];
      let frequency = splitStrings[1];
      let selectedProduct = {};
      for (let i =0 ; i < products.length; i++) {
        if (products[i].name === name && products[i].frequency === frequency) {
          selectedProduct = products[i];
        }
      }
      
      if (selectedProduct) {
        if (typeof selectedProduct.unit_price === 'undefined' || selectedProduct.unit_price === '' || selectedProduct.unit_price === null) {
          this.selectedItemTotal = '';
        } else if (selectedProduct.unit_price === 0){
          this.selectedItemTotal = 0.00;
        } else {
          this.selectedItemTotal = selectedProduct.unit_price;
        }
        
        this.selectedTaxCode = selectedProduct.tax_code || "";

        // console.log("Product Name: ", selectedProduct.productName);
        // console.log("Item Total: ", selectedProduct.item_total);
        // console.log("Tax Code: ", this.selectedTaxCode);

        // console.log(this.$refs);
        this.$refs.taxCalcForm.updateInputVal(
          {
            name: 'Item Total',
          },
          {
            target: {
              value: this.selectedItemTotal,
            },
          }
        );

        if (this.selectedTaxCode == "") {
          this.$refs.taxCalcForm.updateDropDownVal(
            {
              name: 'Tax Code',
            },
            this.selectedTaxCode
          );
        } else {
          this.$refs.taxCalcForm.updateDropDownVal(
            {
              name: 'Tax Code',
            },
            {
              "label": this.selectedTaxCode, "value": this.selectedTaxCode
            }
          );
        }


      } else {
        this.$refs.taxCalcForm.updateInputVal(
          {
            name: 'Item Total',
          },
          {
            target: {
              value: '',
            },
          }
        );
      }
    },
    async handlePricebookSelection(id) {
      await this.fetchIndividualPricebooks([id]);
      let results = this.PricebookStore.getPricebookTableInformation;
      await this.fetchProductsByPricebook(results);
    },
    async handlePartnerSelection(partner) {
      this.partner = partner;
      await this.fetchPricebooksByPartner(partner);
      let pricebooksOptions = [];
      let pricebooks = [];
      pricebooks = this.PricebookStore.getPricebooks;
      
      let findIndex = this.taxCalcData[1].inputs.findIndex(input => input.name === "Pricebooks");
      pricebooksOptions = pricebooks.map(item => ({
        label: `${item.partner} - ${item.partner_account_number}`,
        value: item.id,
      }))
      
      this.taxCalcData[1].inputs[findIndex].options = pricebooksOptions;
      
      if (pricebooksOptions.length === 1) {
        this.$refs.taxCalcForm.setOptions('pricebooks', pricebooksOptions[0]);
        await this.fetchProducts(this.partner, pricebooksOptions[0].value);

        let idArray = pricebooksOptions.map(item => item.value);
        await this.fetchIndividualPricebooks(idArray);
        let results = this.PricebookStore.getPricebookTableInformation;

        if (results.length !== 0) {
          await this.fetchProductsByPricebook(results);
        }
        
      } else {
        this.clearOptions('partners', pricebooksOptions);
        this.taxCalcData[1].inputs[findIndex].options = pricebooksOptions;
      }
    },
    async fetchProductsByPricebook(results) {
      if (results && results.length !== 0) {
        this.currentSelectedProducts = results;
        let findProductsIndex = this.taxCalcData[1].inputs.findIndex(input => input.name === "Product Name");
        let dropdownArray = results.map(item => ({value: item.name, label: `${item.name} - ${item.frequency}`}));
        this.taxCalcData[1].inputs[findProductsIndex].options = dropdownArray;
      } else {
        let findProductsIndex = this.taxCalcData[1].inputs.findIndex(input => input.name === "Product Name");
        this.taxCalcData[1].inputs[findProductsIndex].options = [];
      }
      this.$refs.taxCalcForm.clearForm('products');

    },
    handleResize() {
      this.windowHeight = window.innerHeight;
    },
    handleCloseClick() {
      this.showEmail = false;
    },
    clearOptions(form, newValue) {
      if (form === "partners") {
        let findPricebooksIndex = this.taxCalcData[1].inputs.findIndex(input => input.name === "Pricebooks");
        let findProductsIndex = this.taxCalcData[1].inputs.findIndex(input => input.name === "Product Name");
        this.taxCalcData[1].inputs[findPricebooksIndex].options = [];
        this.taxCalcData[1].inputs[findProductsIndex].options = [];
        this.$refs.taxCalcForm.clearForm('pricebooksAndProducts', newValue);
      } else if (form === "pricebooks") {
        let findProductsIndex = this.taxCalcData[1].inputs.findIndex(input => input.name === "Product Name");
        this.taxCalcData[1].inputs[findProductsIndex].options = [];
        this.$refs.taxCalcForm.clearForm('products');
      }
    },
    async handleDropDownUpdate(dropdownData) {
      if (dropdownData.name == 'Product Name') {
        await this.handleProductSelection(dropdownData.value.label);
      }
      if (dropdownData.name == 'Pricebooks') {
        this.clearOptions('pricebooks');
        await this.fetchIndividualPricebooks([dropdownData.value.value]);
        let results = this.PricebookStore.getPricebookTableInformation;
        await this.fetchProductsByPricebook(results);
      }
      if (dropdownData.name == "Partners") {
        await this.handlePartnerSelection(dropdownData.value.value);
        this.partner = dropdownData.value.value;
        // await this.fetchProducts(this.partn.value;
      }
    },
    // handleButtonClick(title) {
    //   if (title === "Export") {
    //     downloadCSV(this.headers, this.data, [], "TaxInfo");
    //   } else if (title === "Email") {
    //     this.showEmail = !this.showEmail;
    //   }
    // },
    buildCSVContent(headers, data) {
      const csvRows = [];
      const headerRow = headers.map((header) => header.text);
      csvRows.push(headerRow);

      data.forEach((row) => {
        const rowData = headers.map((header) => row[header.value]);
        csvRows.push(rowData);
      });

      return csvRows.map((row) => row.join(",")).join("\n");
    },
    async handleDrawerFormSubmission(formData) {
      if (formData === "Error" || Object.keys(formData).length === 0) {
        addAlert(ERROR, "Please enter your email", this.alerts);
      } else {
        const csvContent = this.buildCSVContent(this.headers, this.data);
        const filename = `TaxeCalculatedResults.csv`;
        const S3 = useS3Store();
        const authStore = useAuthStore();

        try {
          const uploadResult = await S3.putObject({
            Bucket: authStore.$state.s3_bucket,
            Key: `public/taxCalculator/${filename}`,
            ContentType: 'text/csv',
            Body: new Blob([csvContent])
          });


          const taxesFilesPath = uploadResult.key;
          const taxesFileName = filename;
          let email = formData['Email Address'];

          let emailResult = await this.TaxCalculatorStore.sendMailTaxes(email, taxesFilesPath, taxesFileName);

          if (emailResult && !emailResult.error) { 
            addAlert(SUCCESS, "Email sent!", this.alerts);
            this.showEmail = false;
          } else {
            addAlert(ERROR, "Email not sent!", this.alerts);
          }
        } catch (error) {
          addAlert(ERROR, `Error: ${error.message}`, this.alerts);
        }
      }
    },
    async handleFormSubmission(formData) {

      this.showTable = false;
      if (formData === "Error" || Object.keys(formData).length === 0) {
        addAlert(ERROR, "Please fill in all values", this.alerts);
      } else {
        if(this.validTaxForm(formData)) {
          var result = await this.fetchTaxInformation(formData["Item Total"], formData["Tax Code"], formData["Zip Code"]);
          if (result.error) {
            addAlert(ERROR, result.message, this.alerts);
          } else {
            this.data = this.TaxCalculatorStore.getTaxes;
            this.showTable = true;
          }
        }
      }
    },
    validTaxForm(formData){
      let valid = true;
      const costRegex = /^\d*(\.\d{2})?$/;
      console.log('Checking itemTotal: ', formData['Item Total']);
      if(Object.keys(formData).indexOf("Item Total") != -1 && !costRegex.test(formData["Item Total"])){
        addAlert(ERROR, "Item Total must be a valid total.", this.alerts);
        valid = false;
      }
      const zipRegex = /^\d{5}$/;
      console.log('Checking Zip: ', formData['Zip Code']);
      if(Object.keys(formData).indexOf("Zip Code") != -1 && !zipRegex.test(formData["Zip Code"])){
        addAlert(ERROR, "Zip Code must have 5 digits.", this.alerts);
        valid = false;
      }
      return valid;
    },
  },
};
</script>

<style scoped>
html {
  overflow-y: auto !important;
}

.taxCalcBackground {
  background-color: #e2f1f4;
  min-height: calc(100vh - 93px);
}

.taxCalcContainer {
  display: flex;
  width: 80%;
  padding-left: 40px;
  padding-right: 40px;
}

.taxCalcPageContent {
  flex-grow: 1;
}

.taxCalcContentContainer {
  display: flex;
  flex-grow: 1;
  position: relative;
}

.taxCalcFormContainer {
  margin: 20px 0px 20px 0px;
  filter: drop-shadow(1px 1px 1px rgb(39, 29, 122, 0.2));
}

.configAlerts {
  position: fixed;
  right: 0;
  padding-bottom: 10px;
  padding-right: 20px;
  z-index: 99;
}

.formContainer {
  padding-bottom: 0;
}

.tableWrapper {
  padding-left: 40px;
  padding-right: 40px;
  width: 80%;
}

@media (max-width: 820px) {
  .taxCalcContainer {
    width: 100%;
  }
  .tableWrapper {
    width: 100%;
  }
}

</style>
