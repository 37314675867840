import { defineStore } from 'pinia'
import { API } from "aws-amplify";
import * as mutations from "@/graphql/mutations.js";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { useStopwatch } from "vue-timer-hook";
import { useAuthStore } from "@/stores/auth/authStore.js";


export const useAgentStore = defineStore('Agent', {
    state: () => ({
        current_agent_id: null,
        current_agent_account_id: null,
        current_agent_status: "loading",
        current_agent_time: useStopwatch(0),
        wrapup_time_start: null,
        wrapup_time_end: null,
        wrapup_time_started: false,
        AgentSocket: null,
        full_agent_status: null,
        agent_socket_status: 3

        
    }), 
    getters: {
        getAgentSocket(state) {
            return state.AgentSocket;
        },
        getCurrentAgentID(state) {
            return state.current_agent_id;
        },
        getCurrentAgentAccountID(state) {
            return state.current_agent_account_id;
        },
        getAgentTime(state) {
            return state.current_agent_time;
        },
        getAgentStatus(state) {
            return state.current_agent_status;
        },
        getWrapUpStarted(state) {
            return state.wrapup_time_started;
        },
        getFullAgentStatus(state) {
            return state.full_agent_status;
        },
        getAgentSocketStatus(state) {
            return state.agent_socket_status;
        }
    },
    actions: {
        async initializeSwitchSocket() {
            const authStore = useAuthStore();


            await this.getCurrentAgentStatus();

            const api_name = "switch";
            const path = `/get_auth_token`;

            const userAuth = `Bearer ${authStore.$state.cognito_tokens.id_token}`;

            const myInit = {
                headers: {
                    Authorization: userAuth,
                    "Content-Type": "application/json"
                },
                body: {}
            }

            let result_json = await API.put(api_name, path, myInit);

            const websocket_url = authStore.getSwitchWebSocketUrl;


            if (websocket_url) {
                this.AgentSocket =  new WebSocket(websocket_url) 

                this.AgentSocket.onopen =  async () => {
                    await this.handleWebSocketOnOpen(result_json);
                } 

                this.AgentSocket.onmessage =  (e) => {
                    this.handleWebSocketOnMessage(e);
                };
            }
        },
        async getCurrentUserRecipientID() {
            const AuthStore = useAuthStore();
            const user = await AuthStore.getCurrentDBUser;

            this.current_agent_id = user.agent_recipient_id;
            this.current_agent_account_id = user.agent_account_id;
        },
        async getCurrentAgentStatus() {
            var gregorian_offset = 62167219200;

            const authStore = useAuthStore();

            var account_id = this.current_agent_account_id;
            var recipient_id = this.current_agent_id;

            const api_name = "switch";
            const path = `/accounts_frontend/${account_id}/qubicle_recipients/status`;

            const userAuth = `Bearer ${authStore.$state.cognito_tokens.id_token}`;

            const myInit = {
                headers: {
                    Authorization: userAuth,
                    "Content-Type": "application/json"
                },
                body: {
                    data: {
                        recipient_ids: [recipient_id]
                    }
                }
            }

            let result_json = await API.post(api_name, path, myInit);
            var agent_object = result_json.data.data[recipient_id];

        
            var agent_status = "not_logged_in";
            
            if (agent_object != "not_logged_in") {
                agent_status = agent_object.availability_state;

                if (agent_object.away_reason && agent_status == "Away") {
                    agent_status = `${agent_status} - ${agent_object.away_reason}`;
                }


                var agent_last_action_time = (agent_object.stats.last_action_time - gregorian_offset);
                var timestampNow = Math.floor(Date.now() / 1000);

                var time_difference = timestampNow - agent_last_action_time;
            }


            this.current_agent_status = agent_status;
            this.current_agent_time = useStopwatch(time_difference);
        },
        async updateAgentStatus(status) {

            const recipient_id = this.current_agent_id;
            const current_agent_status = this.current_agent_status;
            const account_id = this.current_agent_account_id;

            const authStore = useAuthStore();

            if (current_agent_status != "not_logged_in") {
                const api_name = "switch";
                const path = `/accounts_frontend/${account_id}/qubicle_recipients/${recipient_id}/status`;
                const userAuth = `Bearer ${authStore.$state.cognito_tokens.id_token}`;

                var status_string = status;
                var reason_string = null;
                if (status.includes("Away")) {
                    var status_array = status.split("_");
                    status_string = status_array[0];
                    reason_string = status_array[1];
                }

                var data_object = {
                    status: status_string.toLowerCase(),
                }

                if (reason_string) {
                    data_object.reason = reason_string;
                }

                const myInit = {
                    headers: {
                        Authorization: userAuth,
                        "Content-Type": "application/json"
                    },
                    body: {
                        data: data_object
                    }
                }

                await API.post(api_name, path, myInit);
            }   
        },
        async startAgentSession() {
            const api_response = await this.makeLoginRequest();

            if (api_response.success) {
                this.current_agent_status = "Away";
                this.current_agent_time = useStopwatch(0);
                return true;
            }
            else {
                return false;
            }
        },
        async endAgentSession() {
            const api_response = await this.makeLogoutRequest();

            if (api_response.success) {
                this.current_agent_status = "not_logged_in";
                this.current_agent_time = useStopwatch(0);
            }
        },
        async handleRecoveryTime(recipient_id, wrapup_time_start, wrapup_time_end) {
            var total_recovery_time = Math.ceil((wrapup_time_end - wrapup_time_start) / 1000);

            try {
                var options = {
                    query: mutations.createAgentsRecoveryTimes,
                    variables: {
                        input: {
                            recipient_id: recipient_id,
                            recovery_time: total_recovery_time
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                }

                await API.graphql(options);
            }
            catch(error) {
                console.log(error);
            }
        },
        handleAgentEvent(event) {

            console.log("STATUS COMP EVENT: ", event);


            if (event.name == "delivered") {
                // let callIdNum = event.data.caller_id_num;
                let queueName = event.data.queue_name; 
                let agent_status = `On-A-Call - ${queueName}`;

                if (agent_status.length > 31) {
                    this.full_agent_status = agent_status;
                    agent_status = agent_status.substring(0, 31) + "...";
                }


                this.current_agent_status = agent_status;
                this.current_agent_time = useStopwatch(0);

            
            }
            else if (event.name == "reject") {
                if (event.data.state == "ready") {
                    this.current_agent_status = "Ready";
                    this.full_agent_status = this.current_agent_status;
                    this.current_agent_time = useStopwatch(0);
                }
                else {
                    this.current_agent_status = "Away";
                    this.full_agent_status = this.current_agent_status;
                    this.current_agent_time = useStopwatch(0);
                }
            }
            else if (event.name == "rescind") {
                if (event.data.state == "ready") {
                    this.current_agent_status = "Ready";
                    this.full_agent_status = this.current_agent_status;
                    this.current_agent_time = useStopwatch(0);
                }
                else {
                    this.current_agent_status = "Away";
                    this.full_agent_status = this.current_agent_status;
                    this.current_agent_time = useStopwatch(0);
                }
            }
            else if (event.name == "offer") {
                // let callIdNum = event.data.caller_id_num;
                let queueName = event.data.queue_name; 
                let agent_status = `Offer - ${queueName}`;

                if (agent_status.length > 31) {
                    this.full_agent_status = agent_status;
                    agent_status = agent_status.substring(0, 31) + "...";
                }


                this.current_agent_status = agent_status;
                this.current_agent_time = useStopwatch(0);
            }
            else if (event.name == "create") {
                if (event.data.state == "ready") {
                    this.current_agent_status = "Ready";
                    this.full_agent_status = this.current_agent_status;
                    this.current_agent_time = useStopwatch(0);
                }
                else {
                    this.current_agent_status = "Away";
                    this.full_agent_status = this.current_agent_status;
                    this.current_agent_time = useStopwatch(0);
                }
            }
            else if (event.name == "external_call_start") {
                this.current_agent_status = "onAExternalCall";
                this.full_agent_status = this.current_agent_status;
                this.current_agent_time = useStopwatch(0);
            }
            else if (event.name == "external_call_end") {
                this.current_agent_status = event.data.availability_state;
                this.full_agent_status = this.current_agent_status;
                this.current_agent_time = useStopwatch(0);
            }
            else if (event.name == "wrapup_complete") {
                this.current_agent_status = event.data.availability_state;
                this.full_agent_status = this.current_agent_status;
                this.wrapup_time_end = Date.now();
                this.current_agent_time = useStopwatch(0);
                this.handleRecoveryTime(event.data.recipient_id, this.wrapup_time_start, this.wrapup_time_end);
            }
            else if (event.name == "wrapup_start") {
                if (this.current_agent_status != "Recovery") {
                    this.wrapup_time_started = true;
                    this.wrapup_time_start = Date.now();
                    this.current_agent_time = useStopwatch(0);
                }

                this.current_agent_status = "Recovery";
                this.full_agent_status = this.current_agent_status;
            }
            else if (event.name == "ready") {
                this.current_agent_status = "Ready";
                this.full_agent_status = this.current_agent_status;
                this.current_agent_time = useStopwatch(0);
            }
            else if (event.name == "away") {
                var reason = event.data.away_reason;
                
                if (reason != "") {
                    this.current_agent_status = `Away - ${reason}`;
                    this.full_agent_status = this.current_agent_status;
                    this.current_agent_time = useStopwatch(0);
                }
                else {
                    this.current_agent_status = "Away";
                    this.full_agent_status = this.current_agent_status;
                    this.current_agent_time = useStopwatch(0);
                }    
            }
            else if (event.name == "delete") {
                this.current_agent_status = "not_logged_in";
                this.full_agent_status = this.current_agent_status;
                this.current_agent_time = useStopwatch(0);
            }
            // else if (event.name == "sync"){
            //     this.current_agent_status = event.availability_state;
            //     this.full_agent_status = this.current_agent_status;

            //     var gregorian_offset = 62167219200;
            

            //     var agent_last_action_time = (event.last_action_time - gregorian_offset);
            //     var timestampNow = Math.floor(Date.now() / 1000);
            //     var time_difference = timestampNow - agent_last_action_time;

            //     this.current_agent_time = useStopwatch(time_difference);
            // }
        },
        async makeLoginRequest() {
            const recipient_id = this.current_agent_id;
            const account_id = this.current_agent_account_id;
            const authStore = useAuthStore();


            const userAuth = `Bearer ${authStore.$state.cognito_tokens.id_token}`;
           
            var api_name = "switch";
            var path = `/accounts_frontend/${account_id}/qubicle_recipients/${recipient_id}`;
            var myInit = {
                headers: {
                    Authorization: userAuth,
                    "Content-Type": "application/json"
                },
                body: {
                    data: {
                        action: "login"
                    }
                }
            }

            const api_response = await API.post(api_name, path, myInit);

            return api_response;
        },
        async makeLogoutRequest() {
            const recipient_id = this.current_agent_id;
            const account_id = this.current_agent_account_id;
            const authStore = useAuthStore();

            const userAuth = `Bearer ${authStore.$state.cognito_tokens.id_token}`;
           
            var api_name = "switch";
            var path = `/accounts_frontend/${account_id}/qubicle_recipients/${recipient_id}`;
            var myInit = {
                headers: {
                    Authorization: userAuth,
                    "Content-Type": "application/json"
                },
                body: {
                    data: {
                        action: "logout"
                    }
                }
            }

            const api_response = await API.post(api_name, path, myInit);

            return api_response;
        },
        async extendAgentWrapupTime() {
            const recipient_id = this.current_agent_id;
            const account_id = this.current_agent_account_id;
            const authStore = useAuthStore();

            const userAuth = `Bearer ${authStore.$state.cognito_tokens.id_token}`;
           
            var api_name = "switch";
            var path = `/accounts_frontend/${account_id}/qubicle_recipients/${recipient_id}`;
            var myInit = {
                headers: {
                    Authorization: userAuth,
                    "Content-Type": "application/json"
                },
                body: {
                    data: {
                        action: "wrapup_extend"
                    }
                }
            }

            const api_response = await API.post(api_name, path, myInit);

            this.wrapup_time_started = false;
            return api_response;
        },
        async closeAgentSocket() {
            this.AgentSocket.close();
        },
        async checkWebsocketHealth() {
            if (this.AgentSocket) {
                this.agent_socket_status = this.AgentSocket.readyState;
            }
            else {
                this.agent_socket_status = 3;
            }
        },
        async handleWebSocketOnOpen(result_json) {
            this.checkWebsocketHealth();

            //A subscription must include a valid pair of account_id / auth_token, and must include the binding that you want to listen to.
            var recipientSubscription = {
                action: 'subscribe',
                auth_token: result_json.data.auth_token,
                data: {
                    account_id: this.current_agent_account_id, //CRAM ACCOUNT: 47e2f51feb67e1d0303c5ba7cda5e123 //CALL CENTER: 519b73ddf6c2ba5688acc12e9d0c01d4
                    binding: 'qubicle.recipient'
                }
            };

            //bindings we might want: qubicle.recipient, dashboard.callcenter_pro, qubicle.queue
            //The data flowing through sockets must be a String, so we cast the JSON into a String
            var recipientSubscriptionString = JSON.stringify(recipientSubscription);

            console.log("AGENT SOCKET: ", this.AgentSocket);
            console.log("AGENT ACCOUNT ID: ", this.current_agent_account_id);
            //Once we properly configured our subscription, we can send the corresponding string to the WebSocket, which will notify the system that we want to subscribe to an event
            
            this.AgentSocket.send(recipientSubscriptionString);
            this.checkWebsocketHealth();
        },
        async handleWebSocketOnMessage(e) {
            var event = JSON.parse(e.data);
            if (event.subscribed_key == "qubicle.recipient") {
                if (event.data.recipient_id == this.current_agent_id) {
                    this.handleAgentEvent(event);
                }
            }
        }
    },
})


