<template>
  <div class="formContainer">
    <div class="formContent">
      <p class="formHeader">{{ formData[0].title }}</p>
      <img
        v-if="removeIcon"
        class="formRemoveIcon"
        :src="require('@/assets/icons/Remove.png')"
        @click="handleClose"
      />
      <div v-for="(section, sectionIndex) in formData" :key="sectionIndex">
        <template v-if="sectionIndex !== 0 && section.show !== false">
          <p class="formSectionTitle">
            {{ section.title }}
          </p>
          <v-form ref="form" @submit.prevent="onSubmit()">
            <div
              class="formInputs"
              :class="{
                formInputsWithScroll:
                  hasScrollbar && buttonOptions.positionBottom,
              }"
              ref="formInputs"
            >
              <template v-if="section.checkbox">
                <div class="checkboxWrapper">
                  <CheckBox
                    :item="section.checkbox"
                    @toggle-selected="
                      this.submissionData[section.checkbox.name] =
                        !this.submissionData[section.checkbox.name]
                    "
                  />
                  <label class="formInputLabel pl-2">{{
                    section.checkbox.name
                  }}</label>
                </div>
              </template>
              <v-row dense>
                <template v-for="input in section.inputs" :key="input">
                  <v-col
                    :cols="input.fullWidth ? 12 : (input.thirdWidth ? 8 : numCols)"
                    :class="externalCustomer && input.name === 'Tax Code' ? 'hidden-dropdown' : 'formInputCol px-4'"
                  >
                    <label
                      :class="externalCustomer && input.name === 'Tax Code' ? 'hidden-dropdown' : 'formInputLabel'"
                      v-if="
                        input.type !== 'checkbox' &&
                        input.type != 'dynamicOptions'
                      "
                      >{{ input.name }}
                      <span v-if="input.required" class="required-asterisk"
                        >*</span
                      ></label
                    >
                    <template v-if="input.type === 'select'">
                      <div class="dropdownContainer">
                        <DropDown
                          :ref="`formComp-${input.name}`"
                          :name="input.name"
                          :class="(externalCustomer && input.name === 'Tax Code') ? 'hidden-dropdown' : '' "
                          :options="input.options"
                          :default="!input.noDefault && getDefaultValue(input)"
                          @input="updateDropDownVal(input, $event)"
                          :value="
                            input?.options?.find(
                              (option) =>
                                option.value === submissionData?.[input.name]
                            ) ||
                            input?.options?.find(
                              (option) =>
                                option.value === submissionData?.[input.value]
                            ) ||
                            submissionData[input.name] ||
                            formData?.[1].inputs?.[1]?.options?.find(
                              (option) =>
                                option.value === submissionData[input.value]
                            ) ||
                            submissionData[input.value] ||
                            formData?.[1].inputs?.[1]?.options?.find(
                              (option) =>
                                option.value === submissionData[input.value]
                            ) 
                          "
                        />
                      </div>
                    </template>
                    <template v-else-if="input.type === 'multiSelect'">
                      <DropDownMulti
                        :name="input.name"
                        :options="input.options"
                        placeholder="Select Fields"
                        :default="getDefaultValue(input)"
                        @input="updateDropDownVal(input.name, $event)"
                        :value="submissionData[input.name]"
                      />
                    </template>
                    <template v-else-if="input.name === 'Subject'">
                      <v-col class="formInputCol" :style="{ padding: '0px' }">
                        <FillInBubble
                          :placeholder="input.name"
                          :isRequired="input.required"
                          @input="updateInputVal(input, $event)"
                          :value="submissionData[input.name]"
                        />
                        <BulkUpdatePanel class="bulkUpdateText" />
                      </v-col>
                    </template>
                    <template v-else-if="input.type === 'expandableBubble'">
                      <ExpandableFillInBubble
                        :placeholder="input.name"
                        :isRequired="input.required"
                        @input="updateInputVal(input, $event)"
                        :value="
                          submissionData?.[input.value] ||
                          submissionData?.[input.name]
                        "
                      />
                    </template>
                    <template v-else-if="input.type === 'radio'">
                      <RadioComp
                        :input="input"
                        :submissionData="submissionData"
                        @toggle-selected="updateRadioValue(input.name, $event)"
                        :multiSelect="input.multiSelect"
                      />
                    </template>
                    <template v-else-if="input.type === 'bubble'">
                      <FillInBubble
                        :placeholder="input.name"
                        :isRequired="input.required"
                        :readonly="input.readOnly"
                        @change="updateInputVal(input, $event)"
                        :value="
                          submissionData?.[input.value]
                            ? submissionData?.[input.value]
                            : submissionData?.[input.name]
                        "
                      />
                    </template>
                    <template v-else-if="input.type === 'checkbox'">
                      <div
                        class="formInputCheckbox"
                        :class="{
                          checkboxInRow: !(section.inputs.length % 2),
                        }"
                      >
                        <CheckBox
                          :item="input.name"
                          @toggle-selected="
                            this.submissionData[input.name] =
                              !this.submissionData[input.name]
                          "
                        />
                        <label class="formInputLabel pl-2">{{
                          input.name
                        }}</label>
                      </div>
                    </template>
                    <template v-else-if="input.type === 'dateRange'">
                      <DatePicker
                        :allowDateRange="true"
                        :input="input"
                        @date-value="handleDateChange"
                      />
                    </template>
                    <template v-else-if="input.type === 'datePicker'">
                      <DatePicker
                        :allowDateRange="false"
                        :input="input"
                        @date-value="handleDateChange"
                        :value="previousFormValues?.[input.value]"
                      /> </template
                    ><template v-else-if="input.type === 'timePicker'">
                      <TimePicker
                        :allowDateRange="false"
                        :input="input"
                        @date-value="handleDateChange"
                        :value="submissionData?.[input.value]"
                        :existingDate="
                          submissionData['dateEST'] ||
                          previousFormValues?.['dateEST']
                        "
                      />
                    </template>
                    <template v-else-if="input.type === 'dynamicOptions'">
                      <DynamicOptions
                        @dOptionsInput="updateOptionsVal(input.type, $event)"
                        :initialOptions="
                          Object.keys(previousFormValues).length
                            ? [...previousFormValues?.['dynamicOptions']]
                            : submissionData?.['dynamicOptions']
                        "
                      />
                    </template>
                    <template v-else-if="input.type === 'button'">
                      <slot
                        name="button"
                        :sendSubmissionData="sendSubmissionData"
                      >
                        <DarkButton> {{ input.name }}</DarkButton>
                      </slot>
                    </template>
                    <template v-else-if="input.type === 'filePicker'">
                      <slot name="file-picker"></slot>
                    </template>
                    <template v-else-if="input.type === 'smartLink'">
                      <SmartLink />
                    </template>
                    <template v-else-if="input.type === 'textarea'">
                      <slot name="textarea">
                        <textarea
                          :readonly="
                            input.readonly !== undefined ? input.readonly : true
                          "
                          :value="
                            submissionData?.[input.value] ||
                            submissionData?.[input.name] ||
                            input.text
                          "
                          :placeholder="input.placeholder"
                          class="textareaNormal"
                          @change="updateInputVal(input, $event)"
                        ></textarea>
                      </slot>
                    </template>
                  </v-col>
                </template>
                <slot> </slot>
              </v-row>
            </div>
            <template v-if="sectionIndex === formData.length - 1">
              <div class="formButtonContainer">
                <div class="formDarkButton">
                  <DarkButton :title="formData[0].button" :width="getButtonWidth" />
                </div>
                <div class="additionalButtons" v-if="showTable">
                  <template v-for="button in additionalButtons" :key="button.title">
                     <DarkButton 
                       :title="button.title" 
                       @click="onButtonClicked(button)"
                      />
                  </template>
                </div>
              </div>
            </template>
            <template v-else>
              <DividerComp class="formSectionDivider" />
            </template>
          </v-form>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import DividerComp from "@/components/base_components/DividerComp.vue";
import DropDown from "@/components/base_components/FormComponents/DropDown.vue";
import FillInBubble from "@/components/base_components/FormComponents/FillInBubble.vue";
import DarkButton from "@/components/base_components/Buttons/DarkButton.vue";
import CheckBox from "@/components/base_components/FormComponents/CheckBox.vue";
import DropDownMulti from "@/components/base_components/FormComponents/DropDownMulti.vue";
import BulkUpdatePanel from "@/components/base_components/Textbox/BulkUpdatePanel.vue";
import DatePicker from "@/components/base_components/FormComponents/DatePicker.vue";
import ExpandableFillInBubble from "@/components/base_components/ExpandableFillInBubble.vue";
import RadioComp from "@/components/base_components/FormComponents/RadioComp.vue";
import DynamicOptions from "@/components/base_components/DynamicOptions.vue";
import TimePicker from "@/components/base_components/FormComponents/TimePicker.vue";
import SmartLink from "@/components/base_components/FormComponents/SmartLink.vue";

export default {
  name: "TaxCalcFormComp",
  props: {
    formData: Array,
    externalCustomer: Boolean,
    title: String,
    numCols: Number,
    removeIcon: Boolean,
    previousFormValues: Object,
    additionalButtons: Array,
    showTable: Boolean,

    buttonOptions: {
      type: Object,
      default() {
        return {
          positionBottom: true,
          centered: true,
          fillToParent: true,
          width: "100%",
          divider: true,
        };
      },
    },
  },
  watch: {
    windowHeight() {
      this.$nextTick(() => {
        this.dynamicHeight;
      });
    },

    previousFormValues: {
      deep: true,
      handler(newValues) {
        this.previousFormValuesTriggered = true;
        this.submissionData = { ...newValues };
      },
    },

    submissionData: {
      deep: true,
      handler(newData) {
        this.$emit("submissionDataUpdated", newData);
      },
    },
    formData() {
      if (!this.previousFormValuesTriggered) {
        this.submissionData = {};
      }
      this.previousFormValuesTriggered = false;
    },
    
  },
  computed: {
    dynamicHeight() {
      return Number(this.windowHeight) - 93;
    },
    dropdownName(input) {
      if (input.name === "Pricebooks" || input.name === "Partners") {
        return `Select a ${input.name.slice(0, -1)}`;
      } else {
        return `Select a ${input.name}`;
      }
    },
    getButtonWidth() {
      return this.buttonOptions?.fillToParent ? "parent" : "";
    },
  },
  data() {
    return {
      submissionData: {},
      hasScrollbar: false,
      windowHeight: null,
      showDatePicker: false,
      selectedStartDate: "",
      selectedEndDate: "",
      previousFormValuesTriggered: false,
    };
  },
  methods: {
    onButtonClicked(button) {
      this.$emit('button-clicked', button.title);
    },
    handleDateChange(input, value) {
      if (input.value) {
        this.submissionData[input.value] = value;
      } else {
        this.submissionData[input.name] = value;
      }
    },
    toggleDatePicker() {
      this.showDatePicker = !this.showDatePicker;
    },
    updateSelectedDate(date) {
      this.selectedStartDate = date;
    },
    setOptions(input, value) {
      if (input === "pricebooks") {
        let string = "formComp-Pricebooks";
        this.$refs[string][0].selected = value;
      }
      if (input === "products") {
        let string = "formComp-Product Name";
        this.$refs[string][0].selected = value;
      }
    },
    onSubmit() {
      let valid = this.isFormValid();
      if (valid) {
        console.log("Submitting", this.submissionData);
        this.$emit("submission-data", this.submissionData);
      } else {
        this.$emit("submission-data", "Error");
      }
    },
    isFormValid() {
      return this.formData.slice(1, this.formData.length)?.every((section) => {
        return section?.inputs?.every((input) => {
          if (input.required) {
            if (
              (!this.submissionData[input.name] && !this.submissionData[input.value]) &&
              !(this.externalCustomer && (input.name === 'Tax Code'))
            ) {
              return false;
            }
            return true;
          } else {
            if (
              input.name === 'dynamicOptions' &&
              this.submissionData.dynamicOptions
            ) {
              return this.submissionData.dynamicOptions.every((option) => {
                return option.value !== '';
              });
            }
            return true;
          }
        });
      });
    },
    getDefaultValue(input) {
      const vowels = ["a", "e", "i", "o", "u"];

      const inputName = input.name.toLowerCase();
      let string = inputName;
      if (string === "partners" || string === "pricebooks") {
        string = inputName.slice(0, -1);
      }

      return vowels.includes(string.charAt(0))
        ? `Select an ${string}`
        : `Select a ${string}`;
    },
    sendSubmissionData() {
      // this is a function independent from the submit form. Used for partially sending values
      // even if some required fields arent set (used in Iconfig main UI for adding a user)
      this.$emit("submission-data-independent", this.submissionData);
    },
    updateDropDownVal(input, event) {

      if (input.value) {
        if (event.value !== this.submissionData[input.name]) {
          this.submissionData[input.value] = event;
        }
      } else {
        this.submissionData[input.name] = event;
      }
      let name = input.name;
      this.$emit("dropdown-data", { name, value: event });

      // check if this input control's any other dropdown data
      const currentInputObject = this.formData[1].inputs.find(
        (input) => input.name === name
      );

      if (currentInputObject?.controls) {
        const dependent = currentInputObject.controls;
        this.submissionData[dependent] = null;
        this.$emit("dependent-dropdown-data");
      }

    },
    updateInputVal(input, event) {
      if (event.target.value || event.target.value === 0.00) {
        if (input.value) {
          this.submissionData[input.value] = event.target.value;
        } else {
          this.submissionData[input.name] = event.target.value;
        }
        this.$emit("input-data", event.target.value);
      } else {
        if (event.target.innerText) {
          if (input.value) {
            this.submissionData[input.value] = event.target.innerText;
          } else {
            this.submissionData[input.name] = event.target.innerText;
          }
        } else {
          delete this.submissionData[input.name];
          delete this.submissionData[input.value];
        }
      }
    },
    handleClose() {
      this.$emit("close-click");
    },
    updateRadioValue(name, option) {
      if (Array.isArray(option) && name) {
        this.submissionData[name] = option;
      } else {
        if (name) {
          this.submissionData[name] = option.value;
        }
      }
    },
    updateOptionsVal(type, value) {
      this.submissionData[type] = value;
    },
    clearForm(value) {
      if (value === 'pricebooksAndProducts') {
        this.$refs["formComp-Product Name"][0].selected = this.$refs["formComp-Product Name"][0].default;
        this.$refs["formComp-Pricebooks"][0].selected = this.$refs["formComp-Pricebooks"][0].default;
        this.submissionData['Item Total'] = "";
        this.submissionData['Product Name'] = "";
      }
      if (value == 'products') {
        this.$refs["formComp-Product Name"][0].selected = this.$refs["formComp-Product Name"][0].default;
        this.submissionData['Item Total'] = "";
        this.submissionData['Product Name'] = "";
      }
    },
    checkScrollbar() {
      const element = this.$refs.formInputs;
      if (this.windowHeight < element[0].scrollHeight + 200) {
        this.hasScrollbar = true;
      } else {
        this.hasScrollbar = false;
      }
    },
    handleResize() {
      this.windowHeight = window.innerHeight;
      this.checkScrollbar();
    },
  },
  mounted() {
    this.submissionData = { ...this.previousFormValues };
    window.addEventListener("resize", this.handleResize);
    this.windowHeight = window.innerHeight;
    this.checkScrollbar();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  updated() {
    this.checkScrollbar();
  },
  components: {
    DropDown,
    FillInBubble,
    DividerComp,
    DarkButton,
    CheckBox,
    DropDownMulti,
    BulkUpdatePanel,
    DatePicker,
    ExpandableFillInBubble,
    RadioComp,
    DynamicOptions,
    TimePicker,
    SmartLink,
  },
  emits: [
    "submission-data",
    "dropdown-data",
    "input-data",
    "dependent-dropdown-data",
    "submissionDataUpdated",
    "submission-data-independent",
    "close-click",
    "button-clicked"
  ],
};
</script>

<style scoped>
.formButtonDivider {
  margin: 0px 0px 16px 0px;
  width: 100%;
}
.checkboxWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 10px;
  padding-left: 16px;
}
.formInputCheckbox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.checkboxInRow {
  padding-top: 27px;
  padding-left: 3px;
}
.formButtonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px; 
  background-color: #ffffff;
}

.formButtonContainer .formDarkButton {
  flex-grow: 0; 
}

.additionalButtons {
  display: flex;
  gap: 10px;
}
.formButtonPositionBottom {
  position: absolute;
  padding-bottom: 16px;
}
.formButtonCentered {
  align-items: center;
  text-align: center;
}
.additionalButtons {
  display: flex;
  gap: 10px; /* Adjust as needed */
  /* Add any additional styling */
}
.formDarkButton {
  padding: 0px 15px 0px 15px;
}

.formInputCol {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.formContainer {
  background-color: #ffffff;
  overflow: visible;
  height: 100%;
}
.formContent {
  overflow: visible;
  padding: 11px 0px 11px 0px;
  height: 100%;
}
.formInputs {
  padding: 11px 5px 11px 5px;
  max-height: calc(100vh - 160px);
}
.formInputsWithScroll {
  height: calc(100vh - 160px);
  padding-bottom: 120px;
  overflow: auto;
}
.formSectionTitle {
  padding-left: 16px;
  font-size: 11pt;
  font-weight: bold;
  color: #3e87d3;
}
.formSectionDivider {
  margin: 11px 11px 25px 11px;
}
.formInputLabel {
  font-size: 9pt;
  font-weight: 600;
  color: #271d7a;
  padding-bottom: 5px;
}
.formHeader {
  padding-top: 5px;
  padding-left: 16px;
  padding-bottom: 10px;
  font-size: 14pt;
  font-weight: bold;
  color: #271d7a;
}
.formRemoveIcon {
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
  height: 35px;
  padding-top: 20px;
  padding-right: 20px;
}

.textareaNormal {
  outline: #2e3e91 solid 0.25pt;
  border-radius: 2px;
  padding-left: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 9pt;
  color: #271d7a;
  background-color: #ffffff;
  height: 150px;
}

.bulkUpdateText {
  margin-top: 30px;
}
.inLineWithInputs {
  padding-left: 35px;
}

.formButtonContainer .formDarkButton {
  padding-left: 16px;
  padding-right: 16px;
}
.hidden-dropdown {
  display: none;
}
.simplebar-track.simplebar-vertical {
  background-color: #e2f1f4;
}
.simplebar-scrollbar:before {
  background: #e2f1f4 !important;
}
</style>
